.recommend {
  &.hero {
    background-image: url('../img/recommend/recommend-bg.png');
  }

  .desc-item-box {
    h3 {
      font-size: 24px;
      font-weight: 400;
    }

    p {
      color: #202020;
      line-height: 2;
    }
  }

  .module-body {
    padding-bottom: 0;
  }
}

$color-gray: #202020;
$color-light: rgba(238, 238, 238, 1);

@mixin alter($direction : left) {
  content: '';
  display: inline-block;
  width: 500px;
  border-top: 1px solid $color-light;
  margin-right: 5px;
  vertical-align: middle;
}

.recommend-qr-box {
  margin-top: -104px;
  position: relative;
  padding: 64px 0;
  width: 1000px;
  box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  background-color: #fff;
  color: $color-gray;
  text-align: center;

  .pull-new {
    border-bottom: 1px #f3f3f3 solid;
    padding-bottom: 64px;
    margin-bottom: 64px;
  }

  .pull-new-box {
    margin-top: 40px;
  }

  .pull-new-title {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #FEBD94;
  }

  .qr-box-title {
    font-size: 24px;
    font-weight: 300;
  }

  .qr-box-info {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .qr-box-desc-par {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    height: 21px;
    margin-top: 20px;
  }

  .qr-box-desc {
    font-size: 14px;
    font-weight: 100;
    color: $color-gray;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      @include alter(right)
    }

    &:after {
      @include alter()
    }
  }
}

.core-functions-recommend {
  background-color: #f9f9f9;
  padding: 100px 0 80px 0;

}

.recommend-download {
  margin: 0;
  padding-top: 10px;
}

.recommend-core-outer-box {
  color: $color-gray;

  .core-box {
    height: auto;
    padding-bottom: 25px;
    box-shadow: 0 4px 24px 1px rgba(0, 0, 0, 0.03);
    border-radius: 3px;
  }

  .recommend-core-title {
    font-size: 24px;
    margin: 25px 0 12px 0;
  }
}

.recommend-qr-code {
  background-color: #FBFBFB;
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
  .recommend-qr-box {
    border-radius: 0;
    margin-top: -54px;
  }

  .core-functions-recommend {
    padding: 60px 0 30px 0;
  }
}
