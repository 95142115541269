// 客户案例页面样式
.customer-top-box {
    padding-top: rem-calc(90);
    height: rem-calc(330);
    text-align: center;
    background: #fbfcff;
    h3 {
        font-size: rem-calc(32);
        font-weight: 600;
        color: #252525;
        line-height: rem-calc(45);
    }
    p {
        font-size: rem-calc(20);
        font-weight: 400;
        color: #4a4a4a;
        line-height: rem-calc(28);
    }
}
.customer-case-box {
    background: #ffffff;
    margin-bottom: rem-calc(40);
    margin-top: rem-calc(80);
    .case-title {
        font-size: rem-calc(32);
        font-weight: 600;
        color: #252525;
        line-height: rem-calc(45);
        text-align: center;
        margin-bottom: rem-calc(40);
    }
    .case-list {
        margin: 0;
        .case-item {
            list-style: none;
            // padding: 0 rem-calc(10) rem-calc(40);
            margin: 0;
            overflow-y: visible;
            margin-bottom: rem-calc(40);

            &:nth-child(3n-2) {
                padding: rem-calc(0 20 0 0);
            }
            &:nth-child(3n-1) {
                padding: rem-calc(0 10);
            }
            &:nth-child(3n) {
                padding: rem-calc(0 0 0 20);
            }

            div {
                background: #ffffff;
                width: 100%;
                padding-bottom: rem-calc(20);
                box-shadow: 0 rem-calc(10) rem-calc(10) 0 rgba(231, 233, 238, 0.5);
                border-radius: 4px;
                &:hover {
                    box-shadow: 0 rem-calc(20) rem-calc(20) 0 rgba(231, 233, 238, 1);
                }
            }
            .case-img {
                width: 100%;
            }
            .content {
                font-size: rem-calc(14);
                font-weight: 600;
                color: #252525;
                line-height: rem-calc(25);
                padding: 0 rem-calc(15);
                height: rem-calc(75);
                margin: 0;
                text-align: left;
            }
            .case-icon {
                width: rem-calc(118);
                height: rem-calc(42);
            }
            .label-box {
                padding: rem-calc(10) rem-calc(16);
                text-align: left;
            }
        }
    }
}
.label {
    font-size: rem-calc(12);
    font-weight: 600;
    line-height: rem-calc(17);
    padding: rem-calc(4) rem-calc(6);
    &.red {
        color: #ec7063;
        background: #fed0be;
    }
    &.blue {
        background: #bef1fe;
        color: #58a5ec;
    }
    &.green {
        color: #47ad7b;
        background: #befef1;
    }
    &.orange {
        color: #f58523;
        background: #fed8be;
    }
    &.yellow {
        color: #f5a623;
        background: #feedbe;
    }
    &.purple {
        color: #6f60cf;
        background: #e3d3ff;
    }
}
// 客户案例详情样式
.customer-detail-box {
    padding-top: rem-calc(130);
    margin-bottom: rem-calc(80);

    .detail-left {
        .logo {
            width: rem-calc(118);
            height: rem-calc(42);
            &.vipkid {
                margin-left: rem-calc(-27);
            }
            &.cake {
                margin-left: rem-calc(-8);
            }
            &.dingxiangyuan {
                margin-left: rem-calc(-12);
            }
            &.xiaoluyy,
            &.jiandanxinli {
                margin-left: rem-calc(-13);
            }
            &.wanxin {
                margin-left: rem-calc(-26);
            }
            &.jingku {
                margin-left: rem-calc(-11);
            }
            &.kewei {
                margin-left: rem-calc(-11);
            }
            &.leke {
                margin-left: rem-calc(-9);
            }
            &.kaide {
                margin-left: rem-calc(-16);
            }
        }
        .company-info {
            font-size: rem-calc(14);
            color: #252525;
            line-height: rem-calc(20);
            padding-top: rem-calc(16);
        }
        .title {
            font-weight: 500;
            margin: 0;
        }
        .content {
            margin: 0;
        }
    }
    .detail-right {
        h3 {
            font-size: rem-calc(32);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(45);
            margin-bottom: rem-calc(45);
        }
        section {
            box-shadow: 0 rem-calc(3) rem-calc(20) 0 rgba(219, 216, 226, 0.5);
            border-radius: rem-calc(4);
            font-size: rem-calc(14);
            font-weight: 500;
            color: #252525;
            line-height: rem-calc(20);
            padding: rem-calc(26) rem-calc(119) rem-calc(70);
            position: relative;
            .quotation1 {
                position: absolute;
                top: rem-calc(26);
                left: rem-calc(39);
                width: rem-calc(24);
                height: rem-calc(21);
            }
            .quotation2 {
                position: absolute;
                bottom: rem-calc(70);
                right: rem-calc(40);
                width: rem-calc(24);
                height: rem-calc(21);
                transform: rotate(180deg);
            }
            .icon {
                position: absolute;
                bottom: rem-calc(20);
                width: rem-calc(118);
                &.vipkid {
                    right: rem-calc(10);
                }
                &.cake {
                    right: rem-calc(32);
                }
                &.dingxiangyuan {
                    right: rem-calc(28);
                }
                &.xiaoluyy,
                &.jiandanxinli {
                    right: rem-calc(27);
                }
                &.wanxin {
                    right: rem-calc(14);
                }
                &.jingku {
                    right: rem-calc(23);
                }
                &.kewei {
                    right: rem-calc(29);
                }
                &.leke {
                    right: rem-calc(30);
                }
                &.kaide {
                    right: rem-calc(30);
                }
            }
        }
        h4 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(33);
            margin: rem-calc(40) 0 rem-calc(20);
        }
        .content {
            font-size: rem-calc(14);
            color: #4a4a4a;
            line-height: rem-calc(20);
        }
    }
}

@media screen and (max-width: 39.9375em) {
    // 客户案例移动端
    .customer-top-box {
        padding-top: rem-calc(55);
        height: rem-calc(311);
        background: #f9faff;
        position: relative;
        overflow: hidden;
        h3 {
            font-size: rem-calc(20);
            line-height: rem-calc(28);
        }
        p {
            font-size: rem-calc(12);
            color: #252525;
            line-height: rem-calc(18);
            padding: rem-calc(0 40);
        }
        .circle-top-left {
            width: rem-calc(378);
            height: rem-calc(378);
            border-radius: rem-calc(189);
            background: linear-gradient(to bottom, rgba(31, 54, 103, 0.12) 0%, rgba(31, 54, 103, 0.02) 100%);
            border: 2px dashed rgba(95, 150, 157, 0.5);
            position: absolute;
            top: rem-calc(-157);
            left: rem-calc(-189);
        }
        .circle-bottom-right {
            width: rem-calc(184);
            height: rem-calc(184);
            border-radius: rem-calc(92);
            background: linear-gradient(332deg, rgba(31, 54, 103, 0) 0%, rgba(198, 215, 255, 0.23) 100%);
            border: 2px dashed rgba(95, 150, 157, 0.5);
            position: absolute;
            bottom: rem-calc(-56);
            right: rem-calc(-92);
        }
    }
    .customer-case-box {
        margin-top: rem-calc(40);
        .case-title {
            font-size: rem-calc(18);
            color: #202020;
            line-height: rem-calc(24);
            margin: rem-calc(20) 0;
        }
        .case-list {
            justify-content: center;
            .label-box {
                margin-bottom: 0;
            }
            .case-item {
                .content {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                    padding: 0 rem-calc(16);
                    height: auto;
                    margin-bottom: rem-calc(10);
                }
                &:nth-child(3n-2) {
                    padding: 0;
                }
                &:nth-child(3n-1) {
                    padding: 0;
                }
                &:nth-child(3n) {
                    padding: 0;
                }
            }
        }
    }
    // 客户案例详情移动端
    .customer-detail-small-box {
        padding: rem-calc(90) 0 rem-calc(20);
        h3 {
            font-size: rem-calc(20);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(28);
        }
        .customer-img {
            width: 100%;
            height: auto;
            margin-bottom: rem-calc(10);
        }
        .img-logo {
            width: rem-calc(118);
            height: rem-calc(42);
            &.vipkid {
                margin-left: rem-calc(-27);
            }
            &.cake {
                margin-left: rem-calc(-8);
            }
            &.dingxiangyuan {
                margin-left: rem-calc(-12);
            }
            &.xiaoluyy,
            &.jiandanxinli {
                margin-left: rem-calc(-13);
            }
            &.wanxin {
                margin-left: rem-calc(-26);
            }
            &.jingku,
            &.kewei {
                margin-left: rem-calc(-11);
            }
            &.kaide {
                margin-left: rem-calc(-16);
            }
            &.leke {
                margin-left: rem-calc(-9);
            }
        }
        .introduction {
            font-size: rem-calc(18);
            font-weight: 600;
            color: #202020;
            line-height: rem-calc(24);
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(10);
            padding-left: rem-calc(10);
            position: relative;
            &::before {
                content: ' ';
                width: rem-calc(4);
                height: rem-calc(18);
                background: #23b3bc;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: rem-calc(-9);
            }
        }
        .title {
            font-size: rem-calc(18);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(25);
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(10);
        }
        .content {
            font-size: rem-calc(14);
            color: #4a4a4a;
            line-height: rem-calc(22);
        }
    }
}
