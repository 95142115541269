// 页面底部联系我们样式
.bottom-contact-us {
    color: #ffffff;
    align-items: center;
    position: relative;
    .bg-image {
        height: rem-calc(350);
        width: 100%;
    }
    .contact-content {
        color: #000000;
        margin-top: rem-calc(140);
        img {
            width: rem-calc(40);
            height: rem-calc(34);
            margin-top: rem-calc(-5);
            margin-left: rem-calc(15);
        }
        a {
            display: inline-block;
            padding: 0 rem-calc(10) 0 rem-calc(20);
            height: rem-calc(48);
            background: #21b2bb;
            border-radius: rem-calc(48);
            color: #fff;
            font-size: rem-calc(18);
            line-height: rem-calc(48);
            margin-top: rem-calc(25);
            box-shadow: 0 1px rem-calc(8) #1b979fa6;
        }
    }
    h3 {
        font-size: rem-calc(36);
        letter-spacing: rem-calc(8);
        line-height: rem-calc(40);
        font-weight: 500;
        margin-bottom: rem-calc(10);
    }
    p {
        font-size: rem-calc(20);
        line-height: rem-calc(36);
        margin-bottom: rem-calc(30);
        letter-spacing: 1px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .transaction {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 39.9375em) {
    .bottom-contact-us {
        height: rem-calc(214);
        background: url(../img/newhome/contact-us.png) no-repeat center/ cover;
        color: #ffffff;
        h3 {
            font-size: rem-calc(24);
            line-height: rem-calc(36);
            font-weight: 600;
            margin-bottom: rem-calc(12);
            letter-spacing: rem-calc(2);
        }
        p {
            font-size: rem-calc(14);
            line-height: rem-calc(36);
            margin-bottom: rem-calc(20);
            letter-spacing: normal;
        }
        .contact-btn {
            display: inline-block;
            width: rem-calc(108);
            height: rem-calc(40);
            border-radius: 4px;
            color: #ffffff;
            font-size: rem-calc(14);
            font-weight: 500;
            text-align: center;
            line-height: rem-calc(40);
        }
        .contact-content {
            margin-top: rem-calc(50);
        }
    }
}
