.aggregate-header {
    background: #fcfcfd;
    background-image: url(/assets/img/aggregate/banner_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: rem-calc(600);

    .header-section {
        height: 100%;
        width: 100%;
        align-items: center;
        .header-section-item {
            display: inline-flex;

            &:first-of-type {
                justify-content: flex-start;
            }

            &:last-of-type {
                justify-content: flex-end;
            }
            .banner-img {
                height: rem-calc(460);
                float: right;
            }
        }

        .header-section-title {
            display: inline-flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            .title {
                color: #fff;
                font-size: 2.5rem;
                line-height: rem-calc(40);
                font-weight: 500;
                margin-bottom: rem-calc(60);
                letter-spacing: 1px;
                text-align: left;
                .title-polymerization {
                    color: #3d6dde;
                }
                .title-payment {
                    color: #000;
                    background-image: url(../img/saas/bar.png);
                    background-position: bottom;
                    background-size: 100% rem-calc(12);
                    background-repeat: no-repeat;
                }
            }

            .text {
                line-height: rem-calc(36);
                margin-bottom: rem-calc(30);
                letter-spacing: 1px;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
}

.aggregate-sol {
    padding: rem-calc(80) 0 rem-calc(40) 0;
}

.aggregate-sol-nav-container {
    height: rem-calc(66);
    background-color: #fff;
    box-shadow: 0px 5px 5px rgba(215, 223, 233, 0.69);
    .aggregate-sol-nav-box {
        display: flex;
        margin-left: rem-calc(128);

        .box-list {
            padding: 0 rem-calc(10);
            opacity: 0.75;
            cursor: pointer;
            transition: font-size 0.25s, color 0.25s;
            font-size: rem-calc(24);
            line-height: rem-calc(66);
            font-weight: 400;
            color: #000000;
            margin-right: rem-calc(30);
            &.active {
                color: #21b2bb;
                opacity: 1;
                font-weight: 600;
                border-bottom: 2px #21b2bb solid;

                &::before {
                    content: ' ';
                    width: rem-calc(2);
                    height: rem-calc(32);
                    left: rem-calc(-2);
                    top: 50%;
                    margin-top: rem-calc(-16);
                    position: absolute;
                    background: #21b2bb;
                }
            }
        }
    }
}

.aggregate-sol-content {
    margin-bottom: rem-calc(40);

    .aggregate-sol-item {
        &:not(:first-of-type) {
            margin-top: rem-calc(40);
        }

        .title {
            font-size: rem-calc(34);
            font-weight: 500;
            color: #000;
            line-height: 1;
            margin-bottom: rem-calc(165);
            line-height: rem-calc(40);
            height: rem-calc(40);
            text-align: center;
            img {
                height: rem-calc(20);
                margin: 0 rem-calc(10);
            }
            img:last-of-type {
                transform: rotate(180deg);
            }
        }
    }
}

.aggregate-point-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pain-box-left, .pain-box-right {
        box-shadow: 0px 0px 20px 0px rgba(215, 223, 233, 0.69);
        border-radius: rem-calc(4);
        padding: rem-calc(30);
        margin-right: rem-calc(30);
        height: rem-calc(216);
        padding-top: rem-calc(120);
    }
    .text-right, .text-left {
        padding-top: rem-calc(120);
        height: rem-calc(320);
    }
    .aggregate-point-item {
        text-align: center;
        position: relative;
        .aggregate-point-img {
            position: absolute;
            left: 50%;
            top: 0px;
            transform: translate(-60%, -50%);
            width: rem-calc(166);
        }
        h4 {
           text-align: center;
           font-weight: 500; 
           margin-bottom: rem-calc(20);
        }
        .aggregate-point-text {
            padding: 0 rem-calc(15) 0 rem-calc(5);
            text-align: left;
        }
        .aggregate-point-center {
            text-align: center;
        }
    }
}
.aggregate-sol-service {
    background-image: url(/assets/img/aggregate/b2b-service-bg.png);
    margin-bottom: rem-calc(100);
    &:not(:first-of-type) {
        margin-top: rem-calc(40);
    }
    .aggregate-service-content {
        .aggregate-service-item {
            &:not(:first-of-type) {
                margin-top: rem-calc(80);
            }

        .title {
            font-size: rem-calc(34);
            font-weight: 500;
            color: #000;
            line-height: 1;
            margin: rem-calc(35) 0;
            line-height: rem-calc(40);
            height: rem-calc(40);
            text-align: center;
            img {
                height: rem-calc(20);
                margin: 0 rem-calc(10);
            }
            img:last-of-type {
                transform: rotate(180deg);
            }
            }
            width: 100%;

            &:not(:first-of-type) {
                margin-top: rem-calc(40);
            }

            .aggregate-service-info {
                margin: 0;
                display: flex;
                justify-content: space-between;
                .service-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h3 {
                        font-weight: 500;
                        font-size: rem-calc(26);
                        color: #000;
                    }
                }

                .aggregate-service-info-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;

                    &:not(:first-of-type) {
                        margin-top: rem-calc(20);
                    }

                    .aggregate-service-info-img {
                        margin-right: rem-calc(20);
                        width: rem-calc(24);
                        height: ren-calc(24);
                        position: absolute;
                        top: rem-calc(5);
                        left: 0;
                    }
                }
                .aggregate-service-info-text {
                    width: 90%;
                    // font-size: rem-calc(20);
                    line-height: rem-calc(33);
                    color: #000;
                    padding-left: rem-calc(40);
                }
                .profit-sharing-service-info-text {
                    padding-left: rem-calc(0);
                }
            }

            .aggregate-service-title {
                font-size: rem-calc(24);
                line-height: rem-calc(54);
                font-weight: 500;
                color: #202020;
                margin-bottom: rem-calc(30);
                img:last-of-type {
                    height: rem-calc(15);
                    margin: 0 rem-calc(10);
                    transform: rotate(180deg);
                }
            }

            .aggregate-merchant-info {
                list-style: none;
                margin: 0;

                .aggregate-merchant-item {
                    padding: 0 rem-calc(40) rem-calc(40) 0;

                    // &:nth-child(n + 5) {
                    //     padding: 0 rem-calc(20) 0 0;
                    // }

                    // &:nth-child(4n) {
                    //     padding: 0 0 rem-calc(40) 0;
                    // }

                    // &:nth-child(8) {
                    //     padding: 0;
                    //     .aggregate-merchant-content {
                    //         padding: rem-calc(20 30);
                    //     }
                    // }
                }
            }
        }
    }
}
.aggregate-B2B-content {
    margin-bottom: rem-calc(60);

    .aggregate-sol-item {

        &:not(:first-of-type) {
            margin-top: rem-calc(100);
        }
        .title {
            font-size: rem-calc(34);
            font-weight: 500;
            color: #000;
            line-height: 1;
            margin: rem-calc(80) 0 rem-calc(165) 0;
            line-height: rem-calc(40);
            height: rem-calc(40);
            text-align: center;
            img {
                height: rem-calc(20);
                margin: 0 rem-calc(10);
            }
            img:last-of-type {
                transform: rotate(180deg);
            }
        }
        .aggregate-point-item {
            text-align: center;
            position: relative;
            .pain-box-left {
                box-shadow: 0px 0px 20px 0px rgba(215, 223, 233, 0.69);
                border-radius: rem-calc(4);
                padding: rem-calc(30);
                margin-right: rem-calc(30);
                height: rem-calc(216);
            }
            .pain-box-right {
                box-shadow: 0px 0px 20px 0px rgba(215, 223, 233, 0.69);
                border-radius: rem-calc(4);
                padding: rem-calc(30);
                height: rem-calc(216);
            }
            .aggregate-point-img {
                position: absolute;
                left: 50%;
                top: 0px;
                transform: translate(-60%, -50%);
                width: 40%;
                width: rem-calc(166);
            }

            .aggregate-point-text {
                line-height: rem-calc(216);
                text-align: center;
                color: #303e5d;
            }
        }
    }
}



.aggregate-merchant-content {
    background-color: #fff;
    padding: rem-calc(20);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    border-radius: 15px;
    box-shadow: 8px 8px 8px rgba(215, 223, 233, 0.3);

    .aggregate-merchant-text {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        font-weight: 400;
        color: #202020;
        margin-top: rem-calc(12);
        margin-bottom: 0;
        text-align: center;
    }
}

.products-grid {
    & > .column {
        padding-bottom: 1em;
        width: 33%;

        @include breakpoint(small) {
            width: 100%;
        }

        @include breakpoint(medium) {
            width: 50%;
        }
    }
}

.products-filters {
    a {
        &::before {
            content: ' ';
            width: 10px;
            height: 10px;
            background: $gray-6;
            float: left;
            margin-right: 0.7em;
            margin-top: 0.1em;
            border-radius: 2px;
        }

        &.pc-web::before {
            background-color: $lavender;
        }

        &.qr::before {
            background-color: $lime;
        }

        &.wechat::before {
            background-color: $leaf;
        }

        &.mobile-web::before {
            background-color: $sky;
        }

        &.app::before {
            background-color: $apple;
        }
    }

    @include breakpoint(small only) {
        a {
            width: 100%;
        }
    }
}

.products-card {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 1px 1px 5px 5px;
    padding: 1.5rem;
}

.product-card__info {
    text-align: left;

    h5 {
        margin-bottom: 0.4em;
    }

    h6 {
        margin-bottom: 0;
    }
}

.product-card__rates {
    h2 {
        text-align: right;
        margin-bottom: 0;
        color: $gray-6;
    }
}

.product-card__logo {
    font-size: rem-calc(32);
    width: rem-calc(32);
    color: $gray-9;
}

// icons
.ld-content__heroicon.financing {
    background: url('../img/products-heroicon-financing.png') no-repeat;
    background-size: 61px 61px;

    @include breakpoint(retina) {
        background-image: url('../img/products-heroicon-financing@2x.png');
    }
}

.ld-content__heroicon.payment {
    background: url('../img/products-heroicon-payment.png') no-repeat;
    background-size: 61px 61px;

    @include breakpoint(retina) {
        background-image: url('../img/products-heroicon-payment@2x.png');
    }
}

.ld-content__heroicon.transfer {
    background: url('../img/products-heroicon-transfer.png') no-repeat;
    background-size: 61px 61px;

    @include breakpoint(retina) {
        background-image: url('../img/products-heroicon-transfer@2x.png');
    }
}

.hero-bg-circle {
    width: 30rem;
    height: 30rem;
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 30%;
    border-radius: 100%;

    &.green {
        background-color: HSLA(128, 41%, 62%, 0.15);
        top: 65%;
        left: -20%;
        animation: item-hover1em 10s ease-in-out infinite alternate;
        width: 80em;
        height: 80em;
    }

    &.yellow {
        background-color: HSLA(47, 100%, 55%, 0.3);
        top: 50%;
        left: 70%;
        width: 40em;
        height: 40em;
        animation: item-hover3em 10s ease-in-out infinite alternate;
    }

    &.blue {
        background-color: HSLA(201, 100%, 67%, 0.2);
        top: 70%;
        left: 30%;
        animation: item-hover2em 10s ease-in-out infinite alternate;
        width: 60em;
        height: 60em;
    }
}

.products-hero {
    background-image: linear-gradient(180deg, #267cd2 0%, #001a7f 100%) !important;

    .header-section {
        .header-section-title {
            h3 {
                color: #fff;
                font-weight: bold;
            }
        }
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .aggregate-header {
        padding-bottom: 0 !important;
        margin-top: 0;
        height: rem-calc(392);
        padding-top: rem-calc(55);
        &::before,
        &::after {
            content: none;
        }

        .header-section {
            width: 100%;
            .header-section-title {
                width: 100%;
                .title {
                    margin-bottom: rem-calc(0);
                    line-height: rem-calc(45);
                    font-size: rem-calc(28);
                    text-align: center;
                }

                .text {
                    line-height: rem-calc(21);
                    font-size: rem-calc(14);
                    margin-bottom: 0;
                    text-align: center;
                }
            }

            .header-section-item .banner-img {
                height: rem-calc(226);
            }
        }
    }

    .aggregate-sol-nav-container {
        height: rem-calc(33);
        .aggregate-sol-nav-box {
            margin-left: rem-calc(40);
            .box-list {
                font-size: rem-calc(14);
                line-height: rem-calc(33);
                margin-right: rem-calc(10);
            }
        }    
    }


    .aggregate-sol {
        padding: 0;
        margin-top: rem-calc(40);
        margin-bottom: 0;
    }
    .aggregate-B2B-content {
        .aggregate-sol-item {
            .title {
                position: relative;
                font-size: rem-calc(18);
                margin-bottom: rem-calc(20);
                line-height: rem-calc(1);
                margin-top: rem-calc(35);
                height: rem-calc(18);
                img {
                    position: absolute;
                    top: rem-calc(-4);
                    height: rem-calc(10);
                    margin: 0 rem-calc(3);
                }
                .img-left {
                    left: rem-calc(90);
                }
                .img-right {
                    right: rem-calc(90);
                }
            }
        }
        .aggregate-point-content {
            .aggregate-point-item {
                height: rem-calc(109);
                .pain-box-left, .pain-box-right {
                    position: relative;
                    height: rem-calc(109);
                    margin-right: rem-calc(10);
                    padding: rem-calc(20);
                }

                .aggregate-point-img {
                    width: rem-calc(60);
                    height: auto;
                    &.aggregate-point-b2b-img {
                        width: rem-calc(62);
                        height: auto;
                    }
                }

                .aggregate-point-text {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    line-height: rem-calc(18);
                    transform: translate(-50%, -50%);
                    font-size: rem-calc(12);
                    margin: rem-calc(10) 0 0;
                }
            }
        }
    }


    .aggregate-sol-content {
        margin-bottom: rem-calc(40);

        .aggregate-sol-item {
            &:not(:first-of-type) {
                margin-top: rem-calc(40);
            }

            .title {
                position: relative;
                font-size: rem-calc(18);
                color: #202020;
                margin-bottom: rem-calc(50);
                line-height: rem-calc(18);
                height: rem-calc(18);
                img {
                    position: absolute;
                    top: rem-calc(4);
                    height: rem-calc(10);
                    margin: 0 rem-calc(3);
                }
                .img-left {
                    left: rem-calc(90);
                }
                .img-right {
                    right: rem-calc(90);
                }
            }
        }
    }

    .aggregate-point-content {
        .aggregate-point-item {
            height: rem-calc(159);
            .pain-box-left, .pain-box-right {
                height: rem-calc(109);
                margin-right: rem-calc(10);
            }
            .text-left, .text-right {
                height: rem-calc(138);
                padding: rem-calc(40) rem-calc(20);
            }
            h4 {
                font-size: rem-calc(10);
                margin-bottom: 0;
            }

            .aggregate-point-img {
                width: rem-calc(60);
                height: auto;
                &.aggregate-point-b2b-img {
                    width: rem-calc(32);
                    height: auto;
                }
            }

            .aggregate-point-text {
                font-size: rem-calc(12);
                margin-top: rem-calc(10);
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .point-text {
                font-size: rem-calc(8);
            }
        }
    }
    .aggregate-sol-service {
        padding-bottom: rem-calc(10);
        margin-bottom: rem-calc(0);
        .aggregate-service-content {
            .aggregate-service-item {
                .title {
                    font-size: rem-calc(18);
                    position: relative;
                    margin: rem-calc(10);
                    img {
                        position: absolute;
                        top: rem-calc(15);
                        height: rem-calc(10);
                        margin: 0 rem-calc(3);
                    }
                    .img-left {
                        left: rem-calc(70);
                    }
                    .img-right {
                        right: rem-calc(70);
                    }
                }
                width: 100%;

                &:not(:first-of-type) {
                    margin-top: rem-calc(20);
                }

                .aggregate-service-info {
                    margin: 0;
                    flex-direction: column;
                    .service-text {
                        h3 {
                            font-size: rem-calc(16);
                        }
                    }

                    .aggregate-service-info-item {
                        align-items: flex-start;
                        

                        &:not(:first-of-type) {
                            margin-top: rem-calc(10);
                        }

                        .aggregate-service-info-img {
                            margin-right: rem-calc(8);
                            transform: scale(0.5);
                            top: rem-calc(-2);
                        }
                    }
                    .aggregate-service-info-text {
                        font-size: rem-calc(12);
                        line-height: rem-calc(17);
                    }
                }

                .aggregate-service-title {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                    margin-bottom: rem-calc(10);
                }

                .aggregate-merchant-info {
                    list-style: none;
                    margin: 0;

                    .aggregate-merchant-item {
                        padding: 0;

                        &:nth-child(n) {
                            padding-bottom: rem-calc(10);
                        }

                        &:nth-child(n + 1) {
                            padding-right: rem-calc(10);
                        }

                        &:nth-child(7) {
                            padding-bottom: 0;
                        }

                        &:last-of-type {
                            padding-bottom: 0;
                        }
                        &:nth-child(even) {
                            padding-left: rem-calc(10);
                            padding-right: 0;
                        }
                        &:nth-child(8) {
                            .aggregate-merchant-content {
                                padding: rem-calc(20);
                            }
                        }
                    }
                }
            }
        }
    }
    
    .aggregate-merchant-content {
        padding: rem-calc(10);

        .aggregate-merchant-logo {
            transform: scale(0.8);
        }

        .aggregate-merchant-text {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            margin-top: 0;
        }
    }
}
