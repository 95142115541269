.fullscreen{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.hero.fullscreen{
  padding: 0;
}
