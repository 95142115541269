// hero section
.hero {
	// margin-top: -87px;
	padding: 100px 0 70px;
	// background-image: $gradient-white;
	position: relative;

	h1,
	h2,
	h3,
	h4,
	p {
		width: 100%;
		text-align: center;
	}

	&.text-left {
		h1,
		h2,
		h3,
		h4,
		p {
			width: 100%;
			text-align: left;
		}
	}

	&--large-padding {
		padding: 12em 0em 7em;
		@include breakpoint(small only) {
			padding: 8em 0 5em;
		}
	}

	&--text-align-left {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			text-align: left;
			width: auto;
			@include breakpoint(small only) {
				text-align: center;
			}
		}
		.align-self-middle {
			svg {
				margin: 0 auto;
				display: block;
			}
		}
	}

	h1,
	h2,
	h3,
	h4 {
		//word-break: keep-all;
	}

	&.is-full-height {
		padding: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.no-padding-with-bottom {
		padding-bottom: 0 !important;
	}
}

.slide-line-box {
	margin-top: -50px;
	height: 50px !important;
}

.slide-line {
	.column {
		opacity: 0.75;
	}

	.bg-line {
		height: 2px;
		background-color: rgba(0, 0, 0, 0.08);
	}

	.active-line {
		background-color: #202020;
		height: 100%;
		width: 0;
	}

	.line-desc {
		margin-top: 20px;
	}

	img {
		height: 28px;
		margin-right: 16px;
	}
}

.activity-box {
	position: relative;

	.close-btn {
		position: absolute;
		right: 20px;
		top: 10px;
		cursor: pointer;

		span {
			float: left;
			color: #b7b5b5;
			font-size: 16px;
			line-height: 28px;
		}
	}

	img {
		max-width: none;
		width: 100%;
		height: 100%;
	}

	.close-img {
		width: 20px;
	}
}

@media screen and (max-width: 414px) {
	.hero-activity {
		min-width: 100%;
		bottom: 0px;
		font-size: 12px;
	}
	.iconwall-item {
		margin-bottom: 10px;
	}
}

@media screen and (min-width: 414px) {
	.hero-scene-intro {
		top: -50px;
	}
	.line-box-wrap {
		margin-top: -80px;
	}
}

.hero--no-padding {
	& > .row .columns {
		padding: 0;
	}
}

.hero--no-content {
	padding: 0;
	height: 5.125em;
}

.hero-link {
	font-size: 16px;
	margin-right: 24px;
}

.hero--clip {
	overflow: hidden;
	border-bottom: $hr-border;
}

.hero-copy {
	//max-width: 650px;
	margin: 0 auto;
	transition: all 0.5s ease;
	z-index: 9;

	&.text-animate {
		transform: translateY(-10px) rotateY(0deg) rotateX(45deg) rotateZ(0deg) scale(0.5);
		transform-origin: bottom;
		opacity: 0;
	}

	h1 {
		margin-bottom: 0.2rem;
	}

	&.is-animated {
		opacity: 1;
		transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1);
	}
}

$starwars-animation-duration: 20s;

.hero-copy--longform {
	h1 {
		margin-bottom: 1rem;
	}
	h4 {
		text-align: center;
		transform: translateX(0.5rem);
		animation: starwars-opening $starwars-animation-duration forwards linear;
		animation-delay: 2s;
		padding-top: 2rem;
		@include breakpoint(small only) {
		}
	}
	//perspective: 300px;
}

.hero-copy__starwars {
	//transform: rotateX(30deg);
	height: 15rem;
	overflow: hidden;
	animation: starwars-ending 1s forwards ease;
	animation-delay: $starwars-animation-duration + 2s;
	position: relative;
}

.starwars-mask {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: linear-gradient(-180deg, #fcfcfc 1%, rgba(252, 252, 252, 0) 20%, rgba(252, 252, 252, 0) 80%, #fbfbfb 100%);
	z-index: 2;
}

.hero-pic {
	text-align: center;
	transform: translateY(10rem);
	margin-top: -10rem;
	padding-top: 2rem;
}

.hero-bg,
.countdown-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.hero-bg {
	z-index: -1 !important;
}

.hero-bg.home {
	@include breakpoint(medium up) {
		-webkit-mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #000000 22%, #000000 48%, rgba(0, 0, 0, 0.2) 78%, rgba(0, 0, 0, 0) 100%);
		height: 107%;
	}
	& > .row {
		height: 100%;
	}
}

.hero-coin {
	width: 30px;
	height: 30px;
	background: $light-gray;
	position: absolute;
	top: -10%;
	left: 50%;
}

.hero-icons {
	padding: rem-calc(16) 0;
}

.device-wall-wrap {
	width: 100%;
	overflow-x: hidden;
	overflow: hidden;
	margin-bottom: 2rem;

	@include breakpoint(small only) {
		overflow-x: scroll;
	}
}

// device wall
ul.device-wall {
	list-style: none;
	list-style-type: none;
	width: 100%;
	transform: rotateY(0deg) rotateX(-15deg) rotateZ(0deg) scale(1);
	transition: all 1s ease;

	&.is-animated {
		transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1);
	}

	li {
		margin-right: 1rem;
		vertical-align: bottom;
		padding-bottom: 2rem;
		transition: all 0.4s ease;
		opacity: 0;
		transform: translateY(-10px);
		@extend .noselect;

		&.is-animated {
			opacity: 1;
			transform: translateY(0px);
		}

		.device {
			background-repeat: no-repeat;
			background-position: bottom;
			position: relative;
			@extend .noselect;
			&.web {
				width: rem-calc(556);
				height: rem-calc(394);
				background-size: contain;
				background-image: url('../img/web-chrome.png');
				@include breakpoint(retina) {
					background-image: url('../img/web-chrome@2x.png');
				}
			}

			&.apple-pay {
				width: rem-calc(206);
				height: rem-calc(406);
				background-size: contain;
				background-image: url('../img/phone-apple-pay.png');
				@include breakpoint(retina) {
					background-image: url('../img/phone-apple-pay@2x.png');
				}
			}

			&.wechat {
				width: rem-calc(217);
				height: rem-calc(435);
				background-size: contain;
				background-image: url('../img/phone-hongbao.png');
				@include breakpoint(retina) {
					background-image: url('../img/phone-hongbao@2x.png');
				}
			}

			&.qr {
				width: rem-calc(196);
				height: rem-calc(237);
				background-size: contain;
				background-image: url('../img/qr-card.png');
				@include breakpoint(retina) {
					background-image: url('../img/qr-card@2x.png');
				}
				.qr-label {
					width: 100%;
					position: absolute;
					padding: 1.25rem;
					text-align: center;
					color: $gray-8;
				}
			}
		}

		.device-label {
			display: block;
			width: 100%;
			text-align: center;
			z-index: 1;
			position: relative;
			font-size: rem-calc(14);
			font-weight: 500;

			span {
				transition: all 0.2s ease;
				position: absolute;
				display: block;
				width: 100%;
			}

			.title {
				bottom: -1rem;
			}

			.description {
				bottom: -0.5rem;
				opacity: 0;
				text-align: center;
				font-weight: 400;
			}
		}

		&:hover {
			cursor: pointer;
			.device-label {
				.title {
					bottom: -1.5rem;
					opacity: 0;
				}
				.description {
					bottom: -1rem;
					opacity: 1;
				}
			}
		}

		&.focus-out {
			opacity: 0.6;
			transform: scale(0.95);
			filter: grayscale(100%);
		}
	}
}

.phone-title:not(:nth-child(1)) {
	display: none;
}

.text-mask {
	position: relative;
	display: block;
	width: 100%;
	overflow: hidden;
	height: rem-calc(100);
	@include breakpoint(small only) {
		height: rem-calc(60);
		margin-bottom: 1em;
	}
	&.multi {
		height: rem-calc(200);
	}
}

.hero-title-carousel {
	display: block;
	position: relative;
	width: 100%;
	list-style: none;
	margin-left: 0;
}

.hero-title-carousel li {
	display: block;
	position: absolute;
	width: 100%;
}

.hero-title-carousel-hide {
	opacity: 0 !important;
	-webkit-transform: translateY(-100%) translateZ(0) !important;
	-ms-transform: translateY(-100%) translateZ(0) !important;
	transform: translateY(-100%) translateZ(0) !important;
}

.hero-title-carousel-reveal {
	-webkit-transform: translateY(100%) translateZ(0); /* Safari */
	-ms-transform: translateY(100%) translateZ(0); /* IE 9 */
	transform: translateY(100%) translateZ(0);
}

.hero footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: transparent !important;
	padding-top: 0;
	padding-bottom: 1em;
	opacity: 0.4;
	transition: all 0.5s ease;

	&:hover {
		opacity: 0.7;
	}

	p {
		text-align: left;
		display: none;
	}
	@include breakpoint(small only) {
		display: none;
	}
}

.line-box-wrap {
	width: 580px;
	height: 480px;
	overflow: hidden;
	position: relative;
	margin-left: 100px;

	@include breakpoint(small only) {
		width: auto;
		transform: scale(0.8) translateY(20%);
		margin: 80px 0 0;
	}
}

.line-box-viewport {
	position: absolute;
	width: 105%;
	height: 100%;
	//mask-image: linear-gradient(90deg, rgba(0,0,0,0.00) 0%, #000000 15%, #000000, #000000 85%, rgba(0,0,0,0.00) 100%);
	// overflow: hidden;
	margin-left: -1em;
	@include breakpoint(small only) {
		margin-left: auto;
	}
}

.line-boxes {
	position: relative;
	width: 2500px;
	padding-top: 52px;
	transform: translate3d(-430px, 0, 0);
	@include breakpoint(small only) {
		opacity: 0;
	}
	&:last-child {
		margin-right: 0;
	}
	.box {
		float: left;
		width: 166px;
		height: 294px;
		margin-right: 17px;
		background-repeat: no-repeat;
		background-size: cover;
		&.web {
			width: 424px;
			background-image: url('../img/wireframe-dashboard.png');
			@include breakpoint(retina) {
				background-image: url('../img/wireframe-dashboard.png');
			}
		}
		&.apple-pay {
			background-image: url('../img/wireframe-app.png');
			@include breakpoint(retina) {
				background-image: url('../img/wireframe-app@2x.png');
			}
		}
		&.hongbao {
			background-image: url('../img/wireframe-hongbao.png');
			@include breakpoint(retina) {
				background-image: url('../img/wireframe-hongbao@2x.png');
			}
		}
		&.qr {
			background-image: url('../img/wireframe-coupon.png');
			@include breakpoint(retina) {
				background-image: url('../img/wireframe-coupon@2x.png');
			}
		}
	}
}

.scene-slider-wrap {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	// width: 303px;
	// height: 426px;
	.iphone-hand-bg {
		position: absolute;
		top: 80px;
		width: 378px;
		height: 573px;
		background-image: url('../img/home-phone-hand.png');
		background-repeat: no-repeat;
		background-size: cover;
		display: none;
		@include breakpoint(retina) {
			background-image: url('../img/home-phone-hand@2x.png');
		}
	}
}

.device-iphone6 {
	box-sizing: border-box;
	position: absolute;
	top: 8px;
	left: 44px;
	width: 182px;
	height: 380px;
	padding: 44px 8px 40px;
	background-color: #fcfcfc;
	border-radius: 25px;
	z-index: 2;
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 15px 15px 0px rgba(0, 0, 0, 0.03), inset 0px 0px 1px 0px rgba(0, 0, 0, 0.14);
	.topbar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 44px;
		background-color: #fcfcfc;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
	}
	.screen {
		position: relative;
		width: 100%;
		height: 294px;
		overflow: hidden;
		border-radius: 1px;
	}
	.camera {
		position: absolute;
		top: 8px;
		left: 50%;
		width: 4px;
		height: 4px;
		background-color: rgba(29, 28, 28, 1);
		border-radius: 50%;
		transform: translateX(-50%);
	}
	.speaker {
		position: absolute;
		top: 20px;
		left: 50%;
		width: 28px;
		height: 3px;
		background-color: rgba(29, 28, 28, 1);
		border-radius: 4px;
		transform: translateX(-49%);
	}
	.speaker-before {
		position: absolute;
		top: 18px;
		left: 33%;
		width: 6px;
		height: 6px;
		background: rgba(39, 38, 38, 1);
		border-radius: 50%;
		transform: translateX(-49%);
	}
	.home {
		position: absolute;
		bottom: 6px;
		left: 50%;
		transform: translateX(-50%);
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #d9d9d9;
	}
	.home:after {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: #fcfcfc;
	}
}

.scene-viewes {
	position: relative;
	height: 100%;
	.scene {
		position: relative;
		float: left;
		width: 580px;
		height: 480px;
		background-repeat: no-repeat;
        background-size: cover;

		@extend .noselect;
		&.aggregate-pay {
			background-image: url('../img/tweenlite/img/aggregate-pay.png');
			width: 527px;
			height: 480px;

			@include breakpoint(retina) {
				transform: scale(0.8);
				background-image: url('../img/tweenlite/img/aggregate-pay@2x.png');
			}
		}

		&.aggregate-deposit {
			background-image: url('../img/tweenlite/img/aggregate-deposit.png');
			width: 580px;
			height: 480px;

			@include breakpoint(retina) {
				transform: scale(0.8);
				background-image: url('../img/tweenlite/img/aggregate-deposit@2x.png');
			}
		}

		&.pay-system {
			background-image: url('../img/tweenlite/img/pay-system.png');
			width: 580px;
            height: 480px;
            margin-left: 30px;


			@include breakpoint(retina) {
				transform: scale(0.8);
				background-image: url('../img/tweenlite/img/pay-system@2x.png');
			}
		}

		&.cross-border-pay {
			background-image: url('../img/tweenlite/img/cross-border-pay.png');
			width: 580px;
			height: 480px;

			@include breakpoint(retina) {
				transform: scale(0.8);
				background-image: url('../img/tweenlite/img/cross-border-pay@2x.png');
			}
		}

		&.apple-pay {
			border: 1px solid rgba(0, 0, 0, 0.3);
			background-image: url('../img/screen-app-pay.jpg');
		}

		// 会员系统
		&.hongbao {
			background-image: url('../img/newhome/account-system.png');
		}

		// 解决方案 - 资金存管
		&.qr {
			width: 425px;
			height: 293px;
			background-size: cover;
			background-image: url('../img/newhome/solutionfund.png');
		}

		// 多级商户系统
		&.web {
			width: 425px;
			height: 293px;
			background-image: url('../img/newhome/merchants-system.png');
			background-size: cover;
		}

		// 银行存款
		&.bank {
			width: 425px;
			height: 293px;
			background-image: url('../img/newhome/bank.png');
			background-size: cover;
		}
	}
}

.home-feature__copy {
	p {
		font-size: 16px;
		color: #606060;
	}
}

.hero-scene-intro {
	position: relative;
	// top: -50px;
	z-index: 9;
	@include breakpoint(small only) {
		margin-left: auto;
		margin-right: auto;
	}
	.viewport {
		position: relative;
		width: 100%;
		min-width: 370px;
		margin-bottom: -40px;
		height: 480px;
		overflow: hidden;
		@include breakpoint(small only) {
			height: 88px;
		}

		li {
			height: 480px;
			box-sizing: border-box;

			&:first-child,
			&:last-child {
				padding-top: 103px;
			}

			&:nth-child(2) {
				padding-top: 81px;
			}

			&:nth-child(3) {
				padding-top: 116px;
			}

			&:nth-child(4) {
				padding-top: 116px;
			}
		}
	}
	h1,
	h4,
	p {
		text-align: left;
		@include breakpoint(small only) {
			text-align: center;
		}
	}
	h1 {
		word-break: keep-all;

		&.title {
			font-size: 2.65rem;
			font-weight: 500;
			font-weight: 500;
			color: rgba(32, 32, 32, 1);
			line-height: 72px;
		}
	}
	h4 {
		font-size: 1.25rem;
		margin-bottom: 1em;
		white-space: nowrap;
		font-weight: 400;
		color: rgba(124, 132, 134, 1);
		line-height: 24px;
	}

	.cta {
		margin-top: 1.25rem;
	}
}

.hero-scene-text {
	position: relative;
	display: block;
	width: 100%;
	height: 646px;
	min-width: 370px;
	list-style: none;
	margin-left: 0;
}

.hero.fullheight {
	height: 700px;
	min-height: 485px;
	//border-bottom: 1px solid rgba(0,0,0,0.06);

	& > .row {
		height: 100%;
	}

	@include breakpoint(small only) {
		padding-top: 7rem;
		min-height: 600px;
		height: 600px;
		h1.show-for-small-only {
			margin-bottom: 0.2em;
		}
	}
}

.fullheight-column-align {
	align-self: center;
	@include breakpoint(small only) {
		align-self: flex-start;
	}
}
