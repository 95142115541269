.hero-platform {
  background-image: url('../img/bg-platform.png') !important;
}

.platform-title {
  h3 {
    img {
      vertical-align: -9px !important;
    }
  }
}

.horizontal {
  padding-left: 36px;
  .system-box-image {
    .account-rect-img {
      height: auto;
      width: 514px;
    }

    .account-contain-img {
      left: 40px;
      top: 20px;
      width: 460px;
    }
  }

  &.platform {
    margin-top: 40px;
  }
}

.module-sec.platform {
  padding-bottom: 0;
}

.platform-business {
  h3 {
    text-align: center;
    margin-top: 72px;
  }
}

.control-box-fun.platform {
  margin-bottom: 81px;

  .control-box {
    margin: 0;
  }
}

.img-hidden {

  > img:not(:first-child) {
    display: none;
  }
}

@each $index in 1 2 3 4 {
  #scenSlidePlatform#{$index} .account-contain-img {
    width: 17em;
  }
}

#scenSlidePlatform1 {
  .arrow-s-l, .arrow-s-r {
    top: 95px;
  }

  a {
    height: 175px;
  }
}

.pc-rect {
  position: relative;
  min-height: 13em;

  .pc-rect-bg {
    position: absolute;
    top: 0;
    width: 80%;
    left: 10%;
    z-index: -1;
  }

  .scen {
    position: absolute;
    top: 10%;
    left: 17%;
  }
}

.big-item {
  > div {
    height: 135px;

    > .box-icon {
      height: 135px;
      line-height: 135px;
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .hero-platform {
    background-image: url('../img/bg-platform-mobile.png') !important;
  }

  .fenrun {
    background-color: #F5F7F8;
  }
}