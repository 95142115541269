.information-header {
    height: rem-calc(514);
    margin-top: rem-calc(55);
    background-size: cover;
    background-position: center;
    li {
        list-style: none;
        cursor: pointer;
    }
    .box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .title {
        width: 200px;
        ul {
            margin-top: rem-calc(50);
            padding-left: rem-calc(20);
        }
        .information-item {
            display: flex;
            align-items: center;
            color: #b0cadc;
            line-height: rem-calc(60);
            &.active {
                color: #21c1cb;
                position: relative;
                .icon {
                    background: #21c1cb;
                    &::before {
                        content: '';
                        display: inline-block;
                        width: rem-calc(36);
                        height: rem-calc(36);
                        border-radius: rem-calc(36);
                        border: 1px solid #21c1cb;
                        position: absolute;
                    }
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: rem-calc(25);
                    height: rem-calc(2);
                    background: #21c1cb;
                    position: absolute;
                    top: 50%;
                    left: rem-calc(-35);
                }
                &::after {
                    content: '';
                    display: inline-block;
                    width: rem-calc(80);
                    height: rem-calc(2);
                    background: #d1d8da;
                    position: absolute;
                    top: 50%;
                    right: rem-calc(-35);
                }
            }
            .icon {
                width: rem-calc(28);
                height: rem-calc(28);
                border-radius: 50%;
                background: #ecf1f5;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                i {
                    display: inline-block;
                    width: rem-calc(14);
                    height: rem-calc(14);
                    background-size: contain;
                }
            }
            &:nth-of-type(1) {
                i {
                    background-image: url(../img/information/quanbu.png);
                }
                &.active {
                    i {
                        background-image: url(../img/information/quanbu_active.png);
                    }
                }
            }
            &:nth-of-type(2) {
                i {
                    background-image: url(../img/information/building.png);
                }
                &.active {
                    i {
                        background-image: url(../img/information/building_active.png);
                    }
                }
            }
            &:nth-of-type(3) {
                i {
                    background-image: url(../img/information/star.png);
                }
                &.active {
                    i {
                        background-image: url(../img/information/star_active.png);
                    }
                }
            }
        }
    }
    .main-bg {
        flex: 1;
        position: relative;
        height: 100%;
        img {
            &:first-of-type {
                height: rem-calc(400);
                position: absolute;
                right: rem-calc(35);
                top: 50%;
                transform: translateY(-50%);
                z-index: 9;
            }
            &:last-of-type {
                position: absolute;
                top: 25%;
                right: 0;
                height: rem-calc(300);
            }
        }
    }
}

.information-content {
    padding-bottom: rem-calc(60);
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
        margin: 0;
    }
    li {
        list-style: none;
        cursor: pointer;
    }
    .information-content-row {
        display: flex;
        justify-content: space-between;
        .information-content-columns {
            width: 22%;
        }
    }

    .info-list {
        overflow: hidden;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
    .info-item {
        width: 100%;
        padding: rem-calc(8);
        box-sizing: border-box;
        float: left;
        overflow: hidden;
        text-align: left;
        .white-bg {
            background: #fff;
            border-radius: rem-calc(16) rem-calc(16) 0 0;
        }
        a {
            background: #f7f7f9;
            border-radius: rem-calc(16);
            box-shadow: 0 0 6px #e8e8e8;
            display: inline-block;
            width: 100%;
            position: relative;
            padding: 8px;
            .info-content {
                border-radius: 0 0 rem-calc(16) rem-calc(16);
                padding: 15px;
                box-sizing: border-box;
                background: #fff;
            }
        }
        .info-img {
            border-radius: rem-calc(12);
        }
        .info-title {
            font-size: rem-calc(16);
            color: #303e5d;
            margin-bottom: rem-calc(10);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .info-desc {
            font-size: rem-calc(14);
            color: #acb0b9;
            margin-bottom: rem-calc(10);
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 1.2;
            white-space: initial;
        }
        .class-date {
            font-size: 14px;
            color: #9fa8bd;
            margin-top: 15px;
        }
        .class {
            background: #f7f7fb;
            padding: 4px 8px;
            border-radius: rem-calc(5);
        }
        .date {
            float: right;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        color: #c1c8e6;
        font-size: rem-calc(14);
        .prev,
        .next {
            width: rem-calc(50);
            height: rem-calc(50);
            cursor: pointer;
            transition: all 0.2s;
            &.active {
                transform: scale(1.14);
                &:hover {
                    opacity: 0.9;
                }
            }
        }
        .prev {
            margin-left: rem-calc(20);
            background: url(../img/newhome/prev.png) no-repeat center/ 100%;
            &.active {
                background: url(../img/newhome/prev_active.png) no-repeat center/ 100%;
            }
        }
        .next {
            margin-right: rem-calc(20);
            background: url(../img/newhome/next.png) no-repeat center/ 100%;
            &.active {
                background: url(../img/newhome/next_active.png) no-repeat center/ 100%;
            }
        }
        ul {
            margin: 0 rem-calc(20);
        }
        .page-item {
            width: rem-calc(25);
            height: rem-calc(25);
            line-height: rem-calc(25);
            text-align: center;
            &.active {
                color: #21c1cb;
            }
        }
        .jump {
            display: flex;
            align-items: center;
        }
        input {
            width: rem-calc(45);
            height: rem-calc(25);
            border-color: #d3e1ea;
            margin: 0 rem-calc(5);
            font-size: rem-calc(12);
            color: #9fa8bd;
            text-align: center;
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .information-header {
        margin-top: 0;
        height: rem-calc(311);
        padding-top: rem-calc(55);
        background-image: url(../img/information/bg-m.png);
        background-size: 100%;
        margin-bottom: rem-calc(20);
    }
    .information-content {
        .information-content-row {
            display: flex;
            justify-content: center;
            .information-content-columns {
                width: 100%;
            }
        }
        .content-item {
            padding-right: 0;
            margin-bottom: 0;
            .row {
                justify-content: center;
            }
            .content {
                box-shadow: 0px 4px 13px 0 rgba(179, 195, 208, 0.5);

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: rem-calc(20);
                    padding: rem-calc(20);
                    height: auto;
                    margin-bottom: rem-calc(20);
                }
            }
            .title {
                margin-top: rem-calc(20);
                margin-left: 0;
                font-size: rem-calc(18);
                line-height: rem-calc(18);
                height: rem-calc(18);
                padding-left: rem-calc(12);
                margin-bottom: rem-calc(20);
                &:before {
                    width: rem-calc(4);
                    height: rem-calc(16);
                    margin-top: rem-calc(-8) !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .information-content {
        .info-list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 670px) {
    .main-bg {
        display: none;
    }
    .title img {
        position: relative;
        top: 30px;
    }
    .information-content {
        .info-list {
            grid-template-columns: repeat(1, 1fr);
            .info-item a {
                display: flex;
                .white-bg {
                    width: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 10px;
                    border-radius: rem-calc(16) 0 0 rem-calc(16);
                }
                .info-content {
                    flex: 1;
                    overflow: hidden;
                    border-radius: 0 rem-calc(16) rem-calc(16) 0;
                }
            }
        }
        .pages,
        .jump {
            width: 0;
            visibility: hidden;
        }
    }
}
