$styleguide-section-padding: 1em;
$color-palette-width: 10em;

.styleguide{
  .top-bar{
    background: $white;
    border-bottom: $hr-border;
  }

  .side-bar{
    font-size: rem-calc(14);
    font-weight: 500;
    ul.menu{
      li{
        margin-bottom: 0.5em;
      }
    }
    a{
      color: $black;
      border-radius: $global-radius;
      transition: background-color 0.2s ease;

      &:hover{
        background-color: $light-gray;
      }

      span.description{
        display:block;
        color: $gray-8;
        font-size: rem-calc(12);
        margin-top: 0.12em;
      }

      &.active{
        background-color: $primary-color;
        color: $white;
        span.description{
          color: $white;
        }
      }
    }
  }
}

.styleguide-element{
  border: 1px solid $light-gray;
  padding: $styleguide-section-padding;
  border-radius: $global-radius;
  margin-bottom: $styleguide-section-padding / 2;
  &.no-padding{
    padding: 0;
  }
  &.h1-no-margin h1{
    margin-bottom: 0.5rem;
  }
  .row:last-child .callout{
    margin: 0;
  }
}

.styleguide-caption {
  font-size: rem-calc(12);
  font-weight: 500;
  display: inline-block;
  color: $dark-gray;
  line-height: 1.2;
}

.styleguide-content{
  margin-top: 2em;
}

.styleguide-head{
  margin-bottom: $styleguide-section-padding;
  padding-bottom: $styleguide-section-padding;
  border-bottom: $hr-border;
  h2{
    margin-bottom: 0.1em;
  }
}

.styleguide-section{
  padding: $styleguide-section-padding 0;
  h3{
    margin-bottom: 0.45em;
  }
  h6.styleguide-section--title{
    margin-bottom: 0.7em;
    font-size: rem-calc(14);
    margin-top: 1em;
  }
}

.color-palette{
  list-style: none;
  margin: 0;
  clear: both;

  .color-palette-item{
    width: $color-palette-width;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);
    border-radius: $global-radius;
    float: left;
    margin-right: 0.5em;
    margin-bottom: 0.5em;

    .fill{
      width: 100%;
      height: $color-palette-width / 1.61;
      border-radius: $global-radius $global-radius 0 0;
      border-bottom: 1px solid rgba(0,0,0,0.05);
    }

    .info{
      font-size: rem-calc(12);
      padding: 0.5em;
      .name{
        font-weight: 500;
        font-size: rem-calc(14);
      }
      .var, .meta{
        font-family: $font-family-monospace;
        display: block;
        text-transform: lowercase;
        font-size: rem-calc(12);
      }
    }

  }
}
