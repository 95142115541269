$ld-section-top-bottom-padding: 4rem;

// Landing content section

.ld-content{
  & > .row{
    padding: $ld-section-top-bottom-padding 0em;
      @include breakpoint(small only) {
        padding: $ld-section-top-bottom-padding/2 0em;
      };
    }
  p:last-child{
    margin-bottom: 0;
  }
  p.gray{
    color: rgba(0,0,0,0.4);
  }
  dl{
    dt{
      margin-top: 1em;
    }
  }
}

.ld-content--center{
  text-align: center;
  h3, p,img{
    text-align: center;
  }
}

.ld-content--no-overflow {
  width:100%;
  overflow: hidden;
}

.ld-content--no-padding{
  width:100%;
  overflow: hidden;
  & > .row{
    padding: 0;
  }
  @include breakpoint(small only){
    .ld-content__body{
      padding: $ld-section-top-bottom-padding 2em 0em;
    }
  };
}

.ld-content--no-padding-bottom{
  & > .row{
    padding-bottom: 0;
  }
}

.ld-content--blue {
  background:$gradient-dark-blue;
  color:#fff;
}

.ld-content--gray{
  background: $gray-2;
  border-bottom: $hr-border;
  & > .row{
    border-bottom: none !important;
  }
}

.ld-content--no-border{
  border-bottom: none;
  & > .row{
    border-bottom: none !important;
  }
}

.ld-content--invert{
  color: $white;
  background: $gradient-black;

  h1,h2,h3,h4,h5,h6{
    color: $white;
    text-shadow: 0px 3px 5px rgba(0,0,0,0.25);
  }

  p, .ld-content__hero h5{
    text-shadow: 0px 2px 3px rgba(0,0,0,0.25);
    color: hsla(130, 50%, 100%, .8);
  }

  & > .row{
    border-bottom: none !important;
  }
}

.ld-content--bluegreen{
  @extend .ld-content--invert;
  background: #2A6591;
  background: radial-gradient(circle farthest-side at left bottom, #43a1bf 0%, #3A9CC9 10%, #2A6591 34%, #284682 63%, #223054 100%);
}

.ld-content--darkgray{
  @extend .ld-content--invert;
  background: #4C5B6E;
  background-image: linear-gradient(163deg,#1b2128,#61758e);
}

.ld-content--midgray{
  @extend .ld-content--invert;
  background: #607485;
  background: radial-gradient(circle farthest-side at left top, #98AEB9 0%, #798F9D 30%, #607485 100%);
}

.ld-content--lightgray{
  background: #eeeeee;
  background: radial-gradient(circle farthest-side at left top, #fcfcfc 0%, #eaeaea 100%);
}

.ld-content--productgray{
  background: #f0f0f0;
  border-bottom: $hr-border;
}

.ld-content--white{
  background: #fff;
  border-bottom: $hr-border;
}


.ld-content--productblue{
  background: #F7FAFC;
  background-image: linear-gradient(-180deg, #F7FAFC 3%, #F0F4FA 98%);
}

.ld-content-productyellow{
  background: #FDFCF9;
  background-image: linear-gradient(-180deg, #FDFCF9 0%, #FCFAF0 95%);
  border-bottom: $hr-border;
}

.ld-content-productwhite{
  background: #ffffff;
  background-image: linear-gradient(-180deg, #FFFFFF 0%, #FAFAFA 100%);
  border-bottom: $hr-border;
}

.bottom-cta{
  text-align: center;
  padding: 60px 0 120px;

  p:last-child{
    margin-bottom: 0;
  }

  &--border-top{
    & >.row{
      border-top: $hr-border;
    }
  }

  .logo-grid__column {
    padding: 0;
  }
}

.bottom-divider{
  height: 3px;
  background: $gray-2;
  hr{
    border-color: #EBEBEB;
    margin-top: 0;
  }
}

.bottom-cta--gray{
  background: $gray-2;
}
.bottom-cta--blue{
  background: #35C8E6;
  color:rgb(255,255,255);
  padding-top: 138px;
  height: 420px;
  box-sizing: border-box;
}

.bottom-cta--bottom {
  margin-top: 24px;
  font-weight: bold;

  a {
    color: white;
  }
}

.button.register{
  margin-top: 36px;
  background-color: #fff;
  color: #35C8E6;
}


.bottom-cta--black{
  background:rgb(71, 71, 71);
  color:rgb(255,255,255);
}

.bottom-cta--nonprofit{
  background-image: url("../img/bottom-cta--nonprofit.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color:#29614E;
}

.bottom-cta--outline{
  & > .row{
    border: $hr-border !important;
    padding: $ld-section-top-bottom-padding 0 ($ld-section-top-bottom-padding)-1.5rem;
    border-radius: $global-radius;
  }
}

.ld-feature-grid{
  text-align: center;
}

.ld-feature-grid__column{
  padding: $ld-section-top-bottom-padding/2 0 $ld-section-top-bottom-padding;
  p{
    padding: 0em 3em;
    color: $gray-9;
    @include breakpoint(small only) {
      padding: 0em 1em;
    }
  }
  h5, h6{
    margin-bottom: 0.5em;
  }
}

.ld-feature-grid--left{
  text-align: left;
  .ld-feature-grid__column p{
    padding-left: 0;
  }
}

.ld-feature-grid--no-padding{
  .ld-feature-grid__column{
    padding-bottom: 0;
  }
}

.ld-feature-grid__icon{
  padding: 2rem 0;
  height: 7.5rem;
  align-items: center;
  justify-content: center;
}

.ld-feature-grid__digit{
  //padding: 2rem 0 0;
  color: $gray-6;
  h1{
    margin-bottom: 0.5rem;
  }
}

.ld-faq{
  & > .row{
    padding: $ld-section-top-bottom-padding 0;
    @include breakpoint(small only){
      padding: $ld-section-top-bottom-padding/2 0;
    };
    border-bottom: none !important;
  }

  h4{
    text-align: center;
  }

  .accordion{
    border: none;
    .accordion-item{
      a{
        font-size: rem-calc(16);
        color: $anchor-color;
      }
      &.is-active{
        background: none;
      }
      &:last-child a{
        border: none;
      }
    }
    .accordion-title{
      transition: background 0.2s ease;
    }
    .accordion-title:hover{
      background: $gray-3;
    }
    .accordion-title:focus{
      background: none;
    }
    .accordion-content{

    }
  }
}

.ld-content-container{
  section > .row{
    // border-bottom: $hr-border;
  }
  section:last-child > .row{
    border: none;
  }
}

.ld-logo-wall{

  & > .row{
    padding: 2rem 0;
    @include breakpoint(small only){
      padding: 0 0;
    };
  }
  text-align: center;
  img{
    max-height: 25px;
  }
  .ld-logo-wall__grid{
    margin: $ld-section-top-bottom-padding/1.61 0;
  }

}

.typical-case-logo-wall {
  padding: 0  0 3rem 0;
  img{
    max-height: 18px;
  }
  h4,p {
    text-align: left;
  }
  .text-gray {
    color:#9a9a9a;
  }
  .thumbnail-bg {
    position: relative;
    width:100%;
    height:252px;
    border-radius: 3px;
    background: rgba(255,255,255,0.95);
    box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.05);
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 2rem;

    &.shiji {
      background-image: url("/assets/img/customer-case-shiji.png");
      background-position: center right;
    }
    &.mmcar {
      background-image: url("/assets/img/customer-case-mmcar.jpg");
      background-position: center center;
    }

    .small-logo {
      position: absolute;
      bottom:-1em;
      right:-1rem;
      width: 55px;
      height: 55px;
      border-radius: 14px;
      box-shadow: 0 2px 4px rgba(0,0,0,.05),0 8px 12px rgba(0,0,0,.1);
      background-size: cover;
      background-repeat: no-repeat;

      &.shiji {
        background-image: url("../img/shiji-small-logo.png");
      }

      &.mmcar {
        background-image: url("../img/mmcar-small-logo1.png");
      }
    }
  }

  .logo-icon {
    margin-bottom: 1rem;
  }
  @include breakpoint(small only){
    h4 {
      // text-align: center;
    }
  };
}

.ld-logo-wall--grayscale{
  img{
    transition: all 0.2s ease;
    filter: grayscale(100%) brightness(120%);
    &:hover{
      filter: grayscale(0%) brightness(100%);
    }
  }
}


.device-android-shell{
  background: url("../img/device-white-android-shell.png") no-repeat;
  background-size: 265px 502px;
  width: 265px;
  height: 502px;
  @include breakpoint(retina) {
    background-image: url("../img/device-white-android-shell@2x.png");
  }

  .device-screen{
    width: 213px;
    height: 377px;
    margin-top: 58px;
    margin-left: 25px;
    background-color: $light-gray;
    background-size: 213px 377px;
    background-repeat: no-repeat;
  }
}

.ld-content__hero{
  padding-bottom: $ld-section-top-bottom-padding/2;
  text-align: center;

  h2{
    text-align: center;
    margin-bottom: 0.2em;
  }

  h5{
    text-align: center;
    margin-bottom: 0.5em;
    color: $gray-9;
  }

  h3{
    font-weight: 300;
    font-size: rem-calc(24);
    margin-bottom: 0.5em;
    margin-top: 1em;
  }

  h6{
    font-weight: 300;
    font-size: rem-calc(16);
    line-height: rem-calc(24);
  }

  &--left{
    text-align: left;
    h2,h3,h4,h5,h6{
      text-align: left;
    }
  }


  .ld-content__heroicon{
    margin-bottom: 1.2em;
  }

}

.ld-content__hero--no-padding{
  padding-bottom: 0;
}

.ld-content__heroicon{
  border-radius: 100%;
  width: 61px;
  height: 61px;
  margin: 0 auto;
}

.ld-content__heroicon--small{
  width: 32px;
  height: 32px;
  background-size: contain !important;
}

.ld-content__callout{
  .left, .left h3{
    text-align: right;
    margin: 0;
    margin-top: 0.15em;
  }
  .right, .right h1{
    text-align: left;
    font-size: rem-calc(68);
    font-weight: 100;
    margin: 0;
  }
  margin-bottom: 2em;
}

.ld-ingridcta{
  .inner-row{
    padding: 2em 2em;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 6px;
    margin-bottom: 4em;
    @include breakpoint(small only) {
      margin-bottom: 1em;
    };
    p{
      margin: 0;
    }
  }
}

.ld-icongrid{
  &__icon{
    padding-bottom: 1em;
  }
}
