.stickers-wrap {
    .business-friend {
        background-color: #F5F7F8;
        &.h5 {
            padding-top: 0;
        }
    }
    .h4title {
        margin-top: 40px;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 40px;
        border-bottom: 1px rgba(0, 0, 0, .08) solid;
    }
    .stickers {
        background-color: #F5F7F8;

        ul {
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            margin: 0;
        }

        li {
            display: inline-block;
            margin: 0 -21px;
        }

        img {
            margin-bottom: 20px;
            height: 300px;
        }
    }
}