.industrial-internet-box {
    img {
        height: rem-calc(273);
        width: auto;
    }
}
.industrial-internet-container {
    .pain-outer-box {
        position: relative;

        padding: rem-calc(20 10 0);
        &:first-child::before {
            content: '1';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            position: absolute;
            background: transparent;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &:first-child::after {
            content: ' ';
            width: 0;
            height: 0;
            border-top: 48px solid #3666de;
            border-right: 48px solid #3361d6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        &:nth-child(2)::before {
            content: '2';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            position: absolute;
            background: transparent;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &:nth-child(2)::after {
            content: ' ';
            width: 0;
            height: 0;
            border-top: 48px solid #3666de;
            border-right: 48px solid #3361d6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        &:last-child::before {
            content: '3';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            position: absolute;
            background: transparent;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &:last-child::after {
            content: ' ';
            width: 0;
            height: 0;
            border-top: 48px solid #3666de;
            border-right: 48px solid #3361d6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        .pain-box {
            height: rem-calc(158);
            color: #252525;
            background: #f3f6ff;
            border: 1px solid #eef1ff;
            padding: rem-calc(20 30 20 40);
            position: relative;
            z-index: 1;
            &::before {
                content: ' ';
                width: 100%;
                height: rem-calc(158);
                background: linear-gradient(to left top, transparent 50%, #f2f5ff 50%);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
            &::after {
                content: ' ';
                width: 100%;
                height: rem-calc(158);
                background: linear-gradient(to right bottom, transparent 50%, #ecf1ff 50%);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
            h4 {
                font-size: rem-calc(20);
                font-weight: 600;
                color: #252525;
                line-height: rem-calc(33);
            }
            p {
                font-size: rem-calc(14);
                font-weight: 400;
                color: #252525;
                line-height: rem-calc(24);
            }
        }
    }

    .service-box {
        // padding-bottom: rem-calc(30);
        &:nth-child(odd) {
            padding-right: rem-calc(50);
        }
        &:nth-child(even) {
            padding-left: rem-calc(50);
        }
        img {
            width: rem-calc(60);
            height: rem-calc(60);
        }
        h4 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(33);
        }
        p {
            font-size: rem-calc(16);
            font-weight: 400;
            color: #252525;
            line-height: rem-calc(24);
        }
    }
    .advantage-outer-box {
        background: #fbfcfd;
        box-shadow: 0px 23px 30px 0px #e4e9ef;
        border-radius: rem-calc(10);
        padding: rem-calc(78 103 47 65);
        height: rem-calc(167);
        position: relative;
        .advantage-box {
            // position: relative;
            // z-index: 1;
            h4 {
                font-size: rem-calc(24);
                font-weight: 600;
                color: #252525;
                line-height: rem-calc(36);
            }
            p {
                font-size: rem-calc(14);
                font-weight: 400;
                color: #202020;
                line-height: rem-calc(21);
            }
            img {
                position: absolute;
                top: rem-calc(23);
                right: rem-calc(71);
                height: rem-calc(133);
                // z-index: -1;
            }
        }
    }
}

@media screen and (max-width: 1440px) and(min-width:1240px) {
    .industrial-internet-container {
        .advantage-outer-box {
            padding: rem-calc(78 43 47 65);
            .advantage-box {
                h4 {
                    margin-bottom: rem-calc(16);
                }
            }
        }
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .industrial-internet-box {
        img {
            height: rem-calc(185);
            width: auto;
        }
    }
    .industrial-internet-container {
        .pain-outer-box {
            padding: rem-calc(25 10);
            &:first-child::before,
            &:nth-child(2)::before,
            &:last-child::before {
                width: rem-calc(36);
                height: rem-calc(36);
                font-size: rem-calc(24);
                font-weight: bold;
                color: #ffffff;
                line-height: rem-calc(36);
                text-align: center;
                position: absolute;
                background: transparent;
                top: 0;
                left: 0;
            }
            &:first-child::after,
            &:nth-child(2)::after,
            &:last-child::after {
                content: ' ';
                width: 0;
                height: 0;
                border-top: 36px solid #3666de;
                border-right: 36px solid #3361d6;
                position: absolute;
                top: 0;
                left: 0;
            }
            .pain-box {
                height: rem-calc(138);
                h4 {
                    margin-bottom: rem-calc(10);
                }
                &::before {
                    height: rem-calc(138);
                }
                &::after {
                    height: rem-calc(138);
                }
            }
        }
        .advantage-outer-box {
            padding: rem-calc(35 30 36);
            margin-bottom: rem-calc(20);
            height: rem-calc(107);
            .advantage-box {
                h4 {
                    font-size: rem-calc(18);
                    margin-bottom: rem-calc(10);
                }
                img {
                    height: rem-calc(92);
                    top: rem-calc(7);
                    right: rem-calc(43);
                    transform: scale(0.9);
                }
            }
        }
        .service-box {
            &:nth-child(odd) {
                padding-right: rem-calc(20);
            }
            &:nth-child(even) {
                padding-left: rem-calc(20);
            }
            img {
                width: rem-calc(45);
                height: rem-calc(45);
                margin: 0 auto;
                display: block;
            }
            h4 {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                text-align: center !important;
            }
            p {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
        }
    }
}
