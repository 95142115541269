.contact-outer-box {
    position: relative;
    .bg {
        position: absolute;
        width: 100%;
        height: rem-calc(300);
        top: rem-calc(500);
        left: 0;
        z-index: -2;
    }
}
.types {
    display: flex;
    margin: 0 auto;
    align-items: flex-end;
    position: relative;
    padding-top: rem-calc(70);
    &::after {
        content: '';
        display: inline-block;
        width: rem-calc(180);
        height: rem-calc(117);
        background-image: url('../img/contact/point.png');
        background-size: cover;
        position: absolute;
        top: rem-calc(45);
        right: rem-calc(280);
        z-index: -1;
    }
    &::before {
        content: '';
        display: inline-block;
        width: rem-calc(180);
        height: rem-calc(117);
        background: #faf7f7;
        opacity: 0.2;
        position: absolute;
        bottom: rem-calc(-10);
        left: 40%;
        border-radius: 10px;
        z-index: -1;
    }
    .left-box {
        flex: 1;
        margin-right: 2vw;
        margin-bottom: rem-calc(30);
        &::before {
            content: '';
            display: inline-block;
            width: rem-calc(180);
            height: rem-calc(117);
            background: rgba(87, 161, 240, 0.2);
            opacity: 0.2;
            position: absolute;
            bottom: rem-calc(0);
            left: rem-calc(-30);
            border-radius: 10px;
        }
    }
    .right-img {
        width: 20vw;
        height: 13.2vw;
        min-width: rem-calc(330);
        min-height: rem-calc(220);
        margin-right: rem-calc(-40);
    }
}
.contact-box {
    padding-top: rem-calc(120);
    padding-bottom: rem-calc(55);
    position: relative;
    .banner-desc {
        font-size: rem-calc(28);
        color: #0a0a0a;
        font-weight: 500;
        padding-left: rem-calc(8);
        position: absolute;
        top: 0;
        left: 0;
        &::before {
            display: inline-block;
            content: '';
            width: rem-calc(6);
            height: rem-calc(26);
            background: #55b2ba;
            position: absolute;
            left: -8px;
            top: 8px;
        }
    }
    .contact-types {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: rem-calc(20);
        align-items: flex-end;
        .type-item {
            min-width: rem-calc(150);
            min-height: rem-calc(150);
            position: relative;
            text-align: center;
            padding-top: rem-calc(5);
            box-shadow: 1px 0px 20px 0px rgba(87, 161, 240, 0.2);
            border-radius: rem-calc(10);
            background: #fff;
            img {
                width: rem-calc(70);
                height: rem-calc(70);
            }
            .type-title {
                color: #0a0a0a;
                font-size: rem-calc(16);
                font-weight: 500;
                margin-bottom: rem-calc(4);
            }
            .type-desc {
                font-size: rem-calc(13);
                color: #62676c;
            }
            .open-form-btn {
                cursor: pointer;
                color: #21b2bb;
            }
        }
    }
    .contact-right {
        overflow: hidden;
        height: rem-calc(346);
        background-image: url('../img/contact/bg.png');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: rem-calc(40);
        position: relative;
        .right-bottom-box {
            display: flex;
            justify-content: space-around;
            padding-top: rem-calc(160);
        }
        .info {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(14);
            color: #ffffff;
            line-height: rem-calc(20);
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
            width: 700px;
            p {
                margin: 0;
            }

            .big {
                font-size: rem-calc(24);
                font-weight: 600;
                color: #ffffff;
                line-height: rem-calc(33);
                font-family: DINAlternate-Bold, DINAlternate;
                span {
                    font-size: rem-calc(54);
                    font-weight: bold;
                    line-height: rem-calc(63);
                }
            }
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .contact-outer-box {
        position: relative;
        .bg {
            display: none;
        }
    }
    .contact-box {
        padding-top: rem-calc(60);
        padding-bottom: 0;
        position: relative;
        .banner-desc {
            font-size: rem-calc(22);
            &::before {
                left: -8px;
                top: 4px;
            }
        }
    }
    .contact-types {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: rem-calc(12);
        .type-item::before {
            content: unset !important;
        }
    }
}

@media screen and (max-width: rem-calc(1250)) {
    .contact-box {
        .types {
            align-items: center;
            &::before,
            &::after {
                content: unset !important;
            }
        }
        .left-box {
            margin-bottom: unset;
        }
        .left-box::before {
            content: unset !important;
        }
        .contact-types {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
        .right-img {
            width: 40vw;
            height: 26.68vw;
            margin-right: unset;
        }
    }
}
