.link-header {
    height: rem-calc(760);
    background-image: url(../img/link/bg.png);
    background-size: cover;
    background-position: center;
    position: relative;

    & > img {
        &:nth-of-type(1) {
            position: absolute;
            top: 38%;
            left: 18%;
            width: 50px;
        }
        &:nth-of-type(2) {
            position: absolute;
            top: 46%;
            left: 20%;
            width: 250px;
        }
        &:nth-of-type(3) {
            position: absolute;
            top: 58%;
            left: 35%;
            width: 40px;
        }
    }
    h2 {
        font-weight: 600;
        font-size: rem-calc(32);
    }
    a {
        width: 400px;
        position: absolute;
        right: 15%;
        top: 40%;
        color: #2b3753;
        font-size: 14px;
        background: #f7fafa94;
        border: 2px solid #fff;
        border-radius: 28px;
        padding: 40px;
        .inner {
            background: #fff;
            border-radius: 28px;
            padding: 30px;
            box-shadow: 0 0 20px 0 rgba(32, 38, 52, 0.1);
            img {
                width: 50px;
                height: 50px;
                border-radius: 16px;
                margin-right: 10px;
            }
        }
        h3 {
            font-size: 14px;
            margin: 30px 0 10px;
        }
        p {
            color: #a1aac0;
        }
    }
}

.link-list {
    margin: rem-calc(50) 0 rem-calc(30);
}

.link-grid {
    display: grid;
    gap: rem-calc(20);
    grid-template-columns: repeat(4, 1fr);
    li {
        list-style: none;
        box-shadow: 8px 8px 20px 0 rgba(32, 38, 52, 0.06);
        border-radius: rem-calc(10);
        height: 120px;
        transition: all 0.3s ease;
        &:hover {
            box-shadow: 8px 8px 20px 0 rgba(32, 38, 52, 0.1);
        }
        a {
            width: 100%;
            height: 100%;
            padding: rem-calc(30);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .link-header {
        height: rem-calc(164);
    }

    .link-grid {
        display: grid;
        gap: rem-calc(10);
        grid-template-columns: repeat(2, 1fr);
    }
}
