.hero-icons {
    max-width: 960px;
    margin: 0 auto;
    margin-top: 5em;

    h5,
    h6,
    p {
        color: $white;
    }

    h6 {
        text-align: center;
        font-size: rem-calc(14);
        font-weight: 500;
        margin-bottom: 7px;
    }

    p {
        font-size: rem-calc(13);
        font-weight: 400;
    }

    .column {
        opacity: 0.7;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }

        @include breakpoint(small only) {
            padding-bottom: 1.5em;
        }
    }
}

.hero-icons__icon {
    margin-bottom: rem-calc(13);
    height: 44px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        align-self: flex-end;
    }
}

.products {
    &-hero {
        .hero-copy {
            margin-top: 72px;

            h1 {
                font-size: rem-calc(48);
                font-weight: 300;
                line-height: 72px;
                margin-bottom: 28px;

                @include breakpoint(small only) {
                    font-size: rem-calc(48);
                }
            }

            h4 {
                margin-bottom: 120px;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                opacity: .7;
            }

            h3 {
                margin-bottom: 24px;
                font-size: 24px;

                img {
                    margin-right: 10px;
                    vertical-align: -2px;
                }
            }

            .hero-arrow {
                display: inline-block;
                width: 22px;
                height: 36px;
                background: url('../img/arrowdown.png');
                background-size: cover;
            }
        }


    }

    &-section-head {
        padding-top: 6.125rem;
        padding-bottom: 5.125rem;

        p {
            color: #565656;
            padding-top: 44px;

            @include breakpoint(small only) {
                padding-top: 7px;
            }

            &.no-padding-top {
                padding-top: 0;
            }

            &.more {
                font-size: 16px;
                padding-top: 0;
                padding-right: 6px;
                margin-top: -2px;

                @include breakpoint(small only) {
                    // text-align: center;
                    padding-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }

        h2+p {
            padding-top: 0;
        }

        .home-feature__copy {
            h5 {
                margin-bottom: 13px;
            }
        }

        .home-feature__bg {
            margin-top: 2em;
        }

        &.is-comingsoon {
            p {
                padding-top: 5.5em;

                @include breakpoint(small only) {
                    padding-top: 7px;
                }
            }
        }
    }

    &-section-head--no-bottom-padding {
        padding-bottom: 0;
    }

    &-section-hero {
        h5 {
            font-size: rem-calc(18);
            text-align: center;
            color: #717171;
            margin-bottom: 7px;
        }

        h2 {
            font-size: rem-calc(28);
            text-align: center;
            margin-bottom: 20px;
            font-weight: 300;
        }

        p {
            font-size: rem-calc(16);
            text-align: center;
            color: #616161;
        }
    }

    &-methodspromo {
        background: transparent !important;

        .hero-icons__icon {
            img {
                align-self: center !important;
            }
        }

        li:hover {
            transform: scale(1) !important;
        }

        li a.block-grid-link {

            h6 {
                color: #343434;
                margin-bottom: 3px;
                font-size: rem-calc(14);
                padding-top: 9px;
                font-weight: 500;
            }

            h5 {
                margin-bottom: -6px !important;
            }
        }
    }

    &-method-icons {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-top: -2rem;

        &>ul {
            list-style: none;
            position: relative;
            z-index: 1;
            top: 0;
            left: 0;
            height: 155px;
            width: 3636px;
            margin-bottom: 3rem;
        }

        &>ul.animate {
            animation: loop 60s linear 1;
        }

        li {
            position: relative;
            text-align: justify;
            text-justify: distribute-all-lines;
            line-height: 0;
            letter-spacing: -0.31em;
            float: left;
            overflow: hidden;
            height: 155px;
            width: 1818px;
            ;
        }

        &>ul>li>* {
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            line-height: 1;
            letter-spacing: 0;
            margin: 2em 7px;
        }
    }

    &-iconwall {
        padding-bottom: 3.125rem;
        padding-top: 1.125rem;
        border-top: 1px solid rgba(0, 0, 0, 0.06);

        .iconwall-item {
            padding-left: 0.9375rem;

            @include breakpoint(large) {
                padding-right: 10rem;
            }

            @include breakpoint(small only) {
                text-align: center;

                .wall-icon {
                    margin: 0 auto;
                    margin-bottom: 13px;
                }

                p {
                    width: 60%;
                    margin: 0 auto;
                }
            }

            ;
        }
    }
}

.pay-container {
    .module-fir {
        padding-top: 80px;
        padding-bottom: 48px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        color: #202020;

        h2 {
            margin-bottom: 12px;
            font-size: 48px;
            line-height: 70px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .module-sec {
        padding: 88px 0;

        .division {
            margin-bottom: 68px;

            h3 {
                font-size: 36px;
                line-height: 52px;
            }

            p {
                margin-bottom: 22px;
                font-size: 16px;
                line-height: 24px;
            }

            .function {
                cursor: pointer;
                padding-right: 22px;
                padding-bottom: 22px;

                .fun-in {
                    padding: 16px;
                    background: #F9F9F9;
                    border-radius: 5px;

                    strong {
                        color: #909090;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }

                .api-in {
                    padding: 10px 26px 26px;
                    background: #F9F9F9;
                    border-radius: 5px;
                    cursor: default;

                    img {
                        margin-bottom: 6px;
                    }

                    strong {
                        display: block;
                        margin-bottom: 8px;
                        font-size: 18px;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                        opacity: .7;
                    }
                }

                .special-channel {
                    height: 200px;
                }

                .loan {
                    height: 200px;
                }

                .authentication-service {
                    height: 250px;
                }

                .img-box {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.product-info {
    padding: 86px 0;

    .odd {
        padding-right: 135px;
    }

    strong {
        display: block;
        margin-bottom: 16px;
        font-size: 72px;
        font-weight: 500;
        line-height: 1;
        color: rgba(255, 255, 255, .5);
    }

    h2 {
        font-size: 48px;
        line-height: 70px;
    }

    h5 {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
    }

    p {
        font-size: 14px;
        color: rgba(255, 255, 255, .5);
    }

    .mb {
        margin-bottom: 64px;
    }
}

.module-thi {
    padding: 46px 0;
    background-color: #1F3074;
    color: #fff;
}

.customer-btn-outer {
    margin: 72px 0 88px;
}

.customer {
    h2 {
        margin: 88px 0 72px;
    }
}

.dev-links-outer {
    background-color: #F9F9F9;
    padding: 108px 0 164px;
}

.dev-links {
    margin-top: 64px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    text-align: center;

    &:first-child {
        text-align: left;
    }

    &:last-child {
        border-right: 0;
    }
}

@media only screen and (max-width : 414px) {
    .product-info {
        .odd {
            padding-right: 0;
        }

        h2 {
            order: -1;
        }
    }

    .dev-links-outer {
        padding: 40px 0 56px;
    }

    .dev-links {
        margin-top: 30px;
        text-align: left;
    }

    .pay-container {
        .module-sec {
            .division {
                .function {
                    padding-right: 10px;
                    padding-bottom: 10px;
                }

                p {
                    font-size: 12px;
                }
            }
        }
    }
}

.method-icon {
    width: 188px;
    height: 122px;

    &-alipay {
        background-image: url('../img/products-methods-alipay@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-wechat {
        background-image: url('../img/products-methods-wechat@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-applepay {
        background-image: url('../img/products-methods-applepay@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-unionpay {
        background-image: url('../img/products-methods-unionpay@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-cmb {
        background-image: url('../img/products-methods-cmb@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-visa {
        background-image: url('../img/products-methods-visa@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-mastercard {
        background-image: url('../img/products-methods-mastercard@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &-qq {
        background-image: url('../img/products-methods-qq@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

@keyframes loop {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(-50%)
    }
}

@keyframes looprotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.label-comingsoon {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-size: rem-calc(13);
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0.04px;
    padding: 0.2em 0.5em;
    display: inline-block;
    margin-bottom: 2em;
}

.payments-carousel {
    height: 660px;
    overflow: hidden;

    *:focus {
        outline: none;
    }

    .orbit-container {
        height: 480px;
        position: relative;
        overflow: inherit;

        @include breakpoint(small only) {
            height: 380px;
        }

        ;
    }

    .orbit-slide {
        height: 480px;
        position: relative;

        @include breakpoint(small only) {
            height: 380px;
        }

        ;
    }

    .slide-text {
        position: absolute;
        bottom: -130px;
        height: 60px;
        text-align: center;
        width: 100%;
        z-index: 9;

        p {
            margin-bottom: 7px;
            font-size: rem-calc(14);
        }

        @include breakpoint(small only) {
            bottom: -160px;
        }
    }

    .product-slide {
        height: 480px;
        overflow: hidden;

        @include breakpoint(small only) {
            height: 380px;

            img {
                max-width: 800px;
            }
        }

        ;
    }

    .orbit-next {
        right: 1em;

        &:hover,
        &:active,
        &:visited,
        &:focus {
            background: rgba(0, 0, 0, 0.1);

        }
    }

    .orbit-bullets {
        padding: 1em 0 7em;
        // background: HSL(0, 0%, 94%);

        button {
            width: auto;
            background: none;
            color: #848484;
            padding: 0 0.5em;
            border-radius: 0;

            &.is-active {
                color: $black;
            }
        }
    }
}

.shadow-mask {
    height: 150px;
    width: 100%;
    background: url('../img/products-carousel-mask.png');
    background-repeat: no-repeat;
    background-size: 648px 88px;
    background-position: center bottom;
    position: absolute;
    bottom: -2px;
    z-index: 9;
}

.products-account-preview {
    .content {
        height: 390px;
        overflow: hidden;
        position: relative;
        text-align: center;

        @include breakpoint(small only) {
            height: 180px;
        }

        ;

        img {
            margin: 0 auto;
        }
    }

    .shadow-mask {
        bottom: 0;
    }

    .description {
        padding: 1.125rem 0 5.125rem 0;

        h6 {
            font-size: rem-calc(14);
            text-align: center;
            margin-bottom: 7px;
        }

        p {
            font-size: rem-calc(13);
            text-align: center;
        }
    }
}

.products-platform-bg {
    height: 350px;
    width: 100%;
    padding-top: 20px;
    overflow: hidden;

    .sun {
        height: 672px;
        width: 672px;
        margin: 0 auto;
        background: url('../img/products-platform-sun.png');
        background-size: 672px 662px;
        background-position: center;
        background-repeat: no-repeat;
        animation: looprotate 30s linear infinite;

        @include breakpoint(retina) {
            background: url('../img/products-platform-sun@2x.png');
            background-size: 672px 662px;
            background-repeat: no-repeat;
        }

        ;
    }
}

.products-tool-card {
    text-align: center;
    margin-bottom: 5.125rem;
    // /* Mask: */
    // background: rgba(255,255,255,0.95);
    // box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.05);


    .products-tool-card-image {
        height: 150px;
        // background: #FCFEFF;
        // padding: 1em;
    }

    h6,
    p {
        text-align: center;
        font-size: rem-calc(14);
    }

    h6 {
        margin-top: 26px;
        margin-bottom: 7px;
        color: #313131;
    }

    p.more {
        font-size: 16px;
    }
}

.hero-campagin {
    width: 100%;
    padding-top: 30px;
    height: 593px;
    background-image: url("../img/bg-hero-campagin.png");
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-top: 10rem;
    padding-bottom: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        text-align: left;
    }

    h5 {
        color: #9e9e9e;
    }

    p {
        margin-top: 12px;
        color: $gray-9;
    }

    @include breakpoint(small only) {
        .home-feature__bg.campaigns {
            margin-top: 10px;
        }
    }
}

// @-moz-document url-prefix(){
//   .hero-campagin{
//     top: -110px;
//     padding-top: 180px;
//     margin-bottom: -80px;
//   }
// }

#campagin-features,
#campagin-functions {
    .section-title {
        color: #9e9e9e;
        margin-bottom: 2rem;

        @include breakpoint(small only) {
            text-align: center;
        }
    }

    p {
        margin-top: 12px;
        color: #616161;

        @include breakpoint(small only) {
            text-align: center;
        }
    }

    .column-title {
        position: relative;
        padding-bottom: 16px;
        font-size: 24px;
        color: #333;

        &:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: -4px;
            width: 100%;
            height: 1px;
            background-color: #ebebeb;
        }

        @include breakpoint(small only) {
            text-align: center;

            &:after {
                left: 50%;
                width: 40px;
                margin-left: -20px;
                background: #9e9e9e;
            }
        }
    }
}

#campagin-functions {
    border-bottom: 1px solid #eee;

    .row.align-center {
        padding-bottom: 2rem;
    }

    .column-large-padding {
        margin-bottom: 3rem;
    }
}

#campagin-features {
    .column-large-padding {
        padding: 0 2rem;

        &:first-child {
            margin-bottom: 6rem;

            @include breakpoint(small only) {
                margin-bottom: 4rem;
            }
        }

        @include breakpoint(small only) {
            margin-bottom: 4rem;
        }

    }
}

#campagin-functions {
    border-top: 1px solid #ebebeb;

    .item-icon {
        padding-bottom: 1rem;

        @include breakpoint(small only) {
            text-align: center;
            padding-bottom: 1rem;
        }
    }

    h5 {
        margin-bottom: 0.5rem;

        @include breakpoint(small only) {
            text-align: center;
        }
    }

    p {
        margin-top: 0;
    }

    .column-large-padding {
        padding: 0 2rem;

        &:first-child {
            margin-bottom: 3rem;

            @include breakpoint(small only) {
                margin-bottom: 3rem;
            }
        }

        @include breakpoint(small only) {
            margin-bottom: 3rem;
        }
    }
}

#campagin-overview {
    padding: 4rem 0;
    position: relative;
    overflow: hidden;

    .bg {
        position: absolute;
        top: 0;
        left: 50%;
        width: 605px;
        height: 100%;
        transform: translateX(-50%);
        background-image: url("../img/campagin-bg1.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
}


@media screen and (max-width: 66.5em) and (min-width: 40em) {
    .hide-for-medium-only.medium {
        display: none !important;
    }
}

@media screen and (max-width: 73.5em) and (min-width: 40em) {
    .hide-for-medium-only.large {
        display: none !important;
    }
}