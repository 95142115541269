.download {
  height: 100%;
  position: relative;

  * {
    font-size: 14px;
  }

  input, select {
    height: 34px;
    border-color: #dddddd;

    &:focus {
      border-color: #35c8e6;
    }
  }

  select {
    line-height: 1;
    color: #cacaca;
  }

  h3 {
    font-size: 27px;
    margin-bottom: 64px;
  }

  h4 {
    font-size: 24px;
    margin: 27px 0 10px 0;
    font-weight: 400;
  }

  .relative {
    position: relative;
  }

  .error-message {
    display: none;
    line-height: 34px;
    padding-left: 8px;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .is-invalid-input:not(:focus) {
    border-color: #dddddd;
    background-color: white;
  }

  .is-invalid-input + .error-message {
    color: #ff5a5f;
    display: block;
  }

  .wrap {
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  .tips {
    font-size: 12px;
  }

  #sms {
    border-radius: 2px 0 0 2px;
  }

  #sendSms {
    font-size: 12px;
    padding: 0 10px;
    border-radius: 0 2px 2px 0;
    background-color: white;
    color: #a0a0a0;
    border: 1px solid #dddddd;
    margin-left: -1px;
    line-height: 32px;
    font-weight: 400;

    &.disabled {
      color: #c1c8cb;
      border-color: #eee;
      opacity: 1;
      background-color: white;

      &:hover {
        background-color: white;
      }

      &:active {
        background-color: white;
      }
    }

    &:hover {
      background-color: #f6f6f6;
    }

    &:active {
      background-color: #dddddd;
    }
  }

  #nextStep1, #submit {
    margin: 27px 0 19px;
    border-radius: 17px;
  }

  .download-cancel {
    color: #CCCCCC;
  }

  .hid {
    display: none;
  }

  .down-success {
    height: 44px;
  }

  .info {
    color: #202020;
    opacity: .7;
    margin-bottom: 43px;
  }

  .back-post::after {
    content: '\e906';
    font-family: 'icomoon';
    margin-left: 5px;
    transform: rotate(-90deg);
    display: inline-block;

  }

  .button-error {
    background-color: #ff5a5f;
  }
}