.mediaList {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .column {
    padding: 2rem;
  }

  a {
    display: block;
    height: 50px;
    text-indent: -9999px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
  .people {
    background-image: url("../img/press/logo-people.png");
  }
  .huanqiu {
    background-image: url("../img/press/logo-huanqiu.png");
  }
  .souhu {
    background-image: url("../img/press/logo-souhu.jpg");
  }
  .yiou {
    background-image: url("../img/press/logo-yiou.jpg");
  }
  .ceweekly {
    background-image: url("../img/press/logo-ceweekly.png");
  }
  .pintu {
    background-image: url("../img/press/logo-pintu.jpg");
  }

  .tencentech {
    background-image: url("../img/press/logo-tencent.png");
    background-size: 80px auto;
  }
  .techweb {
    background-image: url("../img/press/logo-techweb.png");
    background-size: 80px auto;
  }
  .touzi {
    background-image: url("../img/press/logo-touzi.png");
    background-size: 80px auto;
  }
  .heima {
    background-image: url("../img/press/logo-heima.png");
    background-size: 80px auto;
  }
  .kr36 {
    background-image: url("../img/press/logo-kr.png");
  }
  .csdn {
    background-image: url("../img/press/logo-csdn.png");
  }
  .nsb {
    background-image: url("../img/press/logo-nsb.png");
  }
  .pingwest {
    background-image: url("../img/press/logo-pw.png");
  }
  .technode {
    background-image: url("../img/press/logo-tn.png");
  }
  .donews {
    background-image: url("../img/press/logo-donews.jpg");
  }
  .geekpark {
    background-image: url("../img/press/logo-geekpark.png");
  }
  .forbes {
    background-image: url("../img/press/forbes.png");
  }
  .fenghuang {
    background-image: url("../img/press/logo-fenghuang.png");
    background-size: 80px auto;
  }
  .yiou {
    background-image: url("../img/press/logo-yiou.jpg");
    background-size: 80px auto;
  }
  .qianzhan {
    background-image: url("../img/press/logo-qianzhan.png");
    background-size: 80px auto;
  }
  .chuangye {
    background-image: url("../img/press/logo-chuangye.png");
    background-size: 80px auto;
  }
  .caijing {
    background-image: url("../img/press/logo-caijing.png");
    background-size: 80px auto;
  }
  .zhongshang {
    background-image: url("../img/press/logo-zhongshang.png");
    background-size: 80px auto;
  }
  .zhifu {
    background-image: url("../img/press/logo-zhifu.png");
    background-size: 80px auto;
  }
  .jiemian {
    background-image: url("../img/press/logo-jiemian.png");
    background-size: 80px auto;
  }
  .toutiao {
    background-image: url("../img/press/logo-toutiao.png");
    background-size: 80px auto;
  }
  .techdaily {
    background-image: url("../img/press/techdaily.png");
  }
  .chuangjian {
    background-image: url("../img/press/chuangjian.png");
  }
  .netease {
    background-image: url("../img/press/netease.png");
  }
  .ebrun {
    background-image: url('../img/press/logo-ebrun.jpg');
    background-size: 80px auto;
  }
  .lieyun {
    background-image: url('../img/press/logo-lieyun.jpg');
    background-size: 80px auto;
  }
  .tuobe {
    background-image: url("../img/press/logo-2b.png");
    background-size: 100px auto;
  }
  @media (-moz-min-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    .kr36 {
      background-image: url("../img/press/logo-kr@2x.png");
      background-size: 55px 31px;
    }
    .csdn {
      background-image: url("../img/press/logo-csdn@2x.png");
      background-size: 100px 23px;
    }
    .pintu {
      background-image: url("../img/press/logo-pintu@2x.png");
      background-size: 100px 18px;
    }
    .nsb {
      background-image: url("../img/press/logo-nsb@2x.png");
      background-size: 96px 21px;
    }
    .pingwest {
      background-image: url("../img/press/logo-pw@2x.png");
      background-size: 100px 18px;
    }
    .technode {
      background-image: url("../img/press/logo-tn@2x.png");
      background-size: 100px 16px;
    }
    .donews {
      background-image: url("../img/press/logo-donews@2x.png");
      background-size: 100px 19px;
    }
    .geekpark {
      background-image: url("../img/press/logo-geekpark@2x.png");
      background-size: 100px 27px;
    }
    .forbes {
      background-image: url("../img/press/forbes@2x.png");
      background-size: 100px 26px;
    }
    .techdaily {
      background-image: url("../img/press/techdaily@2x.png");
      background-size: 100px 19px;
    }
    .chuangjian {
      background-image: url("../img/press/chuangjian@2x.png");
      background-size: 99px 25px;
    }
    .netease {
      background-image: url("../img/press/netease@2x.png");
      background-size: 100px 24px;
    }
    .tuobe {
      background-image: url("../img/press/logo-2b@2x.png");
      background-size: 100px 28px;
    }
  }
}

.articles-container {
  border-top: 1px solid rgba(0, 0, 0, .05);
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  padding: 1rem 0;

  .column {
    padding: 3rem 2rem;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    h4 {
      font-size: rem-calc(20);
      margin-bottom: 1.5rem;
    }
    .content {
      font-weight: 300;
      margin-bottom: 1.5rem;
    }
    .reporter {
      color: #A6AEB3;
    }
  }
}

.downloads-container {
  padding: 3rem 0 5rem;
  h4 {
    text-align: center;
    margin-bottom: 1.41rem;
  }
  a {
    width: 100%;
    height: 80px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid rgba(0, 0, 0, 0.1);

    span {
      width: 100%;
      height: 100%;
      line-height: 80px;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.2s ease;
      display: inline-block;
      text-align: center;
      color: white;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }
  .white_m {
    background-image: url("../img/logo/white_logo.png");
  }
  .white_s {
    background-image: url("../img/logo/white_logo_icon.png");
  }
  .black_m {
    background-color: black;
    background-image: url("../img/logo/black_logo.png");
  }
  .black_s {
    background-color: black;
    background-image: url("../img/logo/black_icon_logo.png");
  }
}
