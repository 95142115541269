// theme-invert
.theme-invert .hero,
.hero.invert {
	// background-image: $gradient-black;
	color: $white;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
		text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
	}

	p,
	label {
		text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
		color: hsla(130, 50%, 100%, 0.8);
	}
}

.theme-invert--image .hero {
	color: $white;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
		text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
	}

	p {
		text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
		color: hsla(130, 50%, 100%, 0.8);
	}
}

.theme-invert.theme-invert--pattern .hero {
	background: url('../img/invert-bg.png') top left/991px 605px no-repeat, linear-gradient(-180deg, #0f0f0f 0%, #0a0a0a 100%);
}

.theme-products {
	overflow-x: hidden;
	.top-bar {
		ul li a {
			color: rgba(255, 255, 255, 0.7);
			&:hover {
				color: rgba(255, 255, 255, 1);
			}
		}
		.submenu a {
			color: rgba(0, 0, 0, 0.6);
			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
	.button.cta.hollow {
		color: rgba(255, 255, 255, 0.7);
		// border: 1px solid rgba(255, 255, 255, 0.7);
		&:hover {
			color: rgba(0, 0, 0, 0.7);
			border: 1px solid rgba(255, 255, 255, 0.7);
			background: #fff;
			box-shadow: 0px 10px 10px hsla(0, 0%, 100%, 0.2);
		}
	}
	.hero {
		background-color: HSL(225, 17%, 5%);
		background-image: url('../img/bg-products.jpg');
		background-size: cover;
		background-position: center;
	}
	.hero-copy {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
		}
	}
}

.theme-solution {
	overflow-x: hidden;
	.top-bar {
		ul li a {
			color: rgba(255, 255, 255, 0.7);
			&:hover {
				color: rgba(255, 255, 255, 1);
			}
		}
		.submenu a {
			color: rgba(0, 0, 0, 0.6);
			&:hover {
				color: #14b1d1;
			}
		}
	}
	.button.cta.hollow {
		color: rgba(255, 255, 255, 0.7);
		// border: 1px solid rgba(255, 255, 255, 0.7);
		&:hover {
			color: rgba(0, 0, 0, 0.7);
			border: 1px solid rgba(255, 255, 255, 0.7);
			background: #fff;
			box-shadow: 0px 10px 10px hsla(0, 0%, 100%, 0.2);
		}
	}
	.hero {
		background-image: url('../img/solution-bg.png');
		background-size: cover;
		background-position: center top;
	}
	.hero-copy {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
		}
	}
}

// 招商页面
.theme-solicit {
	overflow-x: hidden;
	background-color: #f3faf7;
	.top-bar {
		a {
			cursor: pointer;
		}

		ul li a {
			color: #666666;
			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}
		.submenu a {
			color: rgba(0, 0, 0, 0.6);
			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
}

.theme-new-products {
	overflow-x: hidden;
	.top-bar {
		ul li a {
			color: rgba(255, 255, 255, 0.7);
			&:hover {
				color: rgba(255, 255, 255, 1);
			}
		}
		.submenu a {
			color: rgba(0, 0, 0, 0.6);
			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
	.button.cta.hollow {
		color: rgba(255, 255, 255, 0.7);
		// border: 1px solid rgba(255, 255, 255, 0.7);
		&:hover {
			color: rgba(0, 0, 0, 0.7);
			border: 1px solid rgba(255, 255, 255, 0.7);
			background: #fff;
			box-shadow: 0px 10px 10px hsla(0, 0%, 100%, 0.2);
		}
	}
	.hero {
		background-image: url('../img/solution/fund/solution-fund-banner.jpg');
		background-size: cover;
		background-position: center;
		height: 480px;
	}
	.hero-copy {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}
	}
}

.theme-customers {
	background-color: #edeef1;
}
.theme-in footer.invert {
	background: $footer-invert-background-color;
	color: $footer-invert-body-color;

	ul.menu li a {
		color: $footer-invert-link-color;
		&:hover {
			color: lighten($footer-invert-link-color, 30);
		}
	}

	.footer__language-switcher {
		.lang {
			border: 1px solid $footer-invert-link-color;
			background: $footer-invert-background-color;
			color: $footer-invert-link-color;
		}

		&:hover .lang.han {
			border-color: $footer-invert-link-color;
			color: $footer-invert-background-color;
			background: $footer-invert-link-color;
		}
	}
}

.theme-invert .top-bar,
.top-bar.invert {
	ul li a {
		color: rgba(255, 255, 255, 0.7);
		&:hover {
			color: rgba(255, 255, 255, 1);
		}
	}
	ul li .submenu {
		// background: rgba(10,10,10,0.98);
		// border-radius: $global-radius;
		// border: 1px solid rgba(0,0,0,0.9);
		// box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
		li a {
			color: rgba(0, 0, 0, 0.6);
			&:hover {
				color: #1198b4;
			}
		}
	}
}

.theme-invert {
	.button.cta.hollow {
		color: rgba(255, 255, 255, 0.7);
		// border: 1px solid rgba(255, 255, 255, 0.7);
		&:hover {
			color: rgba(0, 0, 0, 0.7);
			border: 1px solid rgba(255, 255, 255, 0.7);
			background: #fff;
			box-shadow: 0px 10px 10px hsla(0, 0%, 100%, 0.2);
		}
	}
}

.theme-invert .title-bar,
.theme-cmbwallet .title-bar {
	color: white;
}

.theme-invert .title-bar__toggle span,
.theme-cmbwallet .title-bar__toggle span,
.theme-products .title-bar__toggle span,
.theme-solution .title-bar__toggle span {
	background: white;
}

.theme-invert,
.theme-cmbwallet,
.theme-products,
.theme-solution,
.theme-new-products {
	.top-bar__logo {
		background: url('../img/newhome/logo-white.png') left center no-repeat;
		background-size: 90%;
	}
}

.top-bar__logo.invert {
	background: url('../img/newhome/logo-white.png') left center no-repeat;
	background-size: 90%;
}

.theme-orange-blue {
	.hero {
		background-image: $gradient-orange-blue;
	}
}

.theme-light-gray {
	.hero {
		background-image: $gradient-light-gray;
	}
}

.theme-medium-white {
	background-color: #ffffff;
	.hero {
		background: #ffffff;
	}
}

.theme-pink-blue {
	.hero {
		background-image: $gradient-pink-blue;
	}
}

.theme-nonprofit {
	background: #f3fffe;
	h1,
	h2,
	h3 {
		color: #29614e;
	}
	h4,
	h6,
	p,
	span {
		color: #48b691;
	}
	h5 {
		color: #9e9e9e;
	}
	.hero {
		background-image: linear-gradient(0deg, #f3fffe 0%, #e1fefc 100%);
	}
	.ld-content-container section > .row {
		border-color: #c8ede3;
	}
	footer {
		background: #f3fffe;
		.beian {
			background: #f3fffe !important;
		}
		.beian a {
			color: #48b691;
		}
	}
	.button {
		background: #48b691;
	}
	.ld-content dl {
		dt,
		dd {
			color: #48b691;
		}
	}
	.ld-content__callout {
		h1,
		h2,
		h3 {
			color: #48b691;
		}
	}
	.ld-content__hero {
		h6 {
			color: #29614e;
		}
	}
	.ld-ingridcta .inner-row {
		p {
			margin-bottom: 1rem;
		}
		border: 1px solid rgba(72, 182, 145, 0.25);
		&.noborder {
			border: 0;
		}
	}
}

.theme-cmbwallet {
	.top-bar {
		ul li a {
			color: rgba(255, 255, 255, 0.7);
			&:hover {
				color: rgba(255, 255, 255, 1);
			}
		}
		.submenu a {
			color: rgba(0, 0, 0, 0.6);
			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
	.button.cta.hollow {
		color: rgba(255, 255, 255, 0.7);
		// border: 1px solid rgba(255, 255, 255, 0.7);
		&:hover {
			color: rgba(0, 0, 0, 0.7);
			border: 1px solid rgba(255, 255, 255, 0.7);
			background: #fff;
			box-shadow: 0px 10px 10px hsla(0, 0%, 100%, 0.2);
		}
	}

	.ld-feature-grid__icon {
		height: 9.5rem;
	}

	.ld-feature-grid__column {
		@include breakpoint(small only) {
			padding: 2rem 0 0rem;
		}
	}

	.hero--large-padding {
		padding: 12em 0em 14em;
		@include breakpoint(small only) {
			padding: 9em 0em 10em;
		}
	}

	h2 {
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}
	}
}

.theme-pink-black {
	@extend .theme-invert;
	.hero {
		background-image: $gradient-pink-black;
	}
}

.theme-orange-purple {
	@extend .theme-invert;
	.hero {
		background-image: $gradient-orange-purple;
	}
}

.theme-career {
	@extend .theme-invert;

	&,
	.hero {
		background: rgb(44, 44, 44);
	}

	.nav.block-grid {
		background: rgb(44, 44, 44);
	}

	color: #9e9e9e;

	.ld-content__hero h5 {
		color: #9e9e9e;
	}

	.ld-faq .accordion {
		.accordion-item a {
			color: $white;
		}
		.accordion-title:hover {
			background: #212121;
		}
	}

	.accordion {
		background: #2c2c2c;

		.accordion-title,
		.accordion-content {
			border-bottom-color: #212121;
		}
		.accordion-content {
			background-color: #2c2c2c;
		}
	}

	.ld-career-list span.location {
		color: #9e9e9e;
	}

	.nav.block-grid {
		ul li {
			border-right-color: #2c2c2c;
			border-bottom-color: #212121;
			& > a {
				&:hover {
					background: rgba(33, 33, 33, 0.3);
				}
				h5 {
					color: $white;
				}
				span.description {
					color: #9e9e9e;
				}
			}
		}
	}

	h2,
	h3 {
		color: $white;
	}
}

.theme-new-solution {
	.top-bar-wrapper {
		background: #fff;
		padding: 0;
	}
}
@media screen and (max-width: 39.9375em) {
	.theme-new-solution {
		.top-bar-wrapper {
			background: transparent;
		}
		.top-bar__logo {
			background: url('../img/newhome/logo-white.png') left center no-repeat;
			background-size: 90%;
			opacity: 0.8;
		}
		.title-bar__toggle span {
			background: rgba(255, 255, 255, 0.8);
		}
	}

	.theme-new-products {
		.top-bar__logo {
			background: url('../img/newhome/logo-white.png') left center no-repeat;
			background-size: 90%;
			opacity: 0.8;
		}
		.title-bar__toggle span {
			background: rgba(255, 255, 255, 0.8);
		}
	}
	.theme-nonprofit {
		footer .beian a {
			color: #606060;
			span {
				color: #202020;
			}
		}
	}
}
