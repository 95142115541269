ul.device-wall.device-wall--en{
  .device.web{
    background-image: url('../img/web-chrome-en.png');
    @include breakpoint(retina) {
      background-image: url('../img/web-chrome-en@2x.png');
    }
  }
  .device.apple-pay{
    background-image: url('../img/phone-apple-pay-en.png');
    @include breakpoint(retina) {
      background-image: url('../img/phone-apple-pay-en@2x.png');
    }
  }
  .device.wechat{
    background-image: url('../img/phone-hongbao-en.png');
    @include breakpoint(retina) {
      background-image: url('../img/phone-hongbao-en@2x.png');
    }
  }
}
