.countdown{
  @extend .fullscreen;
  text-align: center;
  background-image: linear-gradient(-180deg, #FFFFFF 0%, #F5F5F5 100%);
  overflow: hidden;
}
.countdown-timer{
  padding-top: 3em;
}
.countdown-timer__digit{
  font-size: rem-calc(96);
  margin-bottom: 0;
  @include breakpoint(small down) {
    font-size: rem-calc(48);
  }
}
.countdown-timer__unit{
  font-weight: 400;
}
.countdown-row{
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.countdown-mask{
  z-index: 4;
  position: absolute;
  top:0;
  left: 0;
  height: 40%;
  width: 100%;
  background-image: linear-gradient(-180deg, #FFFFFF 0%, rgba(255,255,255,0.00) 95%);
}
.countdown-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  perspective: 500px;
}
.countdown-bg__piece{
  height: rem-calc(120);
  width: rem-calc(120);
  background: $gray-3;
  position: absolute;
  top: -8rem;
}
.countdown-logo{
  @extend .top-bar__logo;
  margin: 0 auto;
}
.countdown-copy{
  padding: 2em 0;
}
