.pro-consult {
    position: fixed;
    bottom: rem-calc(250);
    right: rem-calc(10);
    z-index: 97;
    display: flex;
    flex-direction: column;
    align-items: center;

    .service-btn,
    .message-btn {
        width: rem-calc(64);
        background: #21b2bb;
        box-shadow: 0px 2px 10px 0px #8cb9bd;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        .img-box {
            width: 100%;
            height: rem-calc(46);
            background: #18abb5;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: rem-calc(22);
                width: rem-calc(24);
            }
        }
        span {
            font-size: rem-calc(18);
            font-weight: 600;
            color: #ffffff;
            display: inline-block;
            padding: rem-calc(10) 0;
            writing-mode: vertical-lr;
        }
    }
    .message-btn {
        margin-top: rem-calc(10);
        .img-box {
            img {
                height: rem-calc(21);
                width: rem-calc(22);
            }
        }
    }
    .service-btn:hover > .service-qr-div {
        display: block;
    }
}
.service-qr-div {
    display: none;
    position: fixed;
    bottom: rem-calc(202);
    right: rem-calc(70);
    z-index: 97;
    width: rem-calc(239);
    height: rem-calc(239);
    img {
        width: rem-calc(239);
        height: rem-calc(239);
        position: absolute;
    }
}
.pro-slide {
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    position: fixed;
    top: rem-calc(200);
    bottom: 0;
    right: rem-calc(-374);
    width: rem-calc(354);

    background-color: #fff;
    z-index: 1001;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    box-shadow: 0px 0px 20px 0px #daf1f2;
    border-radius: 8px;

    &.down {
        animation: fade-in-left 0.5s;
    }
    &.on {
        right: rem-calc(83);
        animation: fade-in-right 0.5s;
    }
}

.pro-header {
    font-size: 13px;
    font-weight: 500;
    color: #252525;
    line-height: 45px;
    padding: 0 20px;
    height: 45px;
    .pro-close {
        position: absolute;
        border: 0;
        right: rem-calc(20);
        top: 0;
        width: rem-calc(20);
        height: rem-calc(20);
        border-radius: 0;
        text-align: center;
        background-color: #fff;
        &:after {
            font-family: 'icomoon' !important;
            content: '\e117';
            color: #707070;
            font-size: rem-calc(20);
            line-height: rem-calc(50);
            font-weight: 200;
        }
    }
}
.pro-con {
    position: relative;
    padding: 0 rem-calc(50) rem-calc(20);
    overflow-y: scroll;
    .pro-title {
        font-size: 13px;
        font-weight: 400;
        color: #a1a1a1;
        line-height: 50px;
        height: 50px;
        text-align: center;
        margin-bottom: 0;
    }
    .submit-btn {
        width: 100%;
        height: 32px;
        background: #21b2bb;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        line-height: 32px;
        text-align: center;
    }
}
.pro-success-box {
    position: relative;
    padding: rem-calc(40) rem-calc(50) rem-calc(20);
    height: rem-calc(160);
    border-top: 1px solid rgba(46, 53, 148, 0.13);

    // .pos-middle {
    //     position: absolute;
    //     top: 30%;
    //     left: 50%;
    //     transform: translate3d(-50%, -50%, 0);
    // }
    .pro-ico {
        position: relative;
        display: block;
        // margin-bottom: 55px;
        height: rem-calc(60);
        .pro-icon-success {
            height: rem-calc(60);
            width: 100%;
            &.pro-draw:after {
                animation: success 0.5s ease;
                animation-fill-mode: forwards;
                transform: scaleX(1) rotate(180deg + -45deg);
            }
            &:after {
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: rem-calc(-20);
                opacity: 1;
                height: 60px;
                width: 40px;
                border-right: 8px solid #21b2bb;
                border-top: 8px solid #21b2bb;
                border-radius: 2px;
                content: '';
            }
        }
    }
    .pro-txt {
        font-size: rem-calc(14);
    }
    @-webkit-keyframes success {
        0% {
            height: 0;
            width: 0;
            opacity: 1;
        }
        20% {
            height: 0;
            width: 40px;
            opacity: 1;
        }
        40% {
            height: 60px;
            width: 40px;
            opacity: 1;
        }
        100% {
            height: 60px;
            width: 40px;
            opacity: 1;
        }
    }
    @keyframes success {
        0% {
            height: 0;
            width: 0;
            opacity: 0;
        }
        20% {
            height: 30px;
            width: 0;
            opacity: 1;
        }
        40% {
            height: 30px;
            width: 40px;
            opacity: 1;
        }
        100% {
            height: 30px;
            width: 50px;
            opacity: 1;
        }
    }
}

.pro-tip {
    position: relative;
    height: rem-calc(58);
    line-height: rem-calc(58);
    font-size: 13px;
    font-weight: 400;
    color: #a1a1a1;
    text-align: center;
    margin: 0;
    strong {
        position: relative;
        color: #202020;
        font-weight: 600;
        cursor: pointer;
        &:hover + img {
            display: block;
        }
    }
    img {
        position: absolute;
        left: 50%;
        top: rem-calc(-110);
        display: none;
        width: rem-calc(118);
        height: rem-calc(123);
        margin-left: rem-calc(-85);
    }
}

select.sel {
    background-color: #fbfbfb;
    border-radius: 3px;
    outline: 0;
}

@media screen and (max-width: 39.9375em) {
    .pro-success-box {
        padding: rem-calc(20 50 20);
    }
    .pro-consult {
        // bottom: 0;
        // right: 0;
        // left: 0;
        // padding: rem-calc(8) 0;
        // background-color: #fff;
        // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
        // text-align: center;
        bottom: rem-calc(40);
        .pro-qrcode {
            height: 0 !important;
            .pro-qrcode-img {
                opacity: 0;
            }
        }
        .message-btn {
            opacity: 0.9;
            .img-box {
                height: rem-calc(40) !important;
                border-radius: 2px;
            }
        }
    }
    footer {
        padding-top: 0;
    }
    .pro-con {
        padding: rem-calc(10 28 28);
        .pro-title {
            line-height: 20px;
            height: 20px;
        }
    }
    .pro-slide {
        box-shadow: none;
        right: -100%;
        bottom: 0;
        top: unset;
        width: 100%;
        h3 {
            text-align: center;
            font-size: rem-calc(23);
        }
        .button {
            display: block;
            width: 100%;
            margin-top: 1.2rem;
        }
        &.on {
            right: 0;
        }
    }
    .pro-tip {
        display: none;
    }
    .pro-describe {
        display: none !important;
    }
}

@-webkit-keyframes fade-in-right {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
    }
}
@keyframes fade-in-right {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
@-webkit-keyframes fade-in-left {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-100%, 0, 0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
    }
}
@keyframes fade-in-left {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@-webkit-keyframes fade-in-show {
    0% {
        top: 105px;
        opacity: 0;
    }
    64% {
        top: 22px;
        opacity: 0;
    }
    100% {
        top: 16px;
        opacity: 1;
    }
}
@keyframes fade-in-show {
    0% {
        top: 105px;
        opacity: 0;
    }
    64% {
        top: 22px;
        opacity: 0;
    }
    100% {
        top: 16px;
        opacity: 1;
    }
}
@-webkit-keyframes fade-in-hide {
    0% {
        top: 16px;
        opacity: 1;
    }
    36% {
        top: 38px;
        opacity: 0;
    }
    100% {
        top: 105px;
        opacity: 0;
    }
}
@keyframes fade-in-hide {
    0% {
        top: 16px;
        opacity: 1;
    }
    36% {
        top: 38px;
        opacity: 0;
    }
    100% {
        top: 105px;
        opacity: 0;
    }
}
