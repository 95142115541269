ul {
    margin: 0;
}
li {
    list-style: none;
}

.driver-line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
}

// 使用此方法的时候， 切记 safari 9 以及低版本的其他webkit 内核中，flex 的子级元素是没有高度的，会导致失效。
.vertically-middle__by_position {
    position: relative;

    > div {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
    }
}

.gray-desc {
    opacity: 0.7;
}

.solution-contact {
    background: linear-gradient(127deg, #1444ad 0%, #0e82b7 100%);
    padding: rem-calc(75) 0;

    .solution-contact-title {
        font-size: rem-calc(40);
        font-weight: 600;
        color: #fff;
        line-height: rem-calc(36);
        letter-spacing: 1px;
        margin-bottom: rem-calc(40);
    }

    .solution-contact-text {
        font-size: rem-calc(22);
        color: #fff;
        line-height: rem-calc(36);
        margin-bottom: rem-calc(15);
    }

    .contact-us {
        padding: rem-calc(12) rem-calc(48);
        font-size: rem-calc(18);
        font-weight: 500;
        color: #ffffff;
        line-height: rem-calc(24);
        border-radius: 5px;
        border: 1px solid #fff;
        background-color: transparent;
    }
}

@media screen and (max-width: 39.9375em) {
    .solution-contact {
        .solution-contact-title {
            font-size: rem-calc(20);
        }

        .solution-contact-text {
            font-size: rem-calc(16);
        }

        .contact-us {
            font-size: rem-calc(13);
        }
    }
}

.zSz_x {
    display: none;
    top: unset !important;
    bottom: 0rem !important;
    right: 0.625rem !important;
    box-shadow: 0px 2px 10px 0px #8cb9bd !important;
    width: 4rem;
    height: unset !important;
    padding: unset !important;
    border-radius: unset !important;
    .cQVmq {
        width: 100% !important;
        height: 46px !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-color: #18abb5 !important;
        margin-bottom: unset !important;
    }
    span > span {
        font-size: 1.125rem;
        font-weight: 600;
        writing-mode: vertical-lr;
        padding: 0.625rem 0;
        letter-spacing: 5px;
    }
}
._1ILJY {
    display: none;
}
