.micro-finance {
    background: #2f2f3d !important;
    padding-top: rem-calc(45);
    margin-top: rem-calc(55);
    height: rem-calc(318);
    position: relative;
    z-index: -1;
    &::before {
        content: '';
        width: 50%;
        height: rem-calc(318);
        position: absolute;
        background: linear-gradient(to left bottom, transparent 50%, rgba(255, 183, 78, 0.03) 50%, rgba(255, 183, 78, 0.03) 100%);
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    &::after {
        content: '';
        width: 50%;
        height: rem-calc(318);
        position: absolute;
        background: linear-gradient(to right bottom, transparent 50%, rgba(255, 183, 78, 0.03) 50%, rgba(255, 183, 78, 0.03) 100%);
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    .header-section {
        .header-section-item {
            display: inline-flex;

            &:first-of-type {
                justify-content: flex-start;
            }

            &:last-of-type {
                justify-content: flex-end;
            }
            .micro-finance-banner-img {
                height: rem-calc(228);
                width: auto;
            }
        }

        .header-section-title {
            display: inline-flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            .title {
                color: #cba87b;
                font-weight: 600;
                line-height: rem-calc(48);
                font-size: rem-calc(34);
                text-align: left;
            }

            .text {
                color: #dfc19e;
                line-height: rem-calc(36);
                font-size: rem-calc(20);
                margin-bottom: 0;
                text-align: left;
            }
        }
    }
}

.micro-finance-container {
    .micro-finance-item {
        margin-top: rem-calc(80);

        > .title {
            font-size: rem-calc(28);
            font-weight: 600;
            color: #202020;
            line-height: rem-calc(40);
            height: rem-calc(40);
            margin-bottom: rem-calc(35);
            position: relative;
            padding-left: rem-calc(18);

            &::before {
                width: rem-calc(8);
                height: rem-calc(26);
                background: #21b2bb;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: rem-calc(-13);
                content: '';
            }
        }

        &:first-of-type {
            > .title {
                margin-bottom: rem-calc(35);
            }
        }

        .micro-finance-content {
            // justify-content: space-between !important;
            :last-of-type {
                .service-container {
                    margin-bottom: 0 !important;
                }
            }
        }

        &:first-of-type(.micro-finance-content) {
            padding-top: rem-calc(15);
        }

        .service-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: rem-calc(20);

            .service-img {
                width: rem-calc(54);
                height: rem-calc(54);
                margin-bottom: rem-calc(15);
            }

            .title {
                font-size: rem-calc(24);
                font-weight: 600;
                color: #000000;
                line-height: rem-calc(33);
                margin-bottom: rem-calc(10);
            }

            .text {
                font-size: rem-calc(16);
                font-weight: 400;
                color: #252525;
                line-height: rem-calc(22);
                margin: 0;
            }
        }

        .advantage-img {
            width: 100%;
            height: auto;
        }

        .advantage-container {
            display: flex;
            flex-direction: column;
        }

        .advantage-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .img-box {
                align-items: center;
            }
        }
    }

    .pain-container-box {
        .pain-container-item {
            height: auto;
            // background: #fbfcfd;
            border-radius: rem-calc(16);
            // display: flex;
            // flex-direction: row;
            align-items: center;
            padding: 0;
            position: relative;
            margin-bottom: 0;
            // padding-left: rem-calc(10);

            &:first-of-type {
                .pain-container-content {
                    margin: rem-calc(20 40 0 10);
                }
            }

            &:last-of-type {
                .pain-container-content {
                    margin: rem-calc(20 10 0 40);
                }
            }
            .pain-container-content {
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                padding: rem-calc(20) rem-calc(20) rem-calc(30) rem-calc(50);
                height: rem-calc(162);
                position: relative;
                // width: 100%;
                &::before {
                    content: ' ';
                    width: 100%;
                    background: linear-gradient(to right bottom, #fbf6ef 0%, #fbf6ef 50%, #fbf2e8 50%, #fbf2e8 100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    bottom: 0;
                }

                .index-icon {
                    font-size: rem-calc(32);
                    font-weight: bold;
                    color: #ffffff;
                    line-height: rem-calc(38);
                    position: absolute;
                    top: rem-calc(-20);
                    left: rem-calc(-10);
                    width: rem-calc(48);
                    height: rem-calc(48);
                    text-align: center;
                    padding: rem-calc(5);
                    background: linear-gradient(135deg, #d6b185 0%, #d6b185 50%, #a27a39 50%, #a27a39 100%);
                    opacity: 0.6123;
                    margin-bottom: rem-calc(20);
                }

                .title {
                    font-size: rem-calc(24);
                    font-weight: 600;
                    color: #252525;
                    line-height: rem-calc(33);
                    margin-bottom: rem-calc(10);
                    margin-top: rem-calc(20);
                }

                .text {
                    font-size: rem-calc(15);
                    font-weight: 400;
                    color: #252525;
                    line-height: rem-calc(24);
                    margin-bottom: 0;
                }
            }
        }
    }
}

.advantage-container {
    .advantage-item {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: rem-calc(70);

        &:last-child {
            margin-bottom: 0;
        }

        .right-icon {
            width: rem-calc(24);
            height: rem-calc(24);
            margin-right: rem-calc(20);
            margin-top: rem-calc(5);
        }

        .advantage-box {
            .title {
                font-size: rem-calc(24);
                font-weight: 600;
                color: #000000;
                line-height: rem-calc(33);
                margin-bottom: rem-calc(10);
            }

            .text {
                font-size: rem-calc(16);
                font-weight: 400;
                color: #252525;
                line-height: rem-calc(22);
                margin-bottom: 0;
            }
        }
    }
}

.mico-finance-contact-us {
    margin-top: rem-calc(80);

    .bottom-contact-us {
        height: rem-calc(320);
        padding: 0;
        background-image: linear-gradient(129deg, #373540 0%, #403c44 100%);

        .contact-info {
            display: flex;
            .contact-img {
                // width: auto;
                // height: auto;
                width: auto;
                height: rem-calc(311);
                margin-left: rem-calc(-158);
                padding-top: rem-calc(30);
            }
        }

        .contact-content {
            color: #cba87b;

            .contact-slogan {
                font-size: rem-calc(34);
                line-height: rem-calc(40);
                font-weight: 500;
                margin-bottom: rem-calc(10);
                letter-spacing: 1px;
            }

            .contact-text {
                font-size: rem-calc(20);
                line-height: rem-calc(36);
                margin-bottom: rem-calc(30);
                letter-spacing: 1px;
            }

            .contact-btn {
                display: inline-block;
                width: rem-calc(138);
                height: rem-calc(40);
                border-radius: 4px;
                border: 1px solid #cba87b;
                color: #cba87b;
                font-size: rem-calc(16);
                font-weight: 600;
                text-align: center;
                line-height: rem-calc(40);
            }
        }
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .key-point {
        .module-title {
            margin-bottom: 54px !important;
        }
    }

    .sub-title {
        text-align: center;
    }

    .sub-desc {
        width: 270px !important;
    }

    .service-container-box {
        .service-container-item {
            justify-content: center;
        }
    }

    .header-section-item {
        justify-content: center !important;

        &:first-of-type {
            margin-bottom: 0;
        }
    }

    .micro-finance {
        padding: 0;
        padding-top: rem-calc(55);
        margin-top: 0;
        height: rem-calc(393);
        &::before,
        &::after {
            content: none;
        }
        .header-section {
            .header-section-item {
                &:first-child {
                    margin-bottom: rem-calc(20);
                }
                &:last-child {
                    margin-top: rem-calc(30);
                }
                .micro-finance-banner-img {
                    // width: 100%;
                    height: rem-calc(147);
                    width: auto;
                }
            }

            .header-section-title {
                .title {
                    font-size: rem-calc(24);
                    line-height: rem-calc(45);
                    text-align: center;
                    margin-bottom: rem-calc(10);
                }

                .text {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                    text-align: center;
                }
            }
        }
    }

    .micro-finance-container {
        .micro-finance-item {
            margin-top: rem-calc(40);

            > .title {
                font-size: rem-calc(18);
                height: rem-calc(18);
                line-height: rem-calc(18);
                margin-bottom: rem-calc(20);
                padding-left: rem-calc(12);

                &::before {
                    width: rem-calc(4);
                    height: rem-calc(16);
                    margin-top: rem-calc(-8) !important;
                }
            }

            &:first-of-type {
                > .title {
                    margin-bottom: rem-calc(40);
                }
            }
            .advantage-title {
                margin-bottom: rem-calc(20) !important;
            }

            .service-container {
                justify-content: center !important;
                align-items: center !important;
                margin-bottom: rem-calc(40);

                .service-img {
                    width: rem-calc(35);
                    height: rem-calc(35);
                }

                .title {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                }

                .text {
                    font-size: rem-calc(12);
                    line-height: rem-calc(17);
                }
            }
        }

        .pain-container-box {
            .pain-container-item {
                justify-content: center !important;

                &:first-of-type {
                    .pain-container-content {
                        margin: 0;
                    }
                }

                &:last-of-type {
                    .pain-container-content {
                        margin: rem-calc(40 0 0 0);
                    }
                }
                .pain-container-content {
                    height: rem-calc(142);
                    padding: rem-calc(20 10 30 40);
                    .index-icon {
                        width: rem-calc(36);
                        height: rem-calc(36);
                        left: 0rem;
                        top: rem-calc(-19);
                        font-size: rem-calc(24);
                        line-height: rem-calc(29);
                    }

                    .title {
                        font-size: rem-calc(18);
                        line-height: rem-calc(20);
                    }

                    .text {
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                    }
                }
            }
        }
    }

    .micro-finance-container {
        &.advantage-outer-box {
            margin-top: rem-calc(40);
            // padding-top: rem-calc(32);
            background: linear-gradient(360deg, #ffffff 0%, #f9fafc 100%);
            .title {
                margin-bottom: rem-calc(20);
                // margin/-top: 0;
            }
        }
        .micro-finance-item {
            .advantage-img {
                width: 100%;
                height: auto;
                margin-top: rem-calc(18);
            }

            .micro-finance-content {
                flex-direction: column;
                justify-content: center !important;
                &.advantage-content {
                    flex-direction: column-reverse !important;
                }
            }

            .advantage-container {
                .advantage-item {
                    margin-bottom: rem-calc(22);

                    .right-icon {
                        width: rem-calc(16);
                        height: rem-calc(16);
                        margin-right: rem-calc(10);
                        margin-top: rem-calc(3);
                    }

                    .advantage-box {
                        .title {
                            font-size: rem-calc(14);
                            line-height: rem-calc(20);
                            margin-bottom: rem-calc(10);
                        }

                        .text {
                            font-size: rem-calc(12);
                            line-height: rem-calc(17);
                        }
                    }
                }
            }
        }
    }

    .mico-finance-contact-us {
        .bottom-contact-us {
            // padding: rem-calc(40) 0;
            height: rem-calc(214);
            padding: 0;

            .contact-content {
                .contact-slogan {
                    font-size: rem-calc(24);
                    line-height: rem-calc(36);
                    letter-spacing: 2px;
                    margin-top: rem-calc(0);
                    margin-bottom: rem-calc(12);
                }

                .contact-text {
                    font-size: rem-calc(14);
                    line-height: rem-calc(36);
                    letter-spacing: normal;
                    color: #dfc19e;
                    margin-bottom: rem-calc(20);
                }

                .contact-btn {
                    color: inherit;
                    display: inline-block;
                    width: rem-calc(108);
                    height: rem-calc(40);
                    border-radius: 4px;
                    font-size: rem-calc(14);
                    font-weight: 500;
                    text-align: center;
                    line-height: rem-calc(40);
                }
            }
        }
    }
}
