// 此页面与 fund 共通部分使用 fund.scss 中的样式

.distribution {
    &.hero {
        background-image: url('../img/solution/distribution/distribution-bg.png');
    }

    .desc-item-box {
        h3 {
            font-size: 24px;
            font-weight: 400;
        }

        p {
            color: #202020;
            line-height: 2;
        }
    }

    .module-info {
        margin-bottom: 120px;
    }

    &.superiority {
        h2 {
            margin-bottom: 72px;
        }

        .superiority-outer-box {
            padding: 0 11px;
        }

        .superiority-box {
            position: relative;
            padding: 72px 22px 65px;
            height: 232px;

            img {
                position: absolute;
                top: 50%;
                right: 22px;
                transform: translateY(-74%);
                height: 133px;
                z-index: -1;
            }

            h4 {
                font-weight: normal;
                font-size: 24px;
                margin-bottom: 16px;
            }

            .superiority-desc {
                font-size: 14px;
                opacity: 0.7;
            }
        }
    }
}

// store 的样式, 因为页面相似, 就不创建新文件了
.sol_store {
    &.hero {
        background-image: url('../img/solution/store/bg.png');
    }
}

.sol_retail.hero {
    background-image: url('../img/solution/retail/bg.png');
}

.sol_store,
.sol_retail {
    .module-info > div > div:last-child {
        padding-left: 0;
    }
}

.core-functions {
    background-color: #f5f7f8;
    //height: 459px;

    h2 {
        margin-bottom: 40px;
    }

    .core-outer-box {
        padding: 0 11px;
        margin-bottom: 22px;
    }

    .core-box {
        padding: 40px 22px;
        height: 205px;
        background-color: #ffffff;
        box-shadow: 0 0 5px 0 rgba(132, 177, 185, 0.24);

        h4 {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 0;
        }

        .hr-line {
            width: 100%;
            height: 1px;
            background-color: #f5f7f8;
            margin: 16px 0;
            position: relative;

            > span {
                position: absolute;
                left: 0;
                display: inline-block;
                width: 29%;
                height: 100%;
                background-color: #53cfcf;
            }
        }

        .core-desc {
            font-size: 14px;
            opacity: 0.7;
        }
    }
}

.get-solution-distribution,
.get-solution-store,
.get-solution-retail,
.get-e-commerce {
    height: 240px;
    background-image: linear-gradient(17deg, #1e98a6, #62dad7);
    padding: 40px 0 60px 0;

    h3 {
        margin: 32px 0;
        font-size: 24px;
        color: #ffffff;
        font-weight: 500;
    }

    .contact-us {
        background-color: #fff;
        color: #3898a5;
        font-weight: 400;

        &:hover {
            box-shadow: 0px 8px 10px rgba(21, 144, 165, 0.3);
        }
    }
}

.get-solution-store {
    background-image: linear-gradient(17deg, #1b4b9c, #4d76cd);

    .contact-us {
        color: #1c4c9d;
    }
}

.get-solution-retail {
    background-image: linear-gradient(17deg, #60489d, #a693dd);

    .contact-us {
        color: #5f3a9d;

        &:hover {
            box-shadow: 0px 8px 10px rgba(95, 58, 157, 0.3);
        }
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .core-functions {
        .core-box {
            height: 250px !important;
        }
    }
}
