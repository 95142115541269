.saas {
    background: #fcfcfd;
    .saas-header {
        background-image: url(../img/saas/banner_bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: rem-calc(600);
        .header-section {
            margin: rem-calc(90) 0 rem-calc(40);
            .header-text {
                padding-top: rem-calc(80);
                .header-section-title {
                    display: inline-block;
                }
                .text {
                    background-image: url(../img/saas/bar.png);
                    background-position: bottom;
                    background-size: 100% rem-calc(12);
                    background-repeat: no-repeat;
                }
            }
            .hide-for-small-only img {
                height: rem-calc(460);
                float: right;
            }
            h1 {
                font-size: rem-calc(40px);
                font-weight: 600;
                font-style: italic;
                letter-spacing: rem-calc(5px);
                &:first-of-type {
                    color: #3d6dde;
                }
            }
            ul {
                margin-top: rem-calc(30);
                li {
                    display: flex;
                    align-items: center;
                    margin: rem-calc(22) 0;
                    img {
                        width: rem-calc(24);
                        margin-right: rem-calc(10);
                        margin-top: -2px;
                    }
                }
            }
        }
    }

    h2 {
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: rem-calc(20);
        margin: rem-calc(60) 0 rem-calc(40);
        img {
            height: rem-calc(14);
        }
        img:last-of-type {
            margin-left: rem-calc(10);
            transform: rotate(180deg);
        }
    }

    .pains {
        display: flex;
        gap: rem-calc(20);
        margin: 0 auto;
        li {
            text-align: center;
            position: relative;
            &:first-of-type {
                margin-top: rem-calc(7);
            }
            p {
                width: 100%;
                position: absolute;
                bottom: rem-calc(20);
                left: 50%;
                transform: translateX(-50%);
                color: #303e5d;
            }
        }
    }

    .services {
        background-image: url(../img/saas/service_bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: rem-calc(80);
        color: #303e5d;
        & > div {
            display: flex;
            align-items: center;
            .text {
                padding-right: rem-calc(30);
                line-height: 2;
                position: relative;
                top: rem-calc(-40);
            }
        }
    }

    .advantage {
        display: flex;
        gap: rem-calc(20);
        margin: 0 auto;
        padding-bottom: rem-calc(80);
        li {
            flex: 1;
            border: 3px solid #fff;
            background: #fcfcfd;
            border-radius: rem-calc(16);
            color: #303e5d;
            padding: rem-calc(5);
            div {
                background: #fff;
                width: 100%;
                height: 100%;
                padding: rem-calc(30) rem-calc(22);
                display: flex;
                align-items: center;
            }
            img {
                width: rem-calc(80);
                margin-right: rem-calc(16);
            }
        }
    }
}
@media screen and (max-width: 39.9375em) {
    .header-text {
        padding-top: 0 !important;
        padding-left: rem-calc(16);
    }
    .pains {
        gap: rem-calc(6) !important;
        font-size: rem-calc(12);
        li {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 rem-calc(3);
            &:first-of-type {
                position: relative;
                top: rem-calc(-4);
            }
        }
        img {
            width: rem-calc(90);
        }
        p {
            position: unset !important;
            transform: unset !important;
        }
    }

    .services .text {
        padding: 0 rem-calc(16) rem-calc(30) !important;
        position: unset !important;
    }

    .advantage {
        gap: rem-calc(6) !important;
        li {
            border: unset !important;
            padding: unset !important;
            font-size: rem-calc(12);
            border-radius: rem-calc(6) !important;
            overflow: hidden;
            img {
                margin: 0 !important;
            }
            div {
                flex-direction: column;
                padding: rem-calc(5) !important;
            }
        }
    }
}
