.info-channel {
    text-align: center;
    overflow: hidden;
    li {
        list-style: none;
    }
    .nav-container {
        text-align: center;
        margin-bottom: rem-calc(40);
    }
    .nav-bar {
        margin: 0 auto;
        line-height: 1;
        border-bottom: 1px solid #9fa8bd33;
        li {
            margin: 0 rem-calc(20);
            padding-bottom: rem-calc(20);
            cursor: pointer;
            color: #b5bbc8;
            border-bottom: 2px solid transparent;
            position: relative;
            transition: all 0.2s;
            &.active {
                border-color: #21b2bb;
                color: #21b2bb;
            }
        }
        img {
            width: rem-calc(44);
            height: rem-calc(42);
            margin-right: rem-calc(15);
        }
    }
    .company-info,
    .industry-info {
        display: inline-block;
    }

    .scroll-bar {
        white-space: nowrap;
        overflow: hidden;
        width: 200%;
        position: relative;
        left: 0;
        transition: left 0.3s;
    }
    .scroll-item {
        float: left;
        width: 50%;
    }
    .company-info-list,
    .industry-info-list {
        overflow: hidden;
        margin: 0 auto;
        display: inline-flex;
        width: 100%;
        max-width: rem-calc(1200);
    }
    .info-item {
        flex: 1;
        padding: rem-calc(8);
        box-sizing: border-box;
        float: left;
        overflow: hidden;
        text-align: left;
        a {
            background: #f2f2f2;
            border-radius: rem-calc(16);
            box-shadow: 0 0 6px #e8e8e8;
            display: inline-block;
            width: 100%;
            position: relative;
            padding: 8px;
            .info-content {
                border-radius: 0 0 rem-calc(16) rem-calc(16);
                padding: 15px;
                box-sizing: border-box;
                background: #fff;
            }
        }
        .img-box {
            background: #fff;
            border-radius: rem-calc(12) rem-calc(12) 0 0;
            overflow: hidden;
        }
        .title {
            font-size: rem-calc(16);
            color: #303e5d;
            margin-bottom: rem-calc(10);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .desc {
            font-size: rem-calc(14);
            color: #acb0b9;
            margin-bottom: rem-calc(10);
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 1.2;
            white-space: initial;
        }
        .class-date {
            font-size: 14px;
            color: #9fa8bd;
            margin-top: 15px;
        }
        .class {
            background: #f7f7fb;
            padding: 4px 8px;
            border-radius: rem-calc(5);
        }
        .date {
            float: right;
        }
    }
    .control {
        a {
            display: inline-block;
            padding: 0 rem-calc(20);
            height: rem-calc(48);
            line-height: rem-calc(46);
            border: 1px solid #21b2bb;
            border-radius: rem-calc(48);
            margin-top: rem-calc(30);
            min-width: rem-calc(190);
            img {
                width: rem-calc(36);
                height: rem-calc(30);
                margin-top: rem-calc(-5);
                margin-left: rem-calc(15);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .info-channel {
        .scroll-item {
            overflow-x: scroll;
            .company-info-list,
            .industry-info-list {
                width: 660px;
            }
            .info-item {
                flex: 0 0 220px;
            }
        }
    }
}
