// Footer settings
$footer-background-color: $gray-2;
$footer-link-color: $gray-9;
$footer-body-color: $gray-6;
$footer-invert-background-color: #212121;
$footer-invert-link-color: $gray-3;
$footer-invert-body-color: #757575;

// Footer
footer {
    width: 100%;
    padding-top: rem-calc(60);
    background: #fff;
    // box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.03);

    ul.row {
        // width: 75%;
        margin: 0 auto rem-calc(20);
        list-style: none;
        display: flex;
        justify-content: space-between;
        .footer-logo {
            width: rem-calc(160);
            opacity: 0.9;
        }
        li {
            list-style: none;
            margin-bottom: rem-calc(30);
            a {
                font-size: rem-calc(14);
                line-height: rem-calc(18);
                color: #606060;
            }
        }
        dt {
            margin-bottom: rem-calc(30);
            font-size: rem-calc(16);
            line-height: rem-calc(28);
            color: #202020;
            font-weight: 500;
            opacity: 0.9;
        }
        dd {
            font-size: rem-calc(14);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(14);
            color: #4a4a4a;
            font-weight: 500;
            opacity: 0.8;
        }
        .time {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(14);
            color: #606060;
            line-height: rem-calc(22);
        }
    }
    .weixin {
        position: relative;
        display: flex;
        .border {
            border: 1px solid #21b2bb;
            border-radius: 4px;
            overflow: hidden;
        }
        .we-text {
            font-size: rem-calc(12);
            opacity: 0.5;
            margin-top: rem-calc(2);
        }
        img {
            width: rem-calc(80);
            height: rem-calc(80);
        }
        .we-customer {
            margin-left: rem-calc(20);
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .we-public {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .beian {
        // width: 75%;
        margin: 0 auto;
        color: #606060;
        font-size: rem-calc(12);
        height: rem-calc(60);

        background: #f7f8fa;

        a {
            color: #606060;
        }

        p > span {
            margin-right: 10px;
            margin-bottom: 0;
            line-height: rem-calc(60);
        }
    }
}

// Language Switcher
.footer__language-switcher {
    position: relative;
    width: 25px;
    height: 16px;
    cursor: pointer;
    float: right;
    margin-top: 5px;
    transition: transform 0.2s ease;

    .lang {
        color: $footer-link-color;
        border: 1px solid $footer-link-color;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        position: absolute;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        background: $footer-background-color;

        &.han {
            top: -2px;
            left: -2px;
            font-size: 11px;
            font-weight: 600;
            padding-left: 1px;
            transition: top 0.2s ease-out, left 0.2s ease-in, background 0.2s ease, color 0.2s ease;
        }

        &.en {
            top: 2px;
            left: 11px;
            font-weight: 500;
            padding-left: 1px;
            transition: top 0.2s ease-out, left 0.2s ease-in, background 0.2s ease, color 0.2s ease;
        }
    }

    &:hover {
        .lang {
            &.han {
                top: 2px;
                left: 11px;
                border-color: $footer-link-color;
                color: $footer-background-color;
                background: $footer-link-color;
            }
            &.en {
                top: -2px;
                left: -2px;
            }
        }
    }

    &:active {
        transform: scale(0.95);
    }
}

.footer-content {
    &--full {
    }
}

// footer-docs.html的样式
footer {
    &.invert {
        .menu {
            display: flex;
            justify-content: center;
        }
        .xsmall {
            text-align: center;
        }
        .beian {
            background: #ffffff;
        }
    }
}

@media screen and (max-width: 39.9375em) {
    footer {
        padding-top: rem-calc(40) !important;
        padding-bottom: rem-calc(20);
        ul {
            list-style: none;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: rem-calc(10);
        }
        .nav-title {
            font-size: rem-calc(15);
            font-weight: 600;
            color: #202020;
            line-height: rem-calc(40);
            height: rem-calc(40);
            margin-bottom: 0;
            position: relative;
            &:after {
                content: '\e906';
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                border: none;
                width: auto;
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: rem-calc(-6);
                -webkit-font-smoothing: antialiased;
                transform: rotate(270deg);
                display: inline-block;
            }
        }
        .nav-content {
            display: none;
            &.expand {
                display: block;
            }
            p {
                margin-bottom: 0;
                padding: rem-calc(8 15);
            }
            a {
                font-size: rem-calc(12);
                font-weight: 400;
                color: #202020;
                line-height: rem-cacl(18);
            }
        }

        .nav-consult {
            .title {
                width: 100%;
                font-size: rem-calc(15);
                font-weight: 600;
                color: #202020;
                line-height: rem-calc(23);
                margin-bottom: (10);
            }
            .phone {
                width: 100%;
                font-size: rem-calc(14);
                line-height: rem-calc(23);
                margin-bottom: 0;
            }
            .time {
                width: 100%;
                font-size: rem-calc(12);
                font-weight: 400;
                color: #202020;
                line-height: rem-calc(18);
                margin-bottom: rem-calc(16);
            }
        }
        .we-public {
            align-items: center;
        }
        .beian {
            margin: 0 auto;
            color: #606060;
            font-size: rem-calc(12);
            height: auto;
            background: #ffffff;
            padding: rem-calc(40) 0 0;
            a {
                color: #606060;
            }
            p {
                text-align: center;
                width: 100%;
                margin: 0;
            }
            p > span {
                display: block;
                font-size: rem-calc(12);
                font-weight: 400;
                color: #202020;
                line-height: rem-calc(21);
            }
        }
    }

    // footer-docs.html的样式
    footer {
        &.invert {
            .menu {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
            .xsmall {
                text-align: center;
                padding-top: rem-calc(10);
                margin-bottom: 0;
            }
            .beian {
                padding: 0;
                background: #ffffff;
            }
        }
    }
}
