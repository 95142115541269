.text-left {
    h1,
    h2,
    h3,
    h4,
    p {
        width: 100%;
        text-align: left;
        margin: 0;
    }
}
.sol-b2b-box {
    img {
        height: rem-calc(286);
        width: auto;
    }
}
.sol-b2b-container {
    .pain-outer-box {
        position: relative;
        padding: rem-calc(50 10);
        &:first-child {
            padding: rem-calc(20 60 0 10);
        }
        &:last-child {
            padding: rem-calc(20 0 0 70);
        }
        &:first-child::before {
            content: '1';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            background: #3361d6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &:last-child::before {
            content: '2';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            background: #3361d6;
            position: absolute;
            top: 0;
            left: rem-calc(60);
            z-index: 2;
        }
        .pain-box {
            height: rem-calc(172);
            color: #252525;
            background: #f3f6ff;
            padding: rem-calc(20 50 30 60);
            position: relative;
            z-index: 1;

            h4 {
                font-size: rem-calc(24);
                font-weight: 600;
                color: #252525;
                line-height: rem-calc(33);
                margin-bottom: rem-calc(10);
            }
            p {
                font-size: rem-calc(16);
                font-weight: 400;
                color: #252525;
                line-height: rem-calc(24);
            }
            &::before {
                content: ' ';
                width: 100%;
                height: rem-calc(172);
                background: linear-gradient(to left top, transparent 50%, #f2f5ff 50%);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
            &::after {
                content: ' ';
                width: 100%;
                height: rem-calc(172);
                background: linear-gradient(to right bottom, transparent 50%, #ecf1ff 50%);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }
    }

    .service-nav-box {
        position: relative;
    }

    .service-nav {
        position: relative;
        .box-list {
            padding: 0 rem-calc(10);
            opacity: 0.75;
            cursor: pointer;
            transition: font-size 0.25s, color 0.25s;
            font-size: rem-calc(18);
            color: #000000;
            border-left: 2px #f3f6ff solid;
            font-weight: 500;
            // &:nth-child(2) {
            //     padding: rem-calc(30) rem-calc(10);
            // }
            
            &.active {
                color: #21b2bb;
                opacity: 1;
                font-weight: 600;
                position: relative;
                &::before {
                    content: ' ';
                    width: rem-calc(2);
                    height: rem-calc(30);
                    left: rem-calc(-2);
                    top: 50%;
                    margin-top: rem-calc(-16);
                    position: absolute;
                    background: #21b2bb;
                }
            }
            &:last-child{
                padding-top: rem-calc(30);
                &.active{
                    &::before {
                        top: 50%;
                        margin-top:0;
                    }
                }
            }
        }
    }

    .service-content {
        .service-label {
            display: none;
        }
        h4 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #000000;
            line-height: rem-calc(33);
            margin-bottom: rem-calc(20);
        }
        p {
            font-size: rem-calc(16);
            color: #252525;
            line-height: rem-calc(22);
        }
        img {
            width: auto;
            height: auto;
            margin: rem-calc(20) 0;
        }
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .sol-b2b-box {
        .content {
            &::before {
                content: '';
                width: rem-calc(48);
                height: rem-calc(36);
                line-height: rem-calc(36);
                color: #ffffff;
                background: transparent;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        img {
            height: rem-calc(194);
        }
    }
    .sol-b2b-container {
        .pain-outer-box {
            &:first-child {
                padding: rem-calc(20 10);
            }
            &:last-child {
                padding: rem-calc(20 10);
            }
            &:first-child::before,
            &:last-child::before {
                width: rem-calc(36);
                height: rem-calc(36);
                font-size: rem-calc(22);
                font-weight: bold;
                color: #ffffff;
                line-height: rem-calc(36);
                text-align: center;
                position: absolute;
                background: transparent;
                top: 0;
                left: 0;
                z-index: 2;
            }
            &:first-child::after,
            &:last-child::after {
                content: ' ';
                width: 0;
                height: 0;
                border-top: 36px solid #3666de;
                border-right: 36px solid #3361d6;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
            .pain-box {
                height: rem-calc(137);
                padding: rem-calc(20 36 20 37);

                h4 {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                }
                p {
                    font-size: rem-calc(14);
                    line-height: rem-calc(20);
                }
                &::before {
                    height: rem-calc(137);
                }
                &::after {
                    height: rem-calc(137);
                }
            }
        }

        .service-nav {
            display: none;
        }
        .service-content {
            img {
                width: auto;
                height: rem-calc(207);
            }
            .service-label {
                display: block;
                margin-bottom: 0;
            }
            h4 {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
            p {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
        }
    }
}
