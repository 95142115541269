.top-bar {
    background: transparent;
    width: 100%;
    padding: 0.57rem 0 !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ul {
        background: transparent;
        li {
            a {
                font-size: rem-calc(14);
                font-weight: 500;
                color: #4a4a4a;
                transition: color 0.2s ease;
                padding: 0.7rem rem-calc(16);
                &:hover {
                    color: $anchor-color-hover;
                }
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 14px;
                }
            }
            @media screen and (max-width: rem-calc(1250)) {
                a {
                    padding: 0.7rem rem-calc(8);
                }
            }
        }
        .submenu {
            padding: 9px;
            background: $white;
            border-radius: $global-radius;
            border: 1px solid #f3f3f3 !important;
            box-shadow: 0px 5px 10px 0px rgba(12, 14, 44, 0.12) !important;
        }
    }
    .cta.hollow {
        padding: 0 rem-calc(15);
        height: rem-calc(28);
        line-height: rem-calc(26);
        font-size: rem-calc(14);
        font-weight: 600;
        background: #21b2bb;
        border-radius: rem-calc(rem-calc(7.5));
        color: #ffffff;
        &.login-link {
            background: transparent;
            color: #21b2bb;
            border: 1px solid #21b2bb;
            border-radius: rem-calc(7.5);
            margin-right: rem-calc(10);
        }
    }
}

.dropdown.menu > li.is-dropdown-submenu-parent a {
    white-space: nowrap;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    content: '\e906';
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    border: none;
    width: auto;
    top: 35%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.top-bar-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    // position: relative;
    width: 100%;
    z-index: 99;
    // padding: 1em 0;

    @include breakpoint(medium) {
        position: fixed;
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        background: rgba(255, 255, 255, 0);
    }

    &.index-with-activity {
        top: auto;
    }

    &.fixed2 {
        background: rgba(255, 255, 255, 0.95);
        padding: 0.1em 0;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
        top: 0 !important;

        .top-bar {
            // ul li a {
            //     &:hover {
            //         color: $anchor-color-hover;
            //     }
            // }
            .submenu a {
                color: rgba(0, 0, 0, 0.6);
                &:hover {
                    color: rgba(0, 0, 0, 0.8);
                }
            }
            .cta.hollow {
                &:hover {
                    // color: #fff;
                    // background-color: #14b1d1;
                }
            }
        }

        .top-bar__logo {
            background: url('../img/newhome/logo-black.svg') left center no-repeat;
            background-size: 100%;
            height: 16px;
            width: 130px;
            padding: 1.1rem 1rem;
        }
    }
}
.top-bar-left {
    min-width: 485px !important;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.top-bar-left .big a {
    font-size: rem-calc(16);
}

.top-bar__logo {
    background: url('../img/newhome/logo-black.svg') left center no-repeat;
    background-size: 100%;
    height: 16px;
    width: 130px;
    padding: 1.1rem 1rem;
}
.title-bar {
    .top-bar__logo {
        background: url('../img/newhome/logo-black.svg') left center no-repeat;
        background-size: 90%;
        height: 16px;
        width: 130px;
        padding: 1.1rem 1rem;
    }
}
// tabs
.tabs {
    border-radius: $global-radius $global-radius 0 0;
    .tabs-title {
        a {
            background-color: transparent;
            font-weight: 500;
            color: $gray-9;
            font-size: rem-calc(14);
            border-bottom: 2px solid transparent;
            margin-bottom: -1px;
        }
        &.is-active {
            a {
                color: $black;
                border-bottom: 2px solid $primary-color;
            }
        }
    }
}

.tabs-content {
    border-radius: 0 0 $global-radius $global-radius;
}

.nav.block-grid {
    background: #fafafa;

    &.no-border {
        ul li {
            border: none;
        }
    }
    ul {
        width: 100%;
        text-align: center;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            list-style: none;
            width: 33%;
            display: flex;
            border-right: 1px solid #eeeeee;

            @include breakpoint(small down) {
                width: 100%;
                border: none;
                border-bottom: 1px solid #eeeeee;
            }

            & > a {
                padding: 2em;
                padding-bottom: 2.5em;
                width: 100%;
                display: block;
                transition: background 0.2s ease;
                height: inherit;
                position: relative;

                @include breakpoint(large) {
                    // padding: 2em 3em;
                }

                @include breakpoint(small down) {
                    padding: 2em 1em;
                }

                h5 {
                    font-size: rem-calc(16);
                    color: $black;
                    margin-bottom: 8px;
                }

                span {
                    display: block;
                    font-size: rem-calc(14);

                    &.description {
                        color: $black;
                        // font-weight: 300;
                        line-height: 1.4;
                        margin-bottom: 3em;
                    }

                    &.anchor {
                        position: absolute;
                        bottom: 2.5em;
                        width: 100%;
                        text-align: center;
                        left: 0.2em;
                    }
                }
                &:hover {
                    background-color: darken(#fafafa, 3);
                    .anchor {
                        text-decoration: underline;
                    }
                }
            }

            &:last-child {
                border: none;
            }
        }
    }
}

.nav-block-grid__icon {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

// subnav-bar
.subnav-container {
    background: #f0f0f0;
    // border-bottom: 1px solid $light-gray;
    // border-top: 1px solid $light-gray;
    height: 50px;
    z-index: 99 !important;
    //opacity: 0;
    //transform:translateY(-50px);

    transition: all 0.2s ease;

    &.dark-mode {
        &.is-stuck {
            background: HSL(300, 14%, 1%);
            .subnav-bar {
                li a {
                    color: rgba(255, 255, 255, 0.4);
                    &.active {
                        color: rgba(255, 255, 255, 0.85);
                    }
                }
            }
            .button.hollow {
                color: $white;
            }
        }
    }

    &.is-stuck {
        background: #fcfcfc;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        //height: 50px;
        //opacity: 1;
    }
    .button.cta {
        // background: $primary-color;
        // border-color: $primary-color;
        //background: $black;
        color: $white;
        font-weight: 500;
        // animation: blink 1s infinite;
    }
    //
    // @keyframes blink {
    //   0% {
    //     background-color: rgba(0,0,0,0.1);
    //   }
    //   50% {
    //     background-color: rgba(0,0,0,1);
    //     transform: scale(15) translateY(20px) translateX(-25px);
    //   }
    //   100% {
    //     background-color: rgba(0,0,0,0.1);
    //   }
    // }

    .subnav-bar {
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    &.is-stuck {
        .subnav-bar {
            opacity: 1;
        }
    }
}

.float-right {
    float: right;
}

.subnav-bar {
    li a {
        padding: 1rem 1.2rem;
        color: $gray-6;
        text-transform: uppercase;
        font-weight: 400;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        letter-spacing: 0.012rem;
        &:hover {
            color: $anchor-color-hover;
        }
        &.active {
            color: $black;
        }
    }

    ul.menu.left li:first-child a {
        padding-left: 0rem;
    }

    .button {
        padding: 0.3rem 1rem;
        margin-top: 0.6rem;
        &.hollow {
            border-color: transparent;
            border-radius: 50px;
        }
    }
}
.side-bar {
    font-size: rem-calc(14);
    font-weight: 500;
    ul.menu {
        li {
            margin-bottom: 0.5em;
        }
    }
    a {
        color: $black;
        border-radius: $global-radius;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: $gray-3;
        }

        span.description {
            display: block;
            color: $gray-8;
            font-size: rem-calc(12);
            margin-top: 0.12em;
        }

        &.active {
            background-color: $light-gray;
            color: $black;
            span.description {
                color: $black;
            }
        }
    }
}
