.accordion {
  .accordion-title, .accordion-content {
    border: 1px solid transparent;
    border-bottom-color: #e0e0e0;
  }
  .accordion-title {
    padding-left: 0;
    padding-right: 2rem;
    line-height: 1.5;

    &:hover, &:focus {
      background-color: inherit;
    }
  }

  .accordion-content {
    color: currentColor;
    padding-left: 0;
  }
}
