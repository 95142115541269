.ld-career-list{

  span.location{
    float:left;
    padding-right: 2em;
    color: $gray-8;
  }
  .accordion-title {
    @include breakpoint(small only){
      padding-right: 2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    };
  }

  .accordion-content{
    padding-top: 2em;
    h4{
      text-align: left;
      margin-bottom: 1.2em;
    }
    ul{
      margin-bottom: 2em;
    }
    h6{
      margin-bottom: 0.5em;
    }
    a.button{
      color: $white;
    }
  }
}

.hero-career {
  display: flex;
  height:100%;
  align-items: center;
  & > .row {
    width: 100%;
  }
  .polygon {
    width: 200px;
    height: 111px;
    position: absolute;
    background-image: url('../img/polygon.gif');
    background-position: 0% 50%;
    background-repeat: no-repeat
  }

  h1, p {
    text-align: left;

    @include breakpoint(small only){
      text-align: center;
    };
  }

  h1 {
    margin-bottom: 2rem;
    font-family: $serif-font-family;
    font-weight: 400;
  }

  .hero-bg {
    z-index: -1;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    overflow: hidden;
    .hreo-bg-content {
      position: relative;
      width: 110%;
      height: 110%;
    }
  }
}

.ld-content-career {
  .tabs {
    background: transparent;
    border:none;
  }
  .tabs-title {
    float:none;
    display: block;
    padding: 1rem 0rem;
    @include breakpoint(small only){
      float:left;
      width: 50%;
    };
    & > a {
      display: inline-block;
      font-size: 1.2rem;
      padding: 0;
      padding-bottom: .5rem;
      font-weight: 400;
      color:#fff;
      transition: all .4s;
      @include breakpoint(small only) {
        font-size: 1rem;
      };
    }

    &.is-active {
      a{
        color:#fff;
        border-bottom-color: #9e9e9e;
      }
    }
  }
  .tabs-content {
    background: transparent;
    border:none;
    height:372px;
    @include breakpoint(small only){
      padding-top: 1rem;
      height:302px;
    };
  }
  .tabs-panel {
    position: relative;
    height: 100%;
  }

  .team-desc {
    line-height: 2;
    padding: 2rem 0rem 2rem 0;
    min-height: 200px;
    @include breakpoint(small only){
      min-height: 130px;
      padding: 0rem 2rem 1rem 0;
    };

  }
  .team-signs-bg {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 375px 297px;
    @include breakpoint(small only){
      background-size: 293px auto;
    };
    &.products{
      background-image: url('../img/polygon-big.svg');
      background-position: 30% 10%;
      @include breakpoint(small only){
        background-position: 0 0;
        left: -1.5rem;
        background-size: 317px auto;
      };
    }
    &.engineer{
      background-image: url('../img/five-line.svg');
      // background-position: 35% 10%;
    }
    &.misc{
      background-image: url('../img/incline-rectangle.svg');
      // background-position: 30% 10%;
      @include breakpoint(small only){
        background-position: 0 0;
      };
    }
    &.operations{
      background-image: url('../img/triangle-big.svg');
      background-position: 30% -10%;
      @include breakpoint(small only){
        background-position: 0 0;
      };
    }
    &.marketing{
      background-image: url('../img/cross-line-big.svg');
      // background-position: 30% 10%;
      @include breakpoint(small only){
        background-position: 0 0;
        background-size: 267px auto;
      };
    }
    &.options{
      background-image: url('../img/slashline-big.svg');
      // background-position: 30% 10%;
      @include breakpoint(small only){
        background-position: 0 0;
        background-size: 158px auto;
      };
    }
  }
}

.image-walls{
  position: absolute;
  top:0;

  &.left {
    left:0;
    width:46%;
    @include breakpoint(small only){
      top: 41%;
      width: 100%;
    };
  }
  &.right {
    right:0;
    width:49%;
    @include breakpoint(small only){
      top: 53%;
      width: 100%;
    };
  }


  #pingerChat {
    width:100%;
    height:502px;
    background-image: url(../img/career-chat1.jpg);
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover!important;
    @include breakpoint(small only){
      height:343px;
    };
  }

  #dinerHall {
    width:100%;
    height:343px;
    background-image: url(../img/career-diner.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover!important;
    margin-bottom: 2rem;
    @include breakpoint(small only) {
      margin-bottom: 1rem;
    };
  }

  #tabelGame {
    width: 300px;
    height: 192px;
    background-image: url(../img/career-game.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover!important;
  }

  .bottom {
    display: flex;
  }

  .desc {
    max-width: 240px;
    padding-left: 1.2rem;

    @include breakpoint(small only){
      text-align: left;
    };

  }

}

#jobDetails{
  & > .row {
    margin-bottom: 4rem;
    @include breakpoint(small only) {
      margin-bottom: 2rem;
    };
  }

}

#pingerStory{
  position: relative;
  height:534px;
  .content {
    min-height: 423px;
    margin-bottom: 2rem;
    @include breakpoint(small only){
      min-height:323px;
    };
  }
  .story-bg {
    position: absolute;
    top:50%;
    left:50%;
    width: 524px;
    height:452px;
    background-image: url(../img/polygonline-large.svg);
    background-size: cover;
    background-repeat: no-repeat;;
    transform: translate(-68%,-37%);
    @include breakpoint(small only) {
      display: none;
    };
  }
  #storyMakrup {
    @include breakpoint(small only){
      margin-top: 23rem;
    };
  }

  @include breakpoint(small only){
    height:930px;
  };
}

#pingerLife {
  margin: 4rem 0;
  position: relative;
  height:679px;
  @include breakpoint(small only){
    margin-top: 0;
    height:1069px;
  };

  .benefits-list {
    h2 {
      margin-bottom: 3rem;
      @include breakpoint(small only){
        margin-bottom: 1.8rem
      };
    }
    dl {
      margin-bottom: 5rem;
      @include breakpoint(small only){
        margin-bottom: 2.5rem;
      };
    }
    dt {
      font-weight: normal;
      margin-top: .5em;
    }
    @include breakpoint(small only){
      margin-bottom: 1rem;
    };
  }
  .benefits-extra {
    margin-top: 2rem;
    max-width: 340px;
    color:$white;
    a {
      color:$white;

      &:hover {
        color:#cecece;
      }
    }
  }
  .life-bg {
    position: absolute;
    left:45%;
    top:73%;
    width: 332px;
    height: 286px;
    background-image: url(../img/polygonline-medium.svg);
    background-size: cover;
    background-repeat: no-repeat;;
    transform: translate(-50%,0%);
    z-index: 99;
    @include breakpoint(small only) {
      display: none;
    };

  }
}

#pingerOverview {
  position: relative;
  height:414px;
  margin: 2rem 0 4rem;
  .viewport {
    position: relative;
    width: 85%;
    height:100%;
    overflow: hidden;
    @include breakpoint(small only){
      width: 100%;
      height:291px;
    };
  }
  .views {
    height: 100%;
    width:400%;
  }
  .view {
    float: left;
    width: 25%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover!important;
    &.first {
      background-image: url("../img/career-life1.jpg");
    }
    &.second {
      background-image: url("../img/career-life2.jpg");
    }
    &.third {
      background-image: url("../img/career-life3.jpg");
    }
  }
  .content {
    position: absolute;
    left:87%;
    bottom: -2rem;
    width:10%;
    min-width: 150px;
    max-width: 240px;
    @include breakpoint(small only){
      position: relative;
      width: 70%;
      margin: 1rem auto 2rem;
      left:auto;
      bottom: auto;
    };
  }
}
