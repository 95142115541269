// Weights
h1, h2 {
  @include breakpoint(medium) {
    font-weight: 300;
  }
}

h1, h2, h3, h4, h5, h6 {
  .punctuation{
    letter-spacing: -0.5em;
  }
}



h6{
  font-weight: 500;
}

// Letter spacing adjustments
h1{
  letter-spacing: -0.012em;
}

h2{
  letter-spacing: -0.008em;
}

h3{
  letter-spacing: -0.008em;
}

h6{
  letter-spacing: 0.012em;
  &.caps{
    text-transform: uppercase;
    font-size: rem-calc(14);
    letter-spacing: 0.03em;
  }
}


// Anchor links
span.anchor{
  &:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: 0.2em;
    content: "\e909";
  }
}

a{
  &.anchor, &.external, &.underline{
    // &:hover:not(.button):not(.tab):not(.block-grid-link):not(.icon){
    //   display: inline;
    //   background-image: -webkit-linear-gradient(top, $anchor-color 0, $anchor-color 100%);
    //   background-repeat: repeat-x;
    //   background-size: 1px 1px;
    //   text-decoration: none;
    //   text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    //   background-position: 0 99%;
    // }
    &:hover{
      text-decoration: underline;
    }
  }

  &.anchor:after, &.external:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: 0.2em;
  }

  &.anchor:after{
    content: "\e909";
  }

  &.external:after{
    content: "\e907";
  }

  &.icon{
    color: $medium-gray;
    text-decoration: none;
    &:hover{
      background: none;
      color: $anchor-color;
    }
  }
  &.invert{
    color: $medium-gray;
  }
}

// .invert{
//   a:hover:not(.button) {
//     text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
//   }
// }

// Paragraphs
p{
  &.small{
    font-size: rem-calc(14);
  }
  &.xsmall{
    font-size: rem-calc(12);
  }
  em{
    font-weight: 600;
    font-style: normal;
  }
  &.gray {
    color:#9e9e9e;
  }
}

.text-markup {
  font-size: rem-calc(13);
  color: #9E9E9E;
  letter-spacing: 0px;
  line-height: 1.41;
  padding-bottom: 3rem;
  padding-right: 2rem;

  &:before{
    content: ' ';
    width: 30px;
    height: 1px;
    background: #E0E0E0;
    display: block;
    margin-bottom: 2em;
  }
  @include breakpoint(small only){
    text-align: center;
    padding-left:2rem;
    padding-top:2rem;
    &:before{
      margin: 0 auto;
      margin-bottom: 2em;
    }
  };
}
