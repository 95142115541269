.hero-account {
  background-image: url('../img/bg-account.jpg') !important;
}

.account-title {
  h3 {
    img {
      vertical-align: -5px !important;
    }
  }
}

.system-box {
  padding-top: 66px;

  & > div {
    //width: 466px;
    height: 112px;
    //position: relative;
    cursor: pointer;

    .box-icon {
      float: left;
      display: inline-block;
      width: 118px;
      height: 112px;
      line-height: 112px;
      text-align: center;

      & > img {
        width: 60px;
      }
    }
  }

  .box-active {
    background-color: #F9F9F9;
  }
}

.system-box-phone.moudle-6.show-for-small-only .arrow-s-l, .system-box-phone.moudle-6.show-for-small-only .arrow-s-r {
  top: 75px;
}

.system-box-phone .scen {
  background-image: none;
}

// small
.phone-only {
   display: none;
}

.box-desc {
  //display: inline-block;
  padding-top: 32px;

  &-title {
    font-size: 18px;
    font-weight: 600;
  }

  &-message {
    opacity: .7;
    font-size: 14px;
    margin-top: 3px;
    padding: 0 10px;
  }
}

#account-switch-one > div {
  cursor: pointer;
}

.system-box-image {
  position: relative;

  & .account-rect-img {
    width: 378px;
    height: 540px;
  }

  & .account-frame-img, & .account-contain-img {
    position: absolute;
    left: 124px;
  }

  & .account-frame-img {
    width: 292px;
    //height: 540px;
    top: 0;
  }

  & .account-contain-img {
    top: 65px;
    left: 146px;
    width: 240px;
  }

  #transfer-client, #withdraw-client, #consumer-client {
    display: none;
  }
}

.control-box-fun {
  position: relative;
}

.control-box {
  margin: 30px 0 0 210px;
  span {
    cursor: pointer;
    margin: 3px;
  }

  .unactive-control {
    opacity: .7;
    font-size: 14px;
    border-bottom: 1px #AAAAAA solid;
    padding-bottom: 4px;
  }
}

.box-row {
  padding: 55px 0;
}

.account-box {
  background-color: #F9F9F9;
  position: relative;

  .account-manager {

    img {
      width: 160px;
    }
  }

  .box-desc {
    display: block;
    text-align: -webkit-center;
    text-align: -moz-center;

    &-message {
      max-width: 343px;
    }
  }
}

.coupon_manager_title {
  position: relative;
  height: 155px;

  p {
    max-width: 720px;
    position: absolute;
    right: 0;
  }
}

.coupon-box {
  .account-rect-img {
    width: 514px;
    height: auto;
  }

  .account-coupon-img {
    position: absolute;
    top: 25px;
    left: 0px;
    width: 460px;
  }
}

#coupon_consumer-big {
  width: 250px;
  left: 20%;
  top: 0px;
}

.coupon {
  & > div {
    height: 136px;

    & .box-icon {
      line-height: 136px;
      height: 136px;
    }
  }
}

#scenSlide1 {
  .arrow-s-l, .arrow-s-r {
    top: 95px;
  }

  a {
    height: 175px;
  }

  img {
    width: 20em;
  }
}

.horizontal.account {
  padding-left: 0;
  margin-top: 100px;
}

// coupon manage
@media screen and (max-width: 39.9375em) {
  .account-title {
    text-align: -webkit-center !important;
    text-align: -moz-center !important;

    > h1 {
      font-size: 30px !important;
      line-height: 1.5 !important;
      width: 238px !important;
    }
    > h4 {
      margin-bottom: 70px !important;
    }
  }

  .pc-only {
    display: none !important;
  }

  .phone-only {
    display: inherit !important;
  }

  .module-fir.fir-title {
    > h2 {
      font-size: 30px !important;
      line-height: 43.5px !important;
    }
  }

  .module-sec {
    padding-top: 44px !important;
    padding-bottom: 0px !important;
  }

  .sec-title {
    > h3 {
      font-size: 22px !important;
      margin-bottom: 0 !important;
    }
  }


  .coupon_manager_title {
    text-align: left !important;
    height: auto;

    h3 {
      font-size: 37px;
    }

    p {
      font-size: 13px;
      position: inherit;
    }
  }
}

@media screen and (max-width: 66.5em) and (min-width: 40em) {
  .hide-for-medium-only.medium {
    display: none !important;
  }
}

@media screen and (max-width: 73.5em) and (min-width: 40em) {
  .hide-for-medium-only.large {
    display: none !important;
  }
}
