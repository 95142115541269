// callout
.callout{
  border: 1px solid $light-gray;
  h6{
    font-size: rem-calc(14);
    margin-bottom: 0.35em;
  }
  p.small, p.xsmall{
    line-height: 1.2;
  }

  &.fill{
    border: none;
    background: $gray-3;
  }
}

// number stat
.stat-number{
  font-weight: 300;
  h1{
    font-weight: 200;
    font-size: rem-calc(68);
    margin-bottom: -0.2em;
    span.small{
      font-size: 30%;
      font-weight: 400;
    }
  }
}

// reveal, modal
.reveal{
  background: #FFFFFF;
  border: 1px solid rgba(0,0,0,0.3);
  box-shadow: 0px 12px 15px 0px rgba(0,0,0,0.05);
  border-radius: 3px;
}

.reveal-overlay{
  background-color: rgba(0, 0, 0, 0.24);
}

// code block
.code{
  background: #FFFFFF;
  padding: 16px;
  // border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #E0E0E0, inset 49px 0 0 #FAFAFA, inset 50px 0 0 #E8E8EB;
  margin-bottom: $header-margin-bottom;
  pre{
    counter-reset: linenumbers;
    div{
      font-family: $font-family-monospace;
      font-size: rem-calc(12);
      width: 100%;
      &:before{
        font-size: rem-calc(10);
        font-weight: 400;
        position: relative;
        top: 2px;
        float: left;
        width: 12px;
        margin-right: 40px;
        content: counter(linenumbers);
        counter-increment: linenumbers;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        text-align: right;
        color: #BDBDBD;
      }
    }
  }
}

.logo-wall{
  // padding: 1em 0;
  // .logo{
  //   display: inline-block;
  //   max-width: 80%;
  //   max-height: 40px;
  //   margin-bottom: 1rem;
  //   @include breakpoint(small only){
  //     max-height: 30px;
  //   };
  // }
}

ul.menu{
  li a{
    transition: color 0.2s ease, border-color 0.2s ease;
  }
}

table thead, table tfoot{
  background: transparent;
  border-left: none;
  border-right: none;
}

table thead tr, table tfoot tr{
  border-bottom: 1px solid $gray-6;
}

table thead, table tbody, table tfoot {
  border: none;
  background-color: transparent;
}

table tbody tr{
  border-bottom: 1px solid $light-gray;
}

table tbody tr:nth-child(even) {
  background: transparent;
}

table tbody th, table tbody td {
  padding: 1rem 1rem 1.225rem;
}

table thead th, table thead td, table tfoot th, table tfoot td{
  padding: 0.625rem 1rem 0.825rem;
}

@include breakpoint(small only) {
  table tbody tr{
    padding: 2em 0em 1.5em;
  }
  table tbody tr:first-child{
    border-top: 1px solid $light-gray;
  }
  table tbody tr td:first-child{
    font-weight: 600;
  }
}

.pricing-table{
  text-align: left;
  td h3{
    text-align: left;
    margin-bottom: 0;
    .small{
      font-size: rem-calc(16);
      font-weight: 400;
      padding-left: 0.2em;
    }
  }
}

.news{
  border-top: 1px solid #eeeeee;
  border-bottom:  1px solid #eeeeee;
  margin: 0;

  h1,h2,h3,h4,h5,h6,p,a{
    margin-bottom: 0;
    @include breakpoint(small only){
      margin: 0.5em 0 0em;
    };
  }

  &.ld-content > .row{
    padding: 1em 0;
  }
}

.list--style-none{
  list-style: none;
  margin: 0;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
