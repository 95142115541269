.title-bar {
    background: transparent;
    width: 100%;
    color: $body-font-color;
    padding: 0.5rem rem-calc(16);
    .top-bar__logo {
        padding: 0;
    }
    &--active {
        .top-bar__logo {
            background-image: url('../img/newhome/logo-white.png');
        }
    }
}

body.no-scrolling {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.menu-icon {
    float: right;
}

.title-bar__toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    float: right;
    width: 16px;
    height: 18px;
    cursor: pointer;
    z-index: 1;
    margin-top: rem-calc(4);
}

.title-bar__toggle span {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    display: block;
    margin: 3px 0;
    height: 2px;
    background: #22222a;
}

.is-resizing .title-bar__toggle span {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

// .title-bar__toggle span:nth-child(3) {
//     margin-top: -5px;
// }

.title-bar--active .title-bar__toggle span {
    background: white;
}

.title-bar--active .title-bar__toggle span:nth-child(1) {
    -webkit-transform: scale(0) translateY(7px);
    -moz-transform: scale(0) translateY(7px);
    -ms-transform: scale(0) translateY(7px);
    -o-transform: scale(0) translateY(7px);
    transform: scale(0) translateY(7px);
}

.title-bar--active .title-bar__toggle span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: rem-calc(4);
}

.title-bar--active .title-bar__toggle span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: rem-calc(-5);
}

.title-bar--active .title-bar__toggle span:nth-child(4) {
    -webkit-transform: scale(0) translateY(-7px);
    -moz-transform: scale(0) translateY(-7px);
    -ms-transform: scale(0) translateY(-7px);
    -o-transform: scale(0) translateY(-7px);
    transform: scale(0) translateY(-7px);
}

.mobile-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    //top: 145px;
    right: 0;
    bottom: 0;
    z-index: 98;
    padding-top: 4em;
    padding-bottom: 2em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    -webkit-transform: translateY(-2em);
    transform: translateY(-2em);
    opacity: 0;
    display: none;
    transition: all 0.2s ease;
    font-size: rem-calc(16);
    dl {
        margin-bottom: 0;
    }
    &.show {
        opacity: 1;
        display: block;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            &.divider {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
            a {
                display: block;
                color: rgba(255, 255, 255, 0.8);
                transition: color 0.2s ease;
                padding: 0.7rem 1rem;
                font-weight: 300;
                &:hover {
                    color: white;
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
        & dd > a {
            padding: 0.4rem 1.2rem;
        }
    }
}

.ui-mask {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    position: fixed;
    display: none;
    transition: all 0.5s;
    &.show {
        display: block;
    }
}
