@keyframes item-hover {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    to {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
}

@keyframes item-hover1em {
    0% {
        -webkit-transform: translateY(1em);
        transform: translateY(1em);
    }

    to {
        -webkit-transform: translateY(-1em);
        transform: translateY(-1em);
    }
}

@keyframes item-hover2em {
    0% {
        -webkit-transform: translateY(-5em);
        transform: translateY(-5em);
    }

    to {
        -webkit-transform: translateY(5em);
        transform: translateY(5em);
    }
}

@keyframes item-hover3em {
    0% {
        -webkit-transform: translateY(-3em);
        transform: translateY(-3em);
    }

    to {
        -webkit-transform: translateY(3em);
        transform: translateY(3em);
    }
}


@keyframes piece-appear{
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes apple-pay-intro{
  0% {
    opacity: 0;
    transform: scale(0);
  }

  70%{
    opacity: 1;
    transform: scale(0.6);
  }

  100%{
    opacity: 0;
    transform: scale(1);
    visibility: hidden;
  }
}

@keyframes apple-pay-hand-intro{
  0% {
    opacity: 0;
    transform: translateY(155px);
    transform-origin: bottom;
  }

  to {
    opacity: 1;
    transform: translateY(125px);
  }
}

@keyframes apple-pay-copy-intro{
  0% {
    opacity: 0;
    transform: translateY(-20px);
    transform-origin: top left;
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


@keyframes apple-pay-hand-intro-small{
  0% {
    opacity: 0;
    transform: translateY(155px) translateX(2em);
    transform-origin: bottom;
  }

  50% {
    opacity: 1;
    transform: translateY(0em) translateX(2em);
  }

  70% {
    opacity: 1;
    transform: translateY(0em) translateX(2em);
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes apple-pay-copy-intro-small{
  0% {
    opacity: 0;
    transform: translateY(20em) translateX(2em);
    transform-origin: top left;
  }

  to {
    opacity: 1;
    transform: translateY(20em) translateX(0em);
  }
}

@keyframes dashboard-pic-intro{
  0% {
    opacity: 0;
    transform: translateY(12em) rotateX(45deg);
  }
  to {
    opacity: 1;
    transform: translateY(10em) rotateX(0deg);
  }
}

@keyframes starwars-opening{
  0%{
    transform: translateY(0em) translateX(0.5em);
  }
  to {
    transform: translateY(-25em) translateX(0.5em);
  }
}

@keyframes starwars-ending{
  0% {
    height: 15em;
  }
  to {
    height: 0;
  }
}

[class*="cubic-easeinout"] {
    -webkit-transition: -webkit-transform cubic-bezier(0.19, 1, 0.22, 1), opacity cubic-bezier(0.19, 1, 0.22, 1), background-color cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: -moz-transform cubic-bezier(0.19, 1, 0.22, 1), opacity cubic-bezier(0.19, 1, 0.22, 1), background-color cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: -moz-transform cubic-bezier(0.19, 1, 0.22, 1), opacity cubic-bezier(0.19, 1, 0.22, 1), background-color cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: -o-transform cubic-bezier(0.19, 1, 0.22, 1), opacity cubic-bezier(0.19, 1, 0.22, 1), background-color cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform cubic-bezier(0.19, 1, 0.22, 1), opacity cubic-bezier(0.19, 1, 0.22, 1), background-color cubic-bezier(0.19, 1, 0.22, 1);
}

[class*="--snap"] {
    -webkit-transition-duration: 0.75s, 0.75s, 0.75s;
    -moz-transition-duration: 0.75s, 0.75s, 0.75s;
    -ms-transition-duration: 0.75s, 0.75s, 0.75s;
    -o-transition-duration: 0.75s, 0.75s, 0.75s;
    transition-duration: 0.75s, 0.75s, 0.75s;
}

[class*="--fast"] {
    -webkit-transition-duration: 1s, 1s, 1s;
    -moz-transition-duration: 1s, 1s, 1s;
    -ms-transition-duration: 1s, 1s, 1s;
    -o-transition-duration: 1s, 1s, 1s;
    transition-duration: 1s, 1s, 1s;
}

[class*="--quick"] {
    -webkit-transition-duration: 1.35s, 1.35s, 1.35s;
    -moz-transition-duration: 1.35s, 1.35s, 1.35s;
    -ms-transition-duration: 1.35s, 1.35s, 1.35s;
    -o-transition-duration: 1.35s, 1.35s, 1.35s;
    transition-duration: 1.35s, 1.35s, 1.35s;
}

[class*="--medium"] {
    -webkit-transition-duration: 1.75s, 1.75s, 1.75s;
    -moz-transition-duration: 1.75s, 1.75s, 1.75s;
    -ms-transition-duration: 1.75s, 1.75s, 1.75s;
    -o-transition-duration: 1.75s, 1.75s, 1.75s;
    transition-duration: 1.75s, 1.75s, 1.75s;
}

[class*="--modal"] {
    -webkit-transition-duration: 1.5s, 0.35s, 0.35s;
    -moz-transition-duration: 1.5s, 0.35s, 0.35s;
    -ms-transition-duration: 1.5s, 0.35s, 0.35s;
    -o-transition-duration: 1.5s, 0.35s, 0.35s;
    transition-duration: 1.5s, 0.35s, 0.35s;
}

[class*="--slow-2500ms"] {
    -webkit-transition-duration: 2.5s, 2.5s, 2.5s;
    -moz-transition-duration: 2.5s, 2.5s, 2.5s;
    -ms-transition-duration: 2.5s, 2.5s, 2.5s;
    -o-transition-duration: 2.5s, 2.5s, 2.5s;
    transition-duration: 2.5s, 2.5s, 2.5s;
}

[class*="--slow-4s"] {
    -webkit-transition-duration: 4s, 4s, 4s;
    -moz-transition-duration: 4s, 4s, 4s;
    -ms-transition-duration: 4s, 4s, 4s;
    -o-transition-duration: 4s, 4s, 4s;
    transition-duration: 4s, 4s, 4s;
}

.delay-075s {
    -webkit-transition-delay: 0.075s;
    -moz-transition-delay: 0.075s;
    -ms-transition-delay: 0.075s;
    -o-transition-delay: 0.075s;
    transition-delay: 0.075s;
}

.button {
    -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}


.text-mask-reveal {
    transform: translate(0%,0%) translateZ(0) !important;
}

.text-mask-hidden-left {
    transform: translateX(-100%);
}


$width-cube: 40vw;
$z-val: $width-cube / 2;
$cube-side-duration: 3s;

.cube {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: auto;
  width: $width-cube;
  height: $width-cube;
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  &.bottom-right{
    top: auto;
    left: auto;
    bottom: 15%;
    right: -5%;
    .side {
      border: 2px solid rgba(255,255,255,0.1);
    }
  }

  .inner {
    position: relative;
    width: $width-cube;
    height: $width-cube;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
  }

  .side {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.05);
    border: 2px solid rgba(255,255,255,0.15);
    opacity: 0;
    &--1 {
      transform:
        translateZ($z-val);
      animation: cubepush--1 $cube-side-duration ease-in-out forwards;
    }
    &--2 {
      transform:
        rotateY(180deg)
        translateZ($z-val*2);
      animation: cubepush--2 $cube-side-duration ease-in-out forwards;
      animation-delay: 0.3s;
    }
    &--3 {
      transform:
        rotateY(90deg)
        translateZ($z-val);
      animation: cubepush--3 $cube-side-duration ease-in-out forwards;
      animation-delay: 0.6s;
    }
    &--4 {
      transform:
        rotateY(-90deg)
        translateZ($z-val);
      animation: cubepush--4 $cube-side-duration ease-in-out forwards;
      animation-delay: 0.9s;
    }
    &--5 {
      transform:
        rotateX(90deg)
        translateZ($z-val);
      animation: cubepush--5 $cube-side-duration ease-in-out forwards;
      animation-delay: 1.2s;
    }
    &--6 {
      transform:
        rotateX(-90deg)
        translateZ($z-val);
      animation: cubepush--6 $cube-side-duration ease-in-out forwards;
      animation-delay: 1.5s;
    }
  }


    &.nofill .side{
      background: none;
    }
}


.cube {
  transition: transform 15s;
  .inner {
    animation: rotate 20s linear infinite;
  }
  &.start {
    transform: scale(0.2);
    transition: transform 5s;
  }
  // &:hover{
  //   transform: scale(0.5);
  //   transition: transform 3s;
  // }
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

$popcube-factor: 8;

@mixin popCubeOutX($rotate, $z-val) {
  transform: rotateX($rotate) translateZ($z-val * $popcube-factor);
}

@mixin popCubeInX($rotate, $z-val) {
  transform: rotateX($rotate) translateZ($z-val);
}

@mixin popCubeOutY($rotate, $z-val) {
  transform: rotateY($rotate) translateZ($z-val * $popcube-factor);
}

@mixin popCubeInY($rotate, $z-val) {
  transform: rotateY($rotate) translateZ($z-val);
}

@keyframes cubepush--1 {
  0% {
    transform: translateZ($z-val * $popcube-factor);
  }
  100% {
    transform: translateZ($z-val);
    opacity: 1;
  }
}

@keyframes cubepush--2 {
  0% {
    @include popCubeOutY(0deg, $z-val);
  }
  100% {
    @include popCubeInY(180deg, $z-val);
    opacity: 0.9;
  }
}

@keyframes cubepush--3 {
  0% {
    @include popCubeOutY(0deg, $z-val);
  }
  100% {
    @include popCubeInY(90deg, $z-val);
    opacity: 0.8;
  }
}


@keyframes cubepush--4 {
  0% {
    @include popCubeOutY(0deg, $z-val);
  }
  100% {
    @include popCubeInY(-90deg, $z-val);
    opacity: 0.7;
  }
}

@keyframes cubepush--5 {
  0% {
    @include popCubeOutX(0deg, $z-val);
  }
  100% {
    @include popCubeInX(90deg, $z-val);
    opacity: 0.6;
  }
}

@keyframes cubepush--6 {
  0% {
    @include popCubeOutX(0deg, $z-val);
  }
  100% {
    @include popCubeInX(-90deg, $z-val);
    opacity: 0.5;
  }
}

@keyframes cmb-payment-select {
  0% {
    opacity:0;
    transform: scale(0);
  }

  20% {
    transform: scale(1);
    opacity:.6;
  }

  80% {
    transform: scale(1);
    opacity:.6;
  }

  100% {
    opacity:0;
  }

}

@keyframes cmb-payment-screen-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }

}

@keyframes cmb-payment-password {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  15% {
    opacity: 1;
    transform: translateY(0);
  }

  85%{
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    transform: translateY(5%);
    opacity: 0;
  }
}

@keyframes cmb-payment-pay {
  0% {
    background-color:#F66869;
  }

  100% {
    background-color: #e23233;
  }
}

@keyframes cmb-payment-result {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
