

.profit-sharing-item {
    margin-bottom: rem-calc(120);
    .title {
        text-align: center;
        font-size: rem-calc(34);
        font-weight: 500;
        img {
            height: rem-calc(20);
            margin: 0 rem-calc(10);
        }
        img:last-of-type {
            transform: rotate(180deg);
        }
    }
    .advantage-container-box {
        .advantage-outer-box {
            height: rem-calc(260);
            border-radius: 10px;
            background-color: #fff;
            padding-right: rem-calc(30);
            .advantage-box {
                padding: rem-calc(36);
                height: 100%;
                border-radius: 10px;
                border: 8px solid #f5f5f5;
                box-shadow: 0px 0px 5px 10px rgba(215, 223, 233, 0.1);
                h4 {
                    font-weight: 500;
                    font-size: rem-calc(26);
                    img {
                        width: rem-calc(50);
                    }
                }
            }
        }
        .advantage-outer-box:nth-child(3) {
            padding-right: rem-calc(0);
        }
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .profit-sharing-item {
        margin-bottom: rem-calc(0);
        .title {
           position: relative;
           font-size: rem-calc(18); 
           margin: rem-calc(10);
           img {
                position: absolute;
                top: rem-calc(7);
                height: rem-calc(10);
                margin: 0 rem-calc(3);
            }
            .img-left {
                left: rem-calc(50);
            }
            .img-right {
                right: rem-calc(50);
            }
        }
        .advantage-container-box {
            .advantage-outer-box {
                height: rem-calc(140);
                padding-right: rem-calc(0);
                .advantage-box { 
                    padding: rem-calc(20);
                    h4 {
                        font-size: rem-calc(12);
                        img {
                            width: rem-calc(24);
                        }
                    }
                    p {
                       font-size: rem-calc(10); 
                    }
                }
            }
        }
    }
}
