
.nav-padding{
  padding: 2rem 0;
}

.terms-content{
  padding: 5rem 0;

  h2{
    font-weight: 200;
  }

  h3{
    font-weight: 200;
    margin-top: 3rem;
  }

  h4{
    font-weight: 200;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  p{
    font-weight: 300;
  }
}