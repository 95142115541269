.hero-cross_border {
  background-image: url('../img/products/cross_border/big-bg.jpg') !important;
  height: 750px;
}

.cross_border.description {
  opacity: 0.7;
}

.cross_border.break-word {
  word-wrap: break-word;
}

.cross-border-container {
  .module-fir {
    margin-top: 80px;
    margin-bottom: 120px;
  }

  .module-fir-title {
    margin-bottom: 50px;
  }

  .module-sec {
    margin-bottom: 120px;
  }
}

.back-img {
  background-image: url(../img/products/cross_border/baoguanchanping.png);
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 130px;
  margin-top: -50px;
}

.cross_border {
  .cb-product {
    //padding-right: 24px;
  }

  .cb-product-crossborder-title {
    margin-bottom: 21px;
  }

  .cb-product-crossborder-content {
    width: 157px;
    height: 232px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(234, 239, 248, 1) 100%);
    border-radius: 0 116px 116px 0;

    .cb-product-crossborder-content-yinlian {
      margin-top: 46px;
    }

    .cb-product-crossborder-content-weixin {
      margin-top: 34px;
      margin-left: 45px;
    }

    .cb-product-crossborder-content-zhifubao {
      margin-top: 34px;
    }

    .cb-product-crossborder-content-visa {
      margin-top: 46px;
      margin-left: 20px;
    }

    .cb-product-crossborder-content-dc {
      margin-top: 34px;
      margin-left: -45px;
    }

    .cb-product-crossborder-content-paypal {
      margin-top: 34px;
      margin-left: 20px;
    }
  }

  .cb-product-box {
    position: relative;
    padding: 10px 0;
    cursor: pointer;
    border-radius: 3px;
    background-color: #f5f7f8;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

    .icon {
      font-size: 33px;
      color: #bbbbbb;
    }
    
    .icon-paypal {
      width: 33px;
      height: 33px;
      background: url(../img/products/cross_border/PayPal.svg) no-repeat;
    }

    &.active {
      background-color: #35c8e6;
      color: white;
      box-shadow: none;

      .icon {
        color: white;
      }

      .icon-paypal {
        background: url(../img/products/cross_border/PayPal_w.svg) no-repeat;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }


  .cb-product-box-r-1 {
    height: 311px;
    border-radius: 10px;

    .cb-alipay-info {
      border-radius: 10px;
    }
  }

  .cb-product-box-r-2 {
    height: 264px;
    border-radius: 10px;

    .cb-alipay-info {
      border-radius: 10px;
    }
  }

  .cb-alipay .icon {
    font-size: 30px;
  }

  .cb-alipay-info, .cb-wechat-info, .cb-paypal-info{
    > div {
      width: 100%;
    }
  }

  .cb-info {
    height: 100%;
    background-color: #f5f7f8;
    transition: all 200ms ease-in;


    img {
      height: 72px;
    }

    > div {

      p {
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .hero-cross_border {
    background-image: url('../img/products/cross_border/m-bg.jpg') !important;
    //height: 750px;
  }

  .cross_border {
    .icon-title-box-m {
      margin-bottom: 20px;
    }

    .icon-title-m {
      font-size: 20px;
    }

    .icon-alipay-m {
      font-size: 45px;
      color: #4cc8e6;
    }
    .icon-wechat-m {
      font-size: 45px;
      color: #84d37c;
    }
    .icon-paypal-m {
      width: 33px;
      height: 33px;
      background: url(../img/products/cross_border/paypal-blue.svg) no-repeat;
    }

    .cb-info-m {
      margin-top: 15px;
    }
  }
}

.pay-tip {
  font-size: 13px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(161, 161, 161, 1);
  line-height: 24px;
  word-wrap: break-word;
}

.appear {
  animation: appear 2s linear normal;
  animation-fill-mode:forwards;
}

.hid {
  animation: hid 2s linear normal;
  animation-fill-mode:forwards;
}

@keyframes appear {
  0% { display: none; }
  100% { display: block; }
}

@keyframes hid {
  0% { display: block; }
  100% { display: none; }
}