.hero-token {
  background-image: url('../img/products/token/token-bg.png') !important;
  height: 750px;
}

.product-header {
  h3 {
    font-size: 24px;
    margin-bottom: 48px;

    img {
      vertical-align: -9px !important;
      margin-right: 13px;
    }
  }

  .bate-icon {
    height: 24px;
    vertical-align: -3px !important;
  }

  h1 {
    font-size: 48px;
    margin-bottom: 32px;
  }

  p {
    opacity: .75;
    color: #fff;
  }

  .hero-box {
    margin-top: 205px;
  }
}

.product-token-container {
  color: #202020;

  .module-fir {
    margin: 80px 0 50px 0;
  }

  .module-sec {
    margin: 80px 0 160px 0;
  }

  .advantage-box {
    margin-top: 25px;

    .advantage-item {
      margin-bottom: 25px;

      img {
        margin-right: 10px;
        margin-top: -3px;
      }

      p {
        margin-top: 9px;
      }

      .advantage-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
      }
    }
  }

  .token-products {
    margin-top: 91px;
  }
}

.products-token.pricing-section__faq {
  border: none;
  padding-top: 0;
}

.products-token.bottom-cta {
  height: 372px;
}

@media screen and (max-width: 39.9375em) {
  .token-products {
    margin-top: 80px !important;
  }

  .product-token-container {
    .module-sec {
      margin: 20px 0 40px 0;

    }
  }
}