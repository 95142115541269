
.nav-padding{
  padding: 2rem 0;
}

.terms-content{
  padding: 5rem 0;

  h2{
    font-weight: 200;
  }

  h3{
    font-weight: 200;
    margin-top: 3rem;
  }

  h4{
    font-weight: 200;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  p{
    font-weight: 300;
  }
}
.my-table {  
  border: 1px solid black;  
  border-collapse: collapse; /* 这将消除单元格之间的间距 */  
}  
.my-table td {  
  border: 1px solid black; /* 这将给单元格也添加边框 */  
}
