@mixin gradient($size : 30%) {
  background-image: -webkit-gradient(linear, 0 0, $size 0, from(#44D89E), to(#35C8E6));
}

.theme-solicit {
  $baseColor: #3DD0C0;

  p {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 40px;
  }

  .hero {
    padding: 181px 0 0;
    position: relative;

    .bg-img {
      position: absolute;
      top: 107px;
      z-index: -1;

      &-mobile {
        position: absolute;
        z-index: -1;
        top: 19.2rem;
      }
    }

    h1, h2, h3, h4, p {
      text-align: left;
    }

    h1 {
      font-weight: 600;
      font-size: 48px;
      display: inline-block;
      @include gradient();
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      margin-bottom: 20px;

      span {
        font-weight: bold;
        color: $baseColor;
      }
    }

    .join {
      color: $baseColor;
      margin-top: 70px;
      font-weight: bold;
      cursor: pointer;
    }

    .belief {
      margin-top: 104px;
      padding-bottom: 64px;
      border-bottom: solid rgba(0, 0, 0, 0.08) 1px;

      > div {
        padding-right: 20px;

        &:last-child {
          padding-right: 0;
        }
      }

      .gradient {
        @include gradient(100%);
        height: 2px;
        margin-bottom: 20px;
      }

      &-title {
        color: $baseColor;
        margin-top: 20px;

        img {
          width: 40px;
          margin-right: 10px;
        }

        span {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &-desc {
        margin-top: 10px;
        opacity: .7;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  // ping ++ 的领先优势
  .superiority {
    padding-right: 13.33px;
    margin-bottom: 20px;

    &:nth-child(3n-1) {
      padding: 0 6.66px;
    }

    &:nth-child(3n) {
      padding-left: 13.33px;
      padding-right: 0;
    }

    &-box {
      border: solid rgba(0, 0, 0, 0.08) 1px;
      border-radius: 5px;
      // min-height: 162px;
      height: 162px;
      padding: 24px 32px 36px;
    }

    &:last-child {
      margin-right: 0;
    }

    &-title {
      margin-bottom: 10px;

      .color-box {
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 15px;
        line-height: 0;
        height: 16px;
        width: 5px;

        $radius: 5px;

        i {
          width: 5px;
          height: 8px;
          display: inline-block;
        }

        i:first-child {
          background-color: #44d89e;
          border-radius: $radius $radius 0 0;
        }

        i:last-child {
          background-color: #35c8e6;
          border-radius: 0 0 $radius $radius;
        }
      }

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }

    &-desc {
      margin-top: 10px;
      opacity: .7;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .cooperater {
    background-image: url('../img/solicit/cooperater-bg.png');
    background-size: cover;
    background-position: center;
    height: 559px;
    padding: 73px 0 88px 0;

    h2 {
      color: white;
      margin-bottom: 48px;
    }

    @mixin cooperater-gradient($bg, $color) {
      background: $bg;
      color: $color;
    }

    &-item {
      text-align: center;

      &:nth-child(1) > .cooperater-box {
        @include cooperater-gradient(#D9F5F8, #034B83);
      }

      &:nth-child(2) > .cooperater-box {
        @include cooperater-gradient(#DBFFF1, #0B593B);
      }

      &:nth-child(3) > .cooperater-box {
        @include cooperater-gradient(#DEEBF9, #234288);
      }

      &:nth-child(4) > .cooperater-box {
        @include cooperater-gradient(#DFFDFF, #11626B);
      }
    }

    &-sec-row {
      margin-top: -24px;
    }

    &-item-sec {
      text-align: center;

      &:nth-child(1) > .cooperater-box {
        @include cooperater-gradient(#F9F3F4, #823040);
      }

      &:nth-child(2) > .cooperater-box {
        @include cooperater-gradient(#FEE9DB, #A14A15);
      }

      &:nth-child(3) > .cooperater-box {
        @include cooperater-gradient(#FFFDF0, #886100);
      }
    }

    &-box {
      display: inline-table;
      height: 160px;
      width: 160px;
      font-size: 20px;
      line-height: 30px;
      border-radius: 10px;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
      background-image: linear-gradient(45deg, #FFFFFF 0%, rgba(255, 255, 255, 0.00) 100%);

      h4 {
        font-size: 20px;
        font-weight: 600;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }


  .partner {
    padding: 73px 0 88px 0;
    background-color: white;


    .partner-row .column {
      text-align: center;
      margin-bottom: 40px;
    }

    img {
      cursor: pointer;
    }
  }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
  .mobile-center {
    text-align: center !important;
  }

  .theme-solicit {
    h1.mobile {
      font-size: 30px;
      @include gradient(80%);
    }

    h2 {
      font-size: 22px !important;
      margin-bottom: 24px !important;
      font-weight: 400 !important;
    }

    .join {
      font-size: 18px !important;
      font-weight: 500 !important;
    }

    .belief > div {
      padding-right: 0 !important;
    }

    .superiority {
      padding-right: 0 !important;
      padding-left: 0 !important;

      &:last-child {
        margin-bottom: 40px;
      }
    }

    .cooperater {
      background-image: url('../img/solicit/cooperater-bg-mobile.png') !important;
      height: auto !important;

      &-box {
        margin-bottom: 10px !important;
      }

      &-sec-row {
        margin-top: 0px !important;
      }
    }

    .partner {
      padding: 40px 0;
    }

  }
}