$serif-font-family: 'PingFang-SC';

$radius: 16px;

// 案例也首页
.hero.hero-testimonial {
    padding-top: 6rem;
    padding-bottom: 0;

    .hero-copy {
        width: 415px;
        height: 415px;
        padding: 5rem 0rem;
        color: #202020;

        h1 {
            font-family: $serif-font-family;
            font-weight: 300;
            font-size: 48px;
            line-height: 1.5;
            text-align: right;
        }

        h4 {
            text-align: right;
            font-weight: 100;
            font-size: 17px;
        }
    }
}

.hero.hero-surface {
    padding-top: 6rem;
    padding-bottom: 0;

    .hero-copy {
        width: 415px;
        height: 415px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: $radius;
        box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.05);
        text-align: left;
        padding: 5rem 3.125rem;
        transform: translateY(4.375rem);

        h1 {
            font-family: $serif-font-family;
            font-weight: 400;
            text-align: left;
            color: $black;
            text-shadow: none;
        }
        h4 {
            text-align: left;
            font-weight: 300;
        }

        &--notransform {
            padding: 3rem 3.125rem 5rem;
            transform: none;
            color: #fff;
            background: none;
            border: none;
            box-shadow: none;
            h1,
            h4 {
                color: #fff;
            }
        }

        h1 {
            margin-bottom: 1.41rem;
        }

        h4 {
        }

        @include breakpoint(small only) {
            background: rgba(0, 0, 0, 0.5);
            color: white;
            width: auto;
            height: auto;
            transform: translateY(-1rem);
            h1,
            h4 {
                color: white;
                text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            }
        }
    }

    &.secondary {
        padding-top: 8rem;

        .hero-copy {
            width: 100%;
            height: 175px;
            padding: 1rem 1rem 0;
            text-align: center;
            h2 {
                font-family: $serif-font-family;
                color: rgba(0, 0, 0, 0.8);
                text-shadow: none;
                margin-bottom: 0.2rem;
            }
            h5 {
                color: #9e9e9e;
                text-shadow: none;
            }
            .breadcrumbs {
                display: flex;
                justify-content: center;
                li {
                    text-transform: none;
                }
                li:not(:last-child)::after {
                    font-family: 'icomoon' !important;
                    content: '\e909';
                    top: -1px;
                }
                a,
                span {
                    color: #aeaeae;
                    font-size: rem-calc(16);
                    font-weight: 300;
                }
            }
            @include breakpoint(small only) {
                height: 167px;
                background: white;
                transform: translateY(-2rem);
            }
        }

        @include breakpoint(small only) {
            padding-top: 9rem;
        }
    }
}

.hero-bg.testimonial {
    background-image: url('../img/testimonial-top.jpg');
    background-size: cover;
    background-position: center;

    &.jingku {
        background-image: linear-gradient(20deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customer-case-jingku.jpg');
    }

    &.motoband {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customer-case-motoband.jpg');
    }

    &.mileslife {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customer-case-mileslife.jpg');
    }

    &.czbapp {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customer-case-czbapp.jpg');
    }

    &.capitaland {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customer-case-capitaland.jpg');
    }

    &.cake21 {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0.45) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/home-cases-21cake.jpg');
    }

    &.xmonster {
        background-image: linear-gradient(20deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/home-cases-xmonster.jpeg');
    }

    &.jianshu {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customers/jianshu.png');
    }

    &.ngmm {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/home-cases-ngmm.jpg');
    }

    &.vipkid {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/home-cases-vipkid.jpeg');
    }

    &.xiaoluyy {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customers/xiaoluyy.jpg');
    }

    &.mobike {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customers/mobike.jpg');
    }

    &.wegene {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customers/wegene.jpg');
    }

    &.dingxiang {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0.45) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customers/dingxiang.jpg');
    }

    &.leoao {
        background-image: linear-gradient(37deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.45) 100%), url('../img/customers/leoao.jpg');
    }
}

@media screen and (max-width: 39.9375em) {
    .hero-bg.testimonial {
        background-image: url('../img/testimonial-top-m.png');
    }

    .testimonial-top-desc {
        margin-top: 70px;
        text-align: left !important;
    }

    .testimonial-top-title {
        text-align: left !important;
    }
}

.testimonial-categories {
    padding-top: 7em;
    text-align: center;
    @include breakpoint(small only) {
        padding-top: 2em;
    }
    .tab {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.05);
    }
    h5 {
        font-size: rem-calc(14);
        font-weight: 400;
    }
}

.testimonial-categories--new {
    transform: translateY(-2rem);
    background: #ffffff;
    border-radius: $radius;
    box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.05);
    text-align: center;

    margin-bottom: 3rem;

    .tabs {
        border-radius: $radius;
        border: none;
    }

    .tabs-title {
        width: 50%;

        &:first-child a {
            border-radius: $radius 0 0 0;
        }

        &:last-child a {
            border-radius: 0 $radius 0 0;
        }

        & > a {
            font-size: rem-calc(20);
            font-weight: 200;
            background-color: #fefefe;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: 1.8rem 1.5rem;
            box-shadow: inset 5px -5px 25px 0px rgba(0, 0, 0, 0.05);
            &#panel2-label {
                border-left: none;
            }
            @include breakpoint(small only) {
                padding: 1rem;
                font-size: rem-calc(16);
            }
        }
        &.is-active a {
            background-color: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: none;
        }
    }

    .tabs-content {
        border-radius: 0 0 $radius $radius;
        border: none;
    }

    .tabs-panel {
        padding-top: 2rem;
        transition: all 0.35 ease-in;

        a {
            color: currentColor;

            &.active {
                color: $anchor-color;
            }
        }
    }

    h5 {
        font-size: rem-calc(14);
        font-weight: 400;
    }
    @include breakpoint(small only) {
        .testimonial-categories__icon {
            display: none;
        }
    }

    .all.active {
        &.active {
            .testimonial-categories__icon {
                background-image: url('../img/testimonial-icon-all1.svg');
            }
        }
    }

    .social.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-social1.svg');
        }
    }

    .shopping.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-shopping1.svg');
        }
    }

    .platform.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-platform1.svg');
        }
    }

    .edu.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-edu1.svg');
        }
    }

    .health.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-health1.svg');
        }
    }

    .estate.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-estate1.svg');
        }
    }

    .travel.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-travel1.svg');
        }
    }

    .products.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-products1.svg');
        }
    }

    .pxxplatform.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-pxxplatform1.svg');
        }
    }

    .account.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-account1.svg');
        }
    }

    .foreign.active {
        .testimonial-categories__icon {
            background-image: url('../img/testimonial-icon-foreign1.svg');
        }
    }
}

.testimonial-categories__icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 1em;
    background-position: center;
    background-repeat: no-repeat;
    &.all {
        background-image: url('../img/testimonial-icon-all.svg');
    }
    &.travel {
        background-image: url('../img/testimonial-icon-travel.svg');
    }
    &.estate {
        background-image: url('../img/testimonial-icon-estate.svg');
    }
    &.health {
        background-image: url('../img/testimonial-icon-health.svg');
    }
    &.social {
        background-image: url('../img/testimonial-icon-social.svg');
    }
    &.edu {
        background-image: url('../img/testimonial-icon-edu.svg');
    }
    &.platform {
        background-image: url('../img/testimonial-icon-platform.svg');
    }
    &.shopping {
        background-image: url('../img/testimonial-icon-shopping.svg');
    }

    &.products {
        background-image: url('../img/testimonial-icon-products.svg');
    }
    &.pxxplatform {
        background-image: url('../img/testimonial-icon-pxxplatform.svg');
    }
    &.account {
        background-image: url('../img/testimonial-icon-account.svg');
    }
    &.foreign {
        background-image: url('../img/testimonial-icon-foreign.svg');
    }
}

.testimonial-card {
    position: relative;
    width: 97%;
    padding-bottom: 7rem;
    margin-bottom: 3rem;
    //border-bottom: 1px solid #ebebeb;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.05);

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    //border-bottom: 3rem solid transparent;

    border-radius: $radius;
    overflow: hidden;
    display: block;
    transition: box-shadow 0.2s ease, opacity 0.2s ease;

    &:hover {
        box-shadow: 0 10px 50px hsla(200, 20, 50, 0.15);
        .testimonial-card__text {
            transform: translateY(3.5rem);
            background: $white;
        }
        .testimonial__fullbleed {
        }
    }

    &:last-child {
        border-bottom: none;
    }

    @include breakpoint(small only) {
        width: 100%;
        left: 0 !important;
    }
    .testimonial__fullbleed {
        position: absolute;
        width: 100%;
        transition: height 0.3s ease;
    }
}

.testimonial-card__text {
    position: relative;
    padding: 40px 45px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: $radius;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
    transform: translateY(4.5rem);
    transition: all 0.2s ease;
    a {
        position: absolute;
        right: 45px;
        bottom: 2.3rem;
    }
    @include breakpoint(small only) {
        margin: 0 auto;
        padding: 28px 35px;
        hr {
            margin: 0 0 1rem;
        }
        a {
            right: 18px;
            bottom: 28px;
            font-size: rem-calc(14);
        }
    }
}

p.quote {
    font-family: $serif-font-family;
    font-size: rem-calc(18);
    line-height: 1.78;
    color: #616161;
    letter-spacing: 0.05rem;
    font-weight: 300;
    min-height: 128px;
}

.testimonial-section {
    padding: 5rem 0 0;
    @include breakpoint(small only) {
        padding: 3rem 0 0;
    }
    &.secondary {
        padding: 10rem 0 10rem;

        @include breakpoint(small only) {
            padding-top: 4rem;
            padding-bottom: 6rem;
            h2 {
                text-align: center;
            }
        }
    }
}

.testimonial-section__head {
    .company-info {
        @include breakpoint(small only) {
            margin-bottom: 1em;
        }
        h2 {
            margin-bottom: 0.1em;
        }
        h5 {
            color: #9e9e9e;
            font-size: rem-calc(18);
        }
    }
    @include breakpoint(small only) {
        h2,
        h5 {
            text-align: center;
        }
        h2 {
            margin-bottom: 1em;
            .punctuation {
                letter-spacing: -0.8em;
            }
        }
    }
}

.testimonial-section__body {
    font-weight: 300;

    p {
        line-height: 2;
        text-align: justify;
        word-break: normal;
        word-wrap: break-word;
    }

    dl {
        margin-bottom: 22px;
        @include breakpoint(small only) {
            text-align: center;
            margin-top: 2em;
        }
        dt {
            color: #9e9e9e;
            font-size: rem-calc(13);
            font-weight: 400;
        }
        dd {
            font-size: rem-calc(18);
            font-weight: 300;
            margin-bottom: 0.2em;
        }
    }
}

.testimonial__company-description {
    font-size: rem-calc(13);
    color: #9e9e9e;
    letter-spacing: 0px;
    line-height: 1.41;
    padding-bottom: 3rem;
    padding-right: 2rem;
    text-align: justify;

    &.ngmm {
        padding-bottom: 1.3rem;
        @include breakpoint(small only) {
            padding-bottom: 3rem;
        }
    }
    &.capitaland {
        padding-bottom: 0em;
        @include breakpoint(small only) {
            padding-bottom: 3rem;
        }
    }
    &:before {
        content: ' ';
        width: 30px;
        height: 1px;
        background: #e0e0e0;
        display: block;
        margin-bottom: 2em;
    }
    @include breakpoint(small only) {
        text-align: center;
        padding-left: 2rem;
        padding-top: 2rem;
        &:before {
            margin: 0 auto;
            margin-bottom: 2em;
        }
    }
}

.testimonial__quote-card {
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: $radius;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
    padding: 40px 45px;
    transform: translateY(5.5em);
    margin-top: -4em;
    backdrop-filter: blur(10px);

    &.secondary {
        &.jingku {
            transform: translateY(6.1em);
            @include breakpoint(small only) {
                transform: translateY(2em);
            }
        }
        &.czbapp {
            transform: translateY(6em);
            @include breakpoint(small only) {
                transform: translateY(2em);
            }
        }

        @include breakpoint(small only) {
            transform: translateY(2em);
        }
    }

    p {
        font-family: $serif-font-family;
        font-size: rem-calc(18);
        line-height: 1.78;
        color: #616161;
        letter-spacing: 0.05rem;
        font-weight: 300;
    }
}

.testimonial__fullbleed {
    height: 262px;
    background-size: cover;
    background-position: center;
    &.jingku {
        background-image: url('../img/customer-case-jingku.jpg');
    }
    &.motoband {
        background-image: url('../img/customer-case-motoband.jpg');
    }
    &.czbapp {
        background-image: url('../img/customer-case-czbapp.jpg');
    }
    &.mileslife {
        background-image: url('../img/customer-case-mileslife.jpg');
    }
    &.capitaland {
        background-image: url('../img/customer-case-capitaland.jpg');
    }
    &.xmonster {
        background-image: url('../img/home-cases-xmonster.jpeg');
    }
    &.cake21 {
        background-image: url('../img/home-cases-21cake.jpg');
    }
    &.jianshu {
        background-image: url('../img/customers/jianshu.png');
    }
    &.vipkid {
        background-image: url('../img/home-cases-vipkid.jpeg');
    }
    &.ngmm {
        background-image: url('../img/home-cases-ngmm.jpg');
    }
    &.xiaoluyy {
        background-image: url('../img/customers/xiaoluyy.jpg');
    }
    &.mobike {
        background-image: url('../img/customers/mobike.jpg');
    }

    &.wegene {
        background-image: url('../img/customers/wegene.jpg');
    }

    &.dingxiang {
        background-image: url('../img/customers/dingxiang.jpg');
    }

    &.leoao {
        background-image: url('../img/customers/leoao.jpg');
    }
}

.testimonial-logo {
    height: 33px;
    width: 100%;
    background-repeat: no-repeat;
    border-right: none;

    display: inline-block;
    background-position: left;
    margin-bottom: -12px;
    margin-right: 15px;

    &.xmonster {
        background-size: 100px auto;
        background-image: url('../img/testimonial-logo-xmonster@2x.png');
        @include breakpoint(small only) {
            background-size: 84px 15px;
        }
    }
    &.jianshu {
        background-size: 58px 27px;
        background-image: url('../img/customers/jianshu-logo@2x.png');
    }
    &.vipkid {
        background-size: 80px auto;
        background-image: url('../img/testimonial-logo-vipkid@2x.png');
    }
    &.cake21 {
        background-size: 71px 25px;
        background-image: url('../img/testimonial-logo-21cake@2x.png');
    }
    &.ngmm {
        background-size: 71px 22px;
        background-image: url('../img/testimonial-logo-ngmm@2x.png');
    }
    &.jingku {
        background-size: 70px auto;
        background-image: url('../img/testimonial-logo-jingku@2x.png');
    }
    &.mileslife {
        background-size: 100px auto;
        background-image: url('../img/testimonial-logo-mileslife@2x.png');
    }
    &.motoband {
        background-size: 75px 18px;
        background-image: url('../img/testimonial-logo-motoband@2x.png');
    }
    &.czbapp {
        background-size: 80px auto;
        background-image: url('../img/testimonial-logo-czbapp@2x.png');
    }
    &.capitaland {
        background-size: 75px auto;
        background-image: url('../img/testimonial-logo-capitaland@2x.png');
    }
    &.xiaoluyy {
        background-size: 60px 33px;
        background-image: url('../img/customers/xiaoluyy-logo@2x.png');
    }

    &.mobike {
        background-size: 100px auto;
        background-image: url('../img/customers/mobike-logo@2x.png');
    }

    &.wegene {
        background-size: 100px auto;
        background-image: url('../img/customers/wegene-logo@2x.png');
    }

    &.dingxiang {
        background-size: 100px auto;
        background-image: url('../img/customers/dingxiang-logo@2x.png');
    }

    &.leoao {
        background-size: 100px auto;
        background-image: url('../img/customers/leoao-logo@2x.png');
    }
}
