// 页面底部客户案例样式
.customer-case {
    width: 100%;
    height: rem-calc(520);
    position: relative;
    overflow: hidden;
    padding-bottom: rem-calc(100);

    .case-container {
        margin: 0;
        position: absolute;
        left: 0;
        width: rem-calc(340 * 27);
        transition: all 0.2s ease-in-out;

        .case-item {
            list-style: none;
            float: left;
            width: rem-calc(320);
            height: rem-calc(400);
            border-radius: 8px;
            padding: 8px;
            box-sizing: border-box;
            margin: 0 rem-calc(10);
            box-shadow: 0 0 rem-calc(5) rgb(224, 227, 234);
            background: #dddddd44;
            &:hover {
                box-shadow: 0 rem-calc(20) rem-calc(20) 0 rgba(231, 233, 238, 1);
            }

            div {
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 8px;
            }

            .case-img {
                width: 100%;
                height: rem-calc(226);
            }

            .content {
                font-size: rem-calc(14);
                color: #252525;
                line-height: rem-calc(25);
                padding: rem-calc(25) rem-calc(15) rem-calc(25);
                margin: 0;
                text-align: left;
                height: rem-calc(105);
            }

            .case-icon {
                width: rem-calc(118);
                height: rem-calc(42);
            }
        }
    }

    .control {
        margin-top: rem-calc(40);
        position: absolute;
        bottom: rem-calc(40);
        left: 50%;
        transform: translateX(-50%);
        div {
            float: left;
            width: rem-calc(50);
            height: rem-calc(50);
            cursor: pointer;
            transition: all 0.2s;
            &:first-of-type {
                margin-right: rem-calc(15);
            }
            &.active {
                transform: scale(1.14);
                &:hover {
                    opacity: 0.9;
                }
            }
        }
        .prev {
            background: url(../img/newhome/prev.png) no-repeat center/ 100%;
            &.active {
                background: url(../img/newhome/prev_active.png) no-repeat center/ 100%;
            }
        }
        .next {
            background: url(../img/newhome/next.png) no-repeat center/ 100%;
            &.active {
                background: url(../img/newhome/next_active.png) no-repeat center/ 100%;
            }
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .customer-case {
        width: 100%;
        height: auto;
        position: relative;
        overflow: auto;
        padding-bottom: rem-calc(40);

        .case-container {
            margin: 0;
            position: static;
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
            max-width: 100% !important;
            margin-left: auto !important;
            margin-right: auto !important;

            .case-item {
                list-style: none;
                float: none;
                width: 100%;
                height: rem-calc(360);
                border-radius: 4px;
                margin: 0 0 rem-calc(20);

                div {
                    width: 100%;
                    height: rem-calc(360);
                }

                .case-img {
                    width: 100%;
                    height: auto;
                }

                .content {
                    font-size: rem-calc(14);
                    font-weight: 500;
                    color: #252525;
                    line-height: rem-calc(20);
                    padding: rem-calc(20) rem-calc(15) rem-calc(10);
                    margin: 0;
                    text-align: left;
                    height: rem-calc(60);
                }
            }
        }
    }
}
