.scenes {
  .scenes-header {
    margin-top: 180px;
    padding-bottom: 85px;

    .scenes-title {
      padding-right: 40px;
      position: relative;

      img {
        position: absolute;
        z-index: -1;
        left: 25px;
      }

      h1 {
        margin-top: 15px;
      }
    }
  }


  .scenes-desc {
    opacity: 0.7;
  }

  .scenes-box-desc {
    @extend .scenes-desc;
    font-size: 14px;
    margin-top: 16px;
    width: 100px;
  }

  .auto-width {
    width: auto;
  }

  .scenes-list {
    padding: 56px 0;
    background-color: #f5f7f8;

    .scenes-list-box > div {
      margin-bottom: 24px;
    }

    .scenes-box {
      width: 100px;
      height: 100px;
      line-height: 100px;
      cursor: pointer;
      background-color: #fff;
      box-shadow: 0 0 12px 0 rgba(210, 215, 216, 0.31);
      border-radius: 20px;

      transition: all 200ms ease-in;

      &:hover {
        box-shadow: 0 0 12px 0 rgba(58, 204, 200, 0.7);
      }
    }
  }


  .module-sec {
    padding: 80px 0;
  }

  .scenes-client {
    margin-top: 70px;
    padding: 50px 80px;
    border-radius: 17px;
    box-shadow: 0 0 19px 1px #e1e8eb;

    .client-logo {
      position: relative;

      img {
        position: relative;
        top: -65px;
        z-index: 1;
        height: 164px;
      }

      img.right {
        right: 0;
      }
    }


    p {
      font-size: 14px;
      margin-bottom: 0;
    }


    .driver-line {
      width: 20%;
      margin: 24px 0;
    }

    .used-channel a {
      margin-right: 10px;
    }
  }

  .hide-for-small-only.client-logo {
    flex: 0 0 auto;
  }


  .scenes-dev-links {
    padding: 108px 0 164px;
  }


  .qr-code-scenes-box {
    height: 181px;
    font-size: 14px;
    padding: 20px 15px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(210, 215, 216, 0.31);

    span {
      opacity: .7;
    }
  }

  .scenes-qr-box {
    cursor: pointer;
  }

  .qr-code-scenes-box-title {
    margin-bottom: 10px;
    margin-left: 20px;
    opacity: .7;
  }

}


@media screen and (max-width: 39.9375em) {
  .scenes {
    &-header {
      margin-top: 140px !important;
      padding-bottom: 100px !important;
    }

    h1 {
      text-align: left;
    }

    .scenes-desc-title {
      line-height: 1.8;
      font-size: 18px;
    }

    .scenes-client {
      padding: 50px 20px;
    }

    .pricing-section__faq {
      border-top: none;
    }
  }
}

