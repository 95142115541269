@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'common';

@import 'components/typography';
@import 'components/animations';
@import 'components/buttons';
@import 'components/navigation';
@import 'components/accordion';
@import 'components/title-bar';
@import 'components/uielements';
@import 'components/fullscreen';
@import 'components/footer';
@import 'components/landing';
@import 'components/hero';
@import 'components/styleguide';
@import 'components/themes';
@import 'components/highlight';
@import 'components/stickers';
@import 'components/bottom_contact_us'; // 新版页面底部联系我们
@import 'components/case'; // 页面底部客户案例
@import 'components/info';

@import 'pages/home';
// @import 'pages/index'; 旧版首页
@import 'pages/index_new';
@import 'pages/products';
@import 'pages/contact';
@import 'pages/countdown';
@import 'pages/applepay';
//@import "pages/priceplan";
// @import 'pages/pricing';  旧版定价页面
@import 'pages/dashboard';
@import 'pages/docs';
@import 'pages/testimonial';
@import 'pages/terms';
@import 'pages/privacy';
@import 'pages/press';
@import 'pages/cmbwallet';
@import 'pages/career';
@import 'pages/about';
@import 'pages/new-products';
@import 'pages/account';
@import 'pages/platform';
@import 'pages/product_token';
@import 'pages/cross_border';
@import 'pages/micro_finance';
@import 'pages/article';

// @import 'pages/consult';旧版咨询

@import 'pages/solicit';
@import 'pages/pricing';

@import 'pages/product_token';

@import 'pages/solicit';

// 场景页面
@import 'pages/scenes';

// 百科页面
@import 'pages/wiki';

@import 'components/localization';

// 新的解决方案页
@import 'pages/solution-fund';
@import 'pages/solution-distribution';
@import 'pages/profit_sharing';
@import 'pages/e_commerce';
@import 'pages/sol_b2b';
@import 'pages/industrial_internet';

// 下载页面
@import 'pages/download';

// 推荐大使
@import 'pages/recommend';

// 客户案例
@import 'pages/case';

// 资讯动态
@import 'pages/information';
@import 'pages/consult_new';

@import 'pages/link';

@import 'pages/saas';

//Remove Gray Highlight When Tapping Links in Mobile
* {
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: text;
}

html,
body {
    height: 100%;
}
