
  .hero.hero-cmbwallet {
    background-image: url('../img/cmb-bg-rainbow.png'), $gradient-red-orange;
    background-repeat: no-repeat;
    background-position: 27% 110%;
    color: $white;
    @include breakpoint (small only){
      h1{
        text-shadow: 0px 0px 15px HSL(356, 67%, 37%);
      }
      .hero-copy{
        padding-top: 0em;
      }
    };

    .cmb-logo {
      width: 180px;
      height:181px;
      margin: 1rem auto;
      background-image: url("../img/cmb-logo.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
    }

    .cmb-logo:before {
      content: '';
      position: absolute;
      top:-56px;
      left: -16px;
      width: 217px;
      height: 289px;
      background-image: url("../img/cmb-logo-points.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .hero-bg #front-rainbow{
      width: 100%;
      bottom: 0;
      position: absolute;
    }
  }

  .cmb-feature {
    position: relative;
  }


  .cmb-feature__bg {
    padding-top: 4rem;
    height:429px;
  }

  .cmb-feature__bg--building {
    width: 682px;
    height: 365px;
    background-image: url(../img/cmb-bg-building.svg);
    background-size: cover;
  }

  .cmb-feature__o2o--text {
    h5 {
      @include breakpoint(small only) {
        margin-top: 1.25rem;
      }
    }
  }

  .devider {
    width: 80%;
    max-width: 168px;
    hr{
      margin: 2em 0;
    }
  }

  .ld-content__body--text {
    @include breakpoint(small only) {
      margin-top: 1.25rem;
      padding-top: 1.25rem;
      border-top: 1px solid #EBEBEB;
    }
    h5 {
      @include breakpoint(small only) {
        margin-top: 1.25rem;
      }
    }
  }
  .cmb-feature__items {
    list-style:none;
    margin: 0;
    padding: 0;
    color: $gray-9;
    @include breakpoint(small only ) {
      font-size: rem-calc(14);
    }
  }

  .cmb-wallet-pay {
    position: relative;
    min-width: 240px;
    height: 469px;
    overflow: hidden;
    padding-top: 2rem;
    padding-left: 2rem;
    p {
      font-size: rem-calc(10);
      color: #5D5959;
      margin-bottom: 0.2rem;
    }
    h3 {
      font-size: rem-calc(20);
      margin-bottom: 0.5rem;
    }
  }

  .cmb-wallet-pay__device {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:303px;
    height:405px;
    transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);

    .button {
      position: absolute;
      bottom: 0;
      left:0;
      display: block;
      width:100%;
      margin: 0;
      border:0;
      border-radius: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      font-size: rem-calc(13);
      font-weight: 400;
    }


    &.animated {
      #step1 {
        z-index: 90;
        animation: cmb-payment-select .5s forwards ease;
        cursor: default;
      }
      #overlay{
        z-index: 93;
        animation: cmb-payment-screen-overlay 0.5s forwards ease;
        animation-delay: .6s;
      }
      #step2 {
        z-index: 95;
        animation: cmb-payment-password 2s forwards ease-in;
        animation-delay: 1.1s;
      }
      #pay {
        z-index: 97;
        animation: cmb-payment-pay .3s forwards ease-in;
        animation-delay: 2s;
      }
      #step3 {
        z-index: 99;
        animation: cmb-payment-result .8s forwards ease;
        animation-delay: 3.2s;
      }
    }
  }

  .cmb-wallet-pay__screen {
    position: relative;
    width: 166px;
    height: 294px;
    border: 1px solid rgba(0,0,0,.3);
    background-image: url(../img/cmb-applepay-screen@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cmb-wallet-pay__selection {
    position: relative;
    bottom: 87px;
    left: 0px;
    width: 166px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .cmb-wallet-pay__overlay {
    position: absolute;
    top:0;
    width: 166px;
    height: 294px;
    background-color: #868686;
    opacity: 0;
    transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: -1;
  }


  .cmb-wallet-pay__finish {
    position: absolute;
    top: 64px;
    left: 7px;
    width: 152px;
    height: 168px;
    opacity: 0;
    background-color: #fff;
    padding-top:8px;
    text-align: center;
    border-radius: 3px;
    z-index: -1;
    transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);
    .button {
      background-color: #fff;
      color:rgba(0,0,0,.8);
      border-top: 1px solid #e0e0e0;
      &:hover {
        color:rgba(0,0,0,.4);
      }
    }
  }
  .cmb-wallet-pay__finish--icon {
    position: relative;
    margin: .5rem auto;
    width: 40px;
    height: 40px;
    border: 2px solid #34B3E0;
    border-radius: 50%;
    &:before {
      content:"";
      position: absolute;
      left:5px;
      bottom:28%;
      width:12px;
      height:2px;
      background-color:#34B3E0;
      transform-origin: right;
      transform: rotate(40deg);
    }
    &:after {
      content:"";
      position: absolute;
      right:1px;
      bottom:28%;
      width:18px;
      height:2px;
      background-color:#34B3E0;
      transform-origin: left;
      transform: rotate(-40deg);
    }
  }
  .cmb-wallet-pay__finish--text {
    color:#34B3E0;
    font-size: rem-calc(14);
  }

  .cmb-wallet-pay__charge {
    position: absolute;;
    top: 64px;
    left: 7px;
    width: 152px;
    height: 168px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    background-color: #fff;
    text-align: center;
    padding:6px;
    z-index: -1;

    h3.price {
      color:#ef5350;
    }
    .input-pwd {
      position: relative;
      width: 95%;
      margin: 0 auto;
      overflow: hidden;
    }

    .input-pwd__cell{
      float:left;
      margin: 0;
      width:16.6%;
      height:26px;
      border:1px solid #CECED2;
      border-right: none;
      position: relative;
      &:before {
        content:"";
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-3px,-3px);
        width:6px;
        height:6px;
        border-radius: 50%;
        background-color: #D8D8D8;
      }
      &:last-child {
        border:1px solid #CECED2;
      }
    }

    #bank {
      width: 100%;
      margin:0.5rem;
      position: relative;
      &:before{
        content:"";
        position: absolute;
        top:2px;
        left:-4px;
        width:14px;
        height:14px;
        background-image: url("../img/ccb_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    hr{
      margin: 0.2rem 0;
    }

  }

  .cmb-intro.ld-content {
    background-image: url('../img/cmb-bg-intro.svg');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
  }

  .cmb-activity.ld-content {
    background-color: #FFF9FB;
    h2.main-title, a:not(.cta) {
      color:#F66869;
    }
  }
