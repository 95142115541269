.text-left {
    h1,
    h2,
    h3,
    h4,
    p {
        width: 100%;
        text-align: left;
    }
}

.solution-header-box {
    height: rem-calc(320);
    margin-top: rem-calc(55);
    padding-top: rem-calc(35);
    position: relative;
    z-index: 2;

    &::before {
        content: ' ';
        width: 100%;
        height: rem-calc(320);
        position: absolute;
        background: linear-gradient(to left top, transparent 50%, #244fbd 50%);
        top: 0;
        left: 0;
        z-index: -1;
    }

    &::after {
        content: ' ';
        width: 100%;
        height: rem-calc(320);
        background: linear-gradient(to right bottom, transparent 50%, #1b47b7 50%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    h1 {
        font-size: rem-calc(34);
        font-weight: 600;
        color: #ffffff;
        line-height: rem-calc(48);
    }

    h2 {
        font-size: rem-calc(20);
        color: #ffffff;
        line-height: rem-calc(36);
        padding-right: rem-calc(100);
    }
    .header-img-box {
        justify-content: flex-end;
    }
    img {
        height: rem-calc(277);
        width: auto;
    }
}

.solution-container {
    padding: 0 0 rem-calc(120);

    .title {
        position: relative;
        padding-left: rem-calc(10);
        line-height: 1;
        color: #000000;
        font-size: rem-calc(28);
        font-weight: 600;
        padding-left: rem-calc(16);
        margin: rem-calc(80 0 35);
        &::before {
            content: ' ';
            width: rem-calc(8);
            height: rem-calc(26);
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: rem-calc(-13);
            background-color: #23b3bc;
        }
    }
}

.e-commerce-container {
    .pain-outer-box {
        position: relative;

        &:first-child {
            padding: rem-calc(20) rem-calc(80) 0 rem-calc(10);
            .pain-box {
                img {
                    width: rem-calc(95) !important;
                    height: rem-calc(92) !important;
                }
            }
        }

        &:last-child {
            padding: rem-calc(20) 0 0 rem-calc(90);
            .pain-box {
                img {
                    width: rem-calc(102) !important;
                    height: rem-calc(95) !important;
                }
            }
        }

        &:first-child::before {
            content: '1';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            position: absolute;
            background: transparent;
            top: 0;
            left: 0;
            z-index: 2;
        }

        &:first-child::after {
            content: ' ';
            width: 0;
            height: 0;
            border-top: 48px solid #3666de;
            border-right: 48px solid #3361d6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        &:last-child::before {
            content: '2';
            width: rem-calc(48);
            height: rem-calc(48);
            font-size: rem-calc(32);
            font-weight: bold;
            color: #ffffff;
            line-height: rem-calc(48);
            text-align: center;
            position: absolute;
            background: transparent;
            top: 0;
            left: rem-calc(80);
            z-index: 2;
        }

        &:last-child::after {
            content: ' ';
            width: 0;
            height: 0;
            border-top: 48px solid #3666de;
            border-right: 48px solid #3361d6;
            position: absolute;
            top: 0;
            left: rem-calc(80px);
            z-index: 1;
        }

        .pain-box {
            height: rem-calc(162);
            color: #252525;
            background: #f3f6ff;
            border: 1px solid #eef1ff;
            padding: rem-calc(20) rem-calc(126) 0 rem-calc(54);
            position: relative;

            h4 {
                font-size: rem-calc(24);
                font-weight: 600;
                color: #252525;
                line-height: rem-calc(33);
            }

            p {
                font-size: rem-calc(16);
                font-weight: 400;
                color: #252525;
                line-height: rem-calc(24);
            }

            img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: rem-calc(102);
                height: rem-calc(107);
                z-index: 1;
            }
        }
    }

    .service-box {
        height: rem-calc(200);

        &:nth-child(3n + 1) {
            padding-right: rem-calc(40);
        }

        &:nth-child(3n + 2) {
            padding: 0 rem-calc(20);
        }

        &:nth-child(3n) {
            padding-left: rem-calc(40);
        }

        img {
            width: rem-calc(60);
            height: rem-calc(56);
        }

        h4 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #000000;
            line-height: rem-calc(33);
            margin: rem-calc(10) 0;
        }

        p {
            font-size: rem-calc(16);
            font-weight: 400;
            color: #252525;
            line-height: rem-calc(22);
        }
    }

    .advantage {
        background: #fbfcfd;
        box-shadow: 0px rem-calc(23) rem-calc(30) 0 #e4e9ef;
        border-radius: rem-calc(16);
        box-sizing: border-box;
        padding: rem-calc(40) 0 rem-calc(20);

        .advantage-outer-box {
            padding: 0 rem-calc(20);
            position: relative;
            margin-top: rem-calc(35);

            .advantage-img-box {
                position: absolute;
                top: 50%;
                right: rem-calc(33);
                text-align: center;
                .advantage-img {
                    -ms-transform: translateY(-74%);
                    transform: translateY(-74%);
                    height: 133px;
                    z-index: -1;
                }
            }
            .advantage-box {
                position: relative;
                padding: rem-calc(30) rem-calc(20) rem-calc(30);
                z-index: 10;

                h4 {
                    font-size: rem-calc(24);
                    font-weight: 600;
                    color: #252525;
                    line-height: rem-calc(36);
                    margin: 0;
                }
                p {
                    font-size: rem-calc(14);
                    font-weight: 400;
                    color: #202020;
                    line-height: rem-calc(21);
                    margin: 0;
                }
            }
        }
    }
}

.get-e-commerce {
    background-image: linear-gradient(0deg, #436d87, #4d4561);

    .contact-us {
        color: #202020;
    }
}

// 适应移动端
@media screen and (max-width: 39.9375em) {
    .solution-header-box {
        height: rem-calc(398);
        padding-top: rem-calc(70);
        margin-top: 0;
        &::before {
            content: ' ';
            width: 100%;
            height: rem-calc(398);
            position: absolute;
            background: linear-gradient(to left top, transparent 50%, #244fbd 50%);
            top: 0;
            left: 0;
            z-index: -1;
        }

        &::after {
            content: ' ';
            width: 100%;
            height: rem-calc(398);
            background: linear-gradient(to right bottom, transparent 50%, #1b47b7 50%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        h2 {
            font-size: rem-calc(24);
            font-weight: 600;
            line-height: rem-calc(45);
            text-align: center;
        }

        p {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            text-align: left;
            padding: 0 rem-calc(20);
        }
        .header-img-box {
            justify-content: center;
        }
        img {
            height: rem-calc(188);
        }
    }

    .solution-container {
        padding: rem-calc(30) 0 rem-calc(35);

        .title {
            position: relative;
            padding-left: rem-calc(12);
            line-height: rem-calc(18);
            height: rem-calc(18);
            font-size: rem-calc(18);
            margin: rem-calc(20) 0;
            // padding: 0 0 0 rem-calc(1);

            &::before {
                content: ' ';
                width: rem-calc(4);
                height: rem-calc(16);
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: rem-calc(-8);
                background-color: #23b3bc;
            }
        }
    }

    .e-commerce-container {
        .pain-outer-box {
            position: relative;

            &:first-child {
                padding: rem-calc(20 10);
            }

            &:last-child {
                padding: rem-calc(20 10);
            }

            &:first-child::before,
            &:last-child::before {
                width: rem-calc(36);
                height: rem-calc(36);
                font-size: rem-calc(22);
                font-weight: bold;
                color: #ffffff;
                line-height: rem-calc(36);
                text-align: center;
                position: absolute;
                background: transparent;
                top: 0;
                left: 0;
                z-index: 2;
            }

            &:first-child::after,
            &:last-child::after {
                content: ' ';
                width: 0;
                height: 0;
                border-top: 36px solid #3666de;
                border-right: 36px solid #3361d6;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .pain-box {
                height: rem-calc(128);
                color: #252525;
                background: #f3f6ff;
                border: 1px solid #eef1ff;
                padding: rem-calc(20) rem-calc(27) 0 rem-calc(36);

                h4 {
                    font-size: rem-calc(14);
                    font-weight: 600;
                    color: #252525;
                    line-height: rem-calc(20);
                }

                p {
                    font-size: rem-calc(14);
                    font-weight: 400;
                    color: #252525;
                    line-height: rem-calc(20);
                }
            }
        }

        .service-box {
            &:nth-child(3n + 1) {
                padding: 0 1em;
            }

            &:nth-child(3n + 2) {
                padding: 0 1em;
            }

            &:nth-child(3n) {
                padding: 0 1em;
            }

            img {
                width: rem-calc(45);
                height: rem-calc(42);
                margin: 0 auto;
                display: block;
            }

            h4 {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                text-align: center !important;
            }

            p {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
            }
        }

        .advantage {
            box-shadow: none;
            background: #ffffff;

            .advantage-outer-box {
                box-shadow: none;
                border-radius: none;

                .advantage-box {
                    h4 {
                        font-size: rem-calc(14);
                        line-height: rem-calc(36);
                    }

                    p {
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                        max-width: rem-calc(98);
                    }
                }
            }
        }
    }
}
