.pricing {
    .main-bg {
        position: relative;
        img {
            &:nth-of-type(1) {
                height: 500px;
                width: 100%;
                object-fit: cover;
            }
            &:nth-of-type(2) {
                height: 70px;
                position: absolute;
                top: 48%;
                left: 20%;
            }
        }
    }
    .module-title {
        font-size: rem-calc(32);
        font-weight: 600;
        color: #000000;
        line-height: rem-calc(45);
        margin-bottom: rem-calc(40);
        display: flex;
        align-items: center;
        margin: rem-calc(40) 0;
        font-weight: 600;
        img {
            width: rem-calc(65);
            height: rem-calc(14);
            &:first-of-type {
                margin-right: rem-calc(20);
            }
            &:last-of-type {
                margin-left: rem-calc(20);
                transform: rotate(180deg);
            }
        }
    }
    .soltion-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: rem-calc(20);
        margin-top: rem-calc(20);
        .soltion-item {
            position: relative;
            span {
                position: absolute;
                top: 65%;
                left: 50%;
                transform: translateX(-50%);
                color: #303e5d;
                font-size: rem-calc(14);
                display: inline-block;
                width: calc(100% - 20px);
            }
            &:nth-of-type(2) {
                margin-top: -6px;
            }
            &:nth-of-type(3) {
                margin-top: -2px;
            }
            &:nth-of-type(4) {
                margin-top: -8px;
            }
        }
    }

    .point-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: rem-calc(40);
        .point-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0 rem-calc(20);
            position: relative;
            text-align: center;
            img {
                width: rem-calc(250);
                margin-bottom: rem-calc(10);
            }
            p {
                font-weight: 400;
                color: #303e5d;
                line-height: rem-calc(20);
                position: absolute;
                bottom: rem-calc(20);
                font-size: rem-calc(14);
            }
        }
    }

    .advantage-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: rem-calc(40) 0 rem-calc(80);
        .advantage-item {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            text-align: center;
            img {
                max-width: rem-calc(170);
            }
            p {
                font-weight: 400;
                color: #303e5d;
                line-height: rem-calc(20);
                position: absolute;
                top: rem-calc(190);
                font-size: rem-calc(14);
            }
        }
    }
    .count-img {
        margin-top: rem-calc(100);
        width: 100%;
    }
}
.body-info {
    .body-info-header {
        text-align: center;
    }
    .price-table-title-sticky {
        position: fixed;
        top: 54px;
        left: 0;
        width: 100%;
        background: #fff;
    }
    h3 {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        img {
            width: rem-calc(18);
            margin-left: rem-calc(12);
            transition: all 0.2s ease-in-out;
        }
    }
}

.price-table-box {
    position: relative;
    height: 1990px;
    transition: height 0.2s linear;
    &.is-close {
        height: 50px;
        overflow: hidden;
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.6) 100%);
            position: absolute;
            top: 0;
        }
    }
    .price-table-title-float {
        position: absolute;
        top: 0;
        opacity: 0;
        .fixed-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: rem-calc(110) !important;
            img {
                width: rem-calc(45);
            }
        }
        &.fixed-title {
            position: fixed;
            top: rem-calc(55);
            opacity: 1;
            z-index: 9;
        }
        .row {
            background: #fff !important;
        }
    }
}

.pricing-body {
    padding: rem-calc(40) 0;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #303e5d;

    .price-table {
        font-size: rem-calc(14);
        .row {
            border-bottom: 1px solid #cfd4dd;
            &:first-of-type {
                background: #f9fafb;
                .table-label {
                    flex: 1 1 200px;
                }
            }
        }
        &.price-table-title,
        &.price-table-title-float {
            .row:first-of-type {
                .table-label {
                    flex: 1 1 0;
                }
            }
        }
        .table-label {
            color: #303e5d;
            font-weight: 600;
        }
        &:first-of-type {
            .row {
                background: unset;
            }
        }
        &:last-child {
            .row {
                &:last-child {
                    .table-col-2,
                    .table-col-3,
                    .table-col-4,
                    .table-col-5 {
                        border-radius: 0 0 4px 4px;
                    }
                }
            }
        }
        .column {
            display: flex;
            align-items: center;
            padding: 0;
            .table-col-title {
                font-size: rem-calc(18);
                font-weight: 600;
                color: #303e5d;
                display: inline-flex;
                align-items: center;
                img {
                    width: rem-calc(50);
                    margin-right: rem-calc(8);
                }
            }
            .yes-icon {
                width: rem-calc(40);
            }
            .no-icon {
                font-size: rem-calc(22);
                color: #999;
            }
        }
        .table-col-2,
        .table-col-3,
        .table-col-4,
        .table-col-5 {
            display: flex;
            align-items: center;
            justify-content: center;

            height: rem-calc(70);
            width: 100%;
            padding: rem-calc(0 10);

            &.table-col-title {
                border-radius: 4px 4px 0 0;
            }
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .price-header-m {
        background: linear-gradient(360deg, #ffffff 0%, #f6fbfd 100%);
        padding-top: rem-calc(70);
    }
    .price-top-m {
        position: relative;
        padding-top: rem-calc(40);
        h3 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(34);
        }
        p {
            font-size: rem-calc(14);
            font-weight: 400;
            color: #252525;
            line-height: rem-calc(24);
        }
        img {
            width: rem-calc(81);
            height: rem-calc(83);
            position: absolute;
            top: rem-calc(20);
            left: rem-calc(30);
        }
    }
    .soltion-m {
        .sol-item-box-m {
            width: 100%;
            // height: rem-calc(108);
            background: #ffffff;
            box-shadow: 0px 3px 7px 0px #eaf4f6;
            border-radius: rem-calc(4);
            margin-bottom: rem-calc(20);
            position: relative;
            padding: rem-calc(13 16 20 20);
            ::before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3));
                background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3));
                z-index: 2;
            }
            h4 {
                font-size: rem-calc(14);
                font-weight: 500;
                color: #202020;
                line-height: rem-calc(20);
            }
            .content {
                font-size: rem-calc(8);
                font-weight: 400;
                color: #202020;
                line-height: rem-calc(12);
                margin-bottom: rem-calc(10);
                position: relative;
                padding-left: rem-calc(10);
                &::before {
                    content: '';
                    position: absolute;
                    width: rem-calc(6);
                    height: rem-calc(6);
                    background: #21b2bb;
                    border-radius: rem-calc(6);
                    top: 50%;
                    left: 0;
                    margin-top: rem-calc(-3);
                }
            }
            .consult-btn {
                width: rem-calc(80);
                height: rem-calc(28);
                border-radius: 4px;
                border: 1px solid #21b2bb;
                font-size: rem-calc(12);
                color: #21b2bb;
                line-height: rem-calc(28);
                text-align: center;
                position: absolute;
                right: rem-calc(16);
                bottom: rem-calc(20);
                z-index: 5;
            }
        }
    }
    .price-body-m {
        padding-bottom: rem-calc(40);
        h3 {
            font-size: rem-calc(17);
            font-weight: 400;
            color: #202020;
            line-height: rem-calc(26);
            text-align: center;
            margin: rem-calc(20 0 10);
        }
        h4 {
            font-size: rem-calc(12);
            font-weight: 400;
            color: #202020;
            line-height: rem-calc(18);
            text-align: center;
            margin-bottom: rem-calc(20);
        }
        .price-table-m {
            background: #ffffff;
            box-shadow: 0px 6px 9px 0px #edeef0;
            border-radius: 4px;
            border-top: 8px solid #21b2bb;
            margin-bottom: rem-calc(20);
            padding: 0 rem-calc(20);
            .price-table-header-m {
                margin-top: rem-calc(22);
                border-bottom: 1px solid #f3f3f3;

                .edition {
                    font-size: rem-calc(18);
                    font-weight: 600;
                    color: #202020;
                    line-height: rem-calc(27);
                    margin-bottom: rem-calc(10);
                }
                .price {
                    font-size: rem-calc(32);
                    font-weight: 600;
                    color: #202020;
                    line-height: rem-calc(35);
                    margin-bottom: rem-calc(20);
                    &.free {
                        margin-bottom: 0;
                    }
                }
                .tip {
                    font-size: rem-calc(12);
                    font-weight: 400;
                    color: #f5a623;
                    line-height: rem-calc(26);
                }
                .try-btn {
                    display: inline-block;
                    width: rem-calc(118);
                    height: rem-calc(34);
                    background: #21b2bb;
                    border-radius: 4px;
                    border: 1px solid #21b2bb;
                    font-size: rem-calc(14);
                    font-weight: 500;
                    color: #ffffff;
                    line-height: rem-calc(34);
                    margin-bottom: rem-calc(40);
                }
                .consult-btn {
                    display: inline-block;
                    width: rem-calc(88);
                    height: rem-calc(34);
                    border-radius: 4px;
                    border: 1px solid #21b2bb;
                    font-size: rem-calc(14);
                    font-weight: 500;
                    color: #21b2bb;
                    line-height: rem-calc(34);
                    margin-bottom: rem-calc(20);
                }
            }
            .table-body-m {
                padding-top: rem-calc(20);
                height: rem-calc(175);
                overflow: hidden;
                .table-body-title-m {
                    font-size: rem-calc(12);
                    font-weight: 600;
                    color: #202020;
                    line-height: rem-calc(23);
                    position: relative;
                    padding-left: rem-calc(10);
                    margin-bottom: rem-calc(10);

                    &::before {
                        content: '';
                        width: rem-calc(4);
                        height: rem-calc(13);
                        background: #202020;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: rem-calc(-6);
                    }
                }
                .permission {
                    font-size: rem-calc(13);
                    font-weight: 400;
                    color: #202020;
                    line-height: rem-calc(20);
                    padding-left: rem-calc(20);
                    margin-bottom: rem-calc(10);
                    position: relative;
                    &::before {
                        content: '';
                        background: url('../img/pricing/yes.png') no-repeat center;
                        background-size: 100%;
                        width: rem-calc(12);
                        height: rem-calc(8);
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: rem-calc(-4);
                    }
                }
            }
            .expand-more,
            .pack-up {
                font-size: rem-calc(14);
                font-weight: 600;
                color: #21b2bb;
                line-height: rem-calc(27);
                padding: rem-calc(20 0);
                text-align: center;
                span {
                    position: relative;
                    padding-right: rem-calc(5);
                }
                &::after {
                    content: '\e906';
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    border: none;
                    width: auto;
                    top: 35%;
                }
            }
            .pack-up {
                display: none;
                &::after {
                    display: inline-block;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
