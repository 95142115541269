.button{
  font-weight: 500;
  user-select: none;

  &.secondary{
    color: black;
    border: 1px solid #ccc;
    &:hover{
      color: black;
    }
  }

  &.hollow{
    border: 1px solid $gray-6;
    border-radius: $global-radius;
    background: none;
    color: darken($gray-6,40);
    &:hover{
      border-color: $gray-6;
      color: $anchor-color-hover;
    }
  }

  &.invert{
    color: black;
    background: #F4F4F4;
    text-shadow: none;
    &:hover{
      opacity: 0.7;
    }
    &.secondary{
      background: none;
      border: 1px solid #f4f4f4;
      color: #f4f4f4;
    }
  }

  &.tiny{
    text-transform: uppercase;
    font-weight: 600;
  }

  &.white{
    background: #FFFFFF;
    color: #C7152D;
    &:hover{
      box-shadow: 0px 10px 10px HSLA(0, 0%, 100%, 0.2) !important;
    }
  }

  &.green{
    background: #66BB6A;
    &:hover{
      box-shadow: 0px 10px 10px HSLA(123, 38%, 57%, 0.2) !important;
    }
  }

  &.cyan{
    background: #3fad86;
    &:hover{
      box-shadow: 0px 10px 10px rgba(63, 173, 134, 0.2) !important;
    }
  }

  &.red{
    background: #F66869;
    &:hover{
      box-shadow: 0px 10px 10px HSLA(360, 89%, 69%, 0.2) !important;
    }
  }

  &.cta{
    border-radius: 10em;
    text-transform: uppercase;
    letter-spacing: 0.022em;
    transition: all 0.2s ease;
    margin: 0 0.2em;
    &:hover{
      box-shadow: 0px 10px 10px rgba(0, 189, 229, 0.2);
      transform: translateY(-1px);
    }
    &.nohover {
      box-shadow:none;
      transform: none;
    }
    &:active{
      box-shadow: 0px 4px 5px rgba(0, 189, 229, 0.2);
      transform: translateY(1px);
    }
    &.hollow{
      border-radius: 10em;
      text-transform: none;
      background: transparent;
      border:1px solid $anchor-color;
      color: $anchor-color;

      &.white {
        color:$white;
        border: 1px solid $white;
      }
      &:hover{
        background-color: $anchor-color;
        border-color: $anchor-color;
        color: white;
      }
    }
  }
}
