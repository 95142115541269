.div-middle {
	//position: relative;

	display: flex;
	-moz-display: flex;

	> div {
		align-items: center;

		//position: relative;
		//top: 50%;
		//transform: translateY(-50%);
	}
}

.module-body {
	padding: 96px 0 80px 0;

	.module-desc {
		margin-bottom: 62px;
	}
}

.theme-new-products {
	h5 {
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
		opacity: 0.85;
		margin-bottom: 8px;
	}

	.hero-copy h5 {
		margin-top: 20px;
	}

	.hero {
		position: relative;
		// margin-bottom: 40px;

		p {
			font-size: 14px;
			color: #ffffff;

			span {
				cursor: pointer;
			}
		}
	}

	.hero-desc {
		position: absolute;
		width: 100%;
		top: 58%;
		transform: translateY(-50%);
	}

	.hero-arrow {
		display: inline-block;
		width: 22px;
		height: 36px;
		background: url('../img/arrowdown.png');
		background-size: cover;
		margin: 30px 0 8px 0;
	}

	.module-title {
		margin-bottom: 40px;
		font-size: 36px;
		line-height: 54px;
		font-weight: 600;
		color: #202020;
	}

	.module-info.two-child > div > div {
		@extend .div-middle;

		img {
			height: auto;
			width: auto;
		}

		p {
			padding-left: 13px;
			text-indent: -10pt;
		}
	}

	.desc-item-box {
		@extend .div-middle;

		.desc-item {
			margin-bottom: 32px;

			p {
				opacity: 0.7;
				font-size: 14px;
			}
		}
	}

	.hr-bottom {
		border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
	}

	// 我们的优势
	.our-superiority {
		&.solution {
			padding-top: 40px;
		}

		.superiority-box > div {
			padding: 22px;
			//height: 310px;
			overflow-y: hidden;

			p {
				opacity: 0.7;
				width: 238px;
				font-size: 14px;
			}
		}

		.superiority-title {
			padding-top: 23px;
			margin-bottom: 40px;
			display: flex;
			justify-content: center;
		}

		.line-3 {
			display: inline-block;
			float: left;
			margin-right: 16px;
			margin-top: 3px;
			width: 4px;
			height: 17px;
			border-radius: 100px;
			background-image: linear-gradient(to top, #5f85a1, #4f4662);
		}

		h4 {
			float: left;
			display: inline-block;
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 0;
		}
	}

	// 接入流程
	.access-flow {
		background-size: cover;

		h2 {
			margin-bottom: 64px;
		}
	}

	// 联系我们
	.get-solution-fund {
		height: 240px;
		background-image: linear-gradient(3deg, rgba(67, 109, 135, 1), rgba(77, 69, 97, 1));
		padding: 40px 0 60px 0;

		h3 {
			margin: 32px 0;
			font-size: 24px;
			color: #ffffff;
			font-weight: 500;
		}

		.contact-us {
			background-color: #fff;
			color: #202020;
			font-weight: 400;

			&:hover {
				box-shadow: 0px 8px 10px rgba(60, 105, 7, 0.3);
			}
		}
	}
}

// 适应移动端solution
@media screen and (max-width: 39.9375em) {
	.solution {
		// background: linear-gradient(360deg, rgba(247, 249, 252, 1) 0%, rgba(244, 244, 250, 1) 100%);
	}

	.theme-new-products {
		.module-body {
			padding-bottom: 0 !important;
		}

		.module-info {
			margin-bottom: 0 !important;
		}

		.module-info > div > div {
			padding: 0 !important;
			margin-bottom: 40px !important;
		}

		.phone-margin-top {
			margin-top: 40px;
		}

		.access-flow {
			padding-bottom: 96px !important;
		}
	}
}

.seamlessRolling {
	ul,
	li {
		list-style: none;
	}

	ul {
		overflow: hidden;
		width: 100%;
		white-space: nowrap;
		margin: 0;
		padding: 0;
		font-size: 0;
	}

	li {
		display: inline-block;
	}

	img {
		width: 100%;
	}

	.seamlessRolling-box {
		position: absolute;
		bottom: 0;
	}
}

.case-item {
	text-align: center;
	cursor: pointer;

	&:hover {
		// box-shadow: 4px 11px 31px 0px rgba(167, 183, 217, 0.33);
	}

	img {
		// margin-top: 64px;
	}

	.case-title {
		margin-top: 10px !important;
		font-size: 18px !important;
		font-weight: 500 !important;
		color: #202020 !important;
		line-height: 24px !important;
		opacity: 1 !important;
	}

	.case-content {
		font-size: 14px !important;
		font-weight: 400 !important;
		color: #212121 !important;
		line-height: 21px !important;
		opacity: 0.7 !important;
		text-align: left;
	}

	.agent-icon {
		width: 85px;
		height: 83px;
	}

	.store-icon {
		width: 80px;
		height: 76px;
	}

	.retail-icon {
		width: 94px;
		height: 76px;
	}
}

.superiority-section {
	background: linear-gradient(360deg, rgba(247, 249, 252, 1) 0%, rgba(244, 244, 250, 1) 100%);
}

.partner-section {
	background-image: url('../img/solution/fund/partner-bg.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 50% 50%;

	.partner-box {
		.module-info:first-of-type {
			margin-bottom: 30px;
		}

		.module-info:last-of-type {
			margin-bottom: 100px;
		}
	}
}

.partner-item {
	background-color: #fff;
	max-width: 320px;
	height: 257px;
	padding: 30px 36px !important;
	box-shadow: 0px 8px 26px 0px rgba(166, 179, 210, 0.47);
	border-radius: 10px;
	margin: 0 auto;

	.img-container {
		height: 61px;
		line-height: 61px;
		margin-bottom: 20px;
	}

	.jryzt {
		width: 188px;
		height: 58px;
	}

	.payh {
		width: 208px;
		height: 37px;
	}

	.hfyh {
		width: 162px;
		height: 61px;
	}

	.partner-desc {
		font-size: 18px !important;
		color: #7c8486;
	}
}

.parter-merchant {
	display: inline-flex;
	justify-content: center;
	width: 100%;

	.kaide-logo {
		width: 143px;
		height: 72px;
	}

	.zdgys-logo {
		width: 141px;
		height: 43px;
	}

	.merchant-info {
		display: inline-flex;
		flex-direction: column;
		flex: 1;

		.merchant-item {
			display: inline-flex;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;

			.merchant-label {
				font-size: 20px;
				color: #7c8486;
				width: auto;
				margin-right: 10px;
				margin-bottom: 0;
				text-align: left;
			}

			.type {
				font-size: 20px;
				color: #202020;
				flex: 1;
				margin-bottom: 0;
				text-align: left;
			}

			.plan {
				font-size: 20px;
				color: #27c8e8;
				flex: 1;
				margin-bottom: 0;
				text-align: left;
			}
		}
	}
}

.butt-section {
	margin-top: -80px;
}
