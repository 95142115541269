.wiki {
  padding-top: 80px;

  &-title {
    margin: 55px auto;
  }

  &-body {
    padding: 65px 0;
    margin-bottom: 50px;
    background-color: #f5f7f8;
  }

  &-ul-box {
    margin-left: 1.25rem;
  }

  .wiki-remarks {
    padding-left: 45px;
    text-indent: -45px;
  }

  &-info {
    padding-right: 15px; 

    &-title {
      margin-bottom: 40px;
    }

    &-sub-title {
      @extend .wiki-info-title;
    }

    p,li {
      font-size: 14px;
    }

    &-back a {
      margin-right: 10px;
    }
  }

  .out-link-box {
    padding: 30px 20px;
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 11px 0 rgba(204, 204, 204, 0.59);

    a {
      font-size: 14px;
    }

    a.anchor {
      float: right;
    }
  }

  .wiki-dev-links {
    padding: 108px 0 164px;
  }
}