.docs-nav {
    text-align: center;

    .row {
        border-bottom: 1px solid #E0E0E0;

        @include breakpoint (small only) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            width: 100%;

            .columns {
                flex: 0 0 auto;
            }
        }

        ;
    }

    a {
        padding: 1.21em 0em;
        display: block;
        width: 100%;
        color: #616161;
        font-weight: 500;
        margin-bottom: -1px;

        &.active {
            color: $black;
            border-bottom: 2px solid $primary-color;

            @include breakpoint (small only) {
                color: $anchor-color;
            }
        }
    }
}

.doc-filters {
    width: 100%;

}

.docs-upnext {
    float: right;
    margin-top: 1.5em;

    a {
        display: block;
    }

}

.docs-head {

    .language-select-item,
    .channel-select-item {
        opacity: 0;
        transition: all 0.2s ease;
        transition-delay: 0.2s;

        &.on {
            opacity: 1;
        }
    }

    .dropdown.menu {
        float: right;

        &>li {
            font-size: rem-calc(12);
            padding-left: 10px;
        }

        &>li>a {
            padding-left: 0;
            font-size: rem-calc(14);
            margin-top: -10px;
        }

        .submenu {
            background: $white;
            border-radius: $global-radius;
            border: 1px solid #EEEEEE;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
            font-size: rem-calc(14);
            overflow-y: auto;
            max-height: 20.5em;
        }
    }
}

.docs-content-container {
    padding: 2em 0em;
}

.docs-article-select {
    &-toggle {
        border: 1px solid #e0e0e0;
        text-align: center;
        width: 100%;
        display: block;
        padding: 1em 0em;
        margin-top: -1em;
        font-weight: 300;
        color: $anchor-color;
    }

    li.subcategory:not(.is-index) {
        display: none;
    }

    li.subcategory.disabled {
        pointer-events: none;
    }

    a.active {
        background: $primary-color;
        color: $white;
    }

    a.subcategory {
        margin-left: 1em;

        &.is-index {
            margin-left: 0;
        }

        &.disabled {
            pointer-events: none;
            cursor: default;
            color: $gray-8;
            font-size: rem-calc(12);
            text-transform: uppercase;
            letter-spacing: 0.0125em;
        }
    }

    a.thirdcategory {
        margin-left: 2em;
    }

    @include breakpoint (small only) {
        max-height: 0px;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
        margin-bottom: 2em;
        opacity: 0;

        &.on {
            max-height: 999px;
            padding: 1em 0;
            border-bottom: $hr-border;
            opacity: 1;
        }
    }

}

$docs-content-margins: 1.67rem;

.docs-content {
    color: #616161;

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    p {
        line-height: $docs-content-margins;
        margin-bottom: $docs-content-margins;
        //font-size: rem-calc(15);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $black;
    }

    h3 {
        font-size: rem-calc(20);
        border-top: 1px solid #E0E0E0;
        padding-top: $docs-content-margins;
        margin-top: $docs-content-margins;
    }

    h4,
    h5,
    h6 {
        font-size: rem-calc(16);
        margin-bottom: 0.8rem;
        margin-top: $docs-content-margins*1.5;
        font-weight: 500;
    }

    code {
        background: #FFFFFF;
        border-radius: 4px;
        font-family: $font-family-monospace;
        font-size: rem-calc(15);
        color: #c82829;
        border: 1px solid #E8E8EB;
    }

    pre>code {
        background: none;
        border: none;
        padding: 0;

        &.hide {
            visibility: hidden;
        }
    }

    ol {
        li {
            padding-left: 0.5em;
            padding-bottom: 0.5em;
        }
    }

    ul:not(.menu) {
        list-style: none;
        margin-left: 0;

        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 0.5em;

            &::before {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                left: 10px;
                top: 9px;
                border-radius: 50%;
                background: #939da3;
            }
        }
    }

    .code-filter {
        .channel-description {
            font-size: rem-calc(12);
            text-transform: uppercase;
            letter-spacing: 0.05em;
            padding-bottom: 0.2em;
        }
    }

    img {
        border: $hr-border;
    }

}

@mixin linkHoverAnimation($color) {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        border-bottom: 1px solid $color;
        transition: 0.4s;
    }

    &:hover:after {
        width: 100%;
    }
}

@mixin linkBoxHoverAnimation($color) {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 0%;
        border: 1px solid $color;
        opacity: 0;
        transition: 0.4s;
    }

    &:hover:after {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    &:active:after {
        transform: scale(0.4);
        opacity: 0.5;
    }
}

.hero-top {
    position: relative;
    top: -87px;
    margin-top: 0;
}

.hero-docs {
    h1 {
        font-size: rem-calc(68);
        font-weight: 100;
        text-align: right;

        @include breakpoint (small only) {
            text-align: left;
            margin-left: 16px;
            font-size: rem-calc(48);
        }
    }

    h4 {
        text-align: left;
        color: rgba(255, 255, 255, 0.6) !important;
        width: auto;

        @include breakpoint(small only) {
            margin-left: 16px;
            margin-top: 0.5em;
        }

        a {
            color: white;

            @include linkHoverAnimation($white);
        }
    }

    .hero-copy {
        margin: 3.5em 0 2em;
    }

    p.buttons {
        margin-bottom: 0;

        a:first-child {
            margin-right: 0.5em;
        }
    }
    footer{
        .menu > li > a {
            color:#FFF;
        }
    }
}

.hero-downloads {
    padding: 8rem 0rem 8rem;
    min-height: 100vh;
    background-color: #000;

    h1,
    h2,
    h3,
    h4 {
        text-align: left;
        width: auto;
    }

    h1 {
        margin-bottom: 1em;
    }

    h3 {
        margin-bottom: 0.5em;
    }

    p.buttons {
        color: rgba(255, 255, 255, 0.5);
    }

}

.downloads-list {
    list-style: none;
    margin: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 2em;

    li {
        padding: 0 rem-calc(30) rem-calc(30) 0;

        a {
            display: block;
            width: rem-calc(200);
            height: rem-calc(120);
            padding: rem-calc(20) rem-calc(30) rem-calc(17);
            background: #202020;
            border-radius: 3px;
            transition: all 0.5s ease;
            font-size: rem-calc(20);
            color: $white;

            span {
                display: block;
                margin-bottom: rem-calc(32);

                &:after {
                    position: relative;
                    top: rem-calc(-2);
                    left: rem-calc(-20);
                    margin-left: rem-calc(8);
                    font-family: 'icomoon' !important;
                    content: "\e904";
                    color: #35C8E6;
                    font-size: rem-calc(12);
                    opacity: 0;
                }
            }

            small {
                display: block;
                font-size: rem-calc(12);
                color: rgba(255, 255, 255, .35);
            }

            &:hover {
                background: #2A2A2A;
                box-shadow: 0 0 5px 0 #000000, inset 0 1px 0 0 rgba(255, 255, 255, 0.05);

                span:after {
                    opacity: 1;
                    left: 0;
                    animation: icon-opacity .5s;
                }
            }
        }
    }
}

@keyframes icon-opacity {
    0% {
        left: rem-calc(-20);
        opacity: .3;
    }

    50% {
        opacity: .6;
    }

    100% {
        left: rem-calc(0);
        opacity: 1;
    }
}

.downloads-content {
    h3 {
        font-weight: 400;

        &:after {
            content: '';
            display: inline-block;
            margin-left: 2px;
            width: rem-calc(14);
            height: 0;
            border-bottom: 2px solid #35C8E6;
            overflow: hidden;
        }
    }

    @include breakpoint(small only) {
        margin-left: 16px !important;
    }

    ;
}

summary {
    ::-webkit-details-marker, ::marker {
        display: none;
        content: '';
    }
}