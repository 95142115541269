$apple-pay-animation-delay: 1s;

.apple-pay-hero-intro{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.apple-pay-logo{
  animation: apple-pay-intro $apple-pay-animation-delay forwards ease-in-out;
}

.hero-delay{
  width: 100%;
  z-index: 1;
  h1,h2,h3,h4,h5,h6,p{
    text-align: left;
  }
}

.hero-delay-copy{
  opacity: 0;
  transform: scale(0);
  animation: apple-pay-copy-intro 0.8s forwards ease;
  animation-delay: $apple-pay-animation-delay;
  @include breakpoint(small only) {
    animation: apple-pay-copy-intro-small 0.8s forwards ease;
    animation-delay: $apple-pay-animation-delay+1s;
  };
  .links {
    a {
      margin-right: 1.5em;
    }
  }
  .typical-case-logo-wall {
    margin-top: 1.5rem;
    @include breakpoint(small only){
      padding:0;
    };
  }
}


.apple-pay-hand{
  background-image: url("../img/apple-pay-hand.png");
  background-size: 377px 634px;
  width: 377px;
  height: 634px;
  position: relative;
  opacity: 0;
  animation: apple-pay-hand-intro 0.8s forwards ease;
  animation-delay: $apple-pay-animation-delay+0.1s;
  margin: 0 auto;

  @include breakpoint(retina) {
    background-image: url("../img/apple-pay-hand@2x.png");
  }

  @include breakpoint(small only) {
    animation: apple-pay-hand-intro-small 0.8s forwards ease;
    animation-delay: $apple-pay-animation-delay;
  };
}

.apple-pay-hand-mask{
  width: 100%;
  height: 20%;
  bottom: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(-179deg, rgba(0,0,0,0.00) 0%, #0A0A0A 100%);
}

.apple-pay-screen{
  width: 160px;
  height: 282px;
  position: absolute;
  top: 53px;
  left: 57px;
  background: url("../img/apple-pay-screen@2x.png");
  background-size: contain;
  overflow: hidden;
}

.apple-pay-screen-form{
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url("../img/apple-pay-form@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  transform: translateY(200px);
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.apple-pay-screen-mask{
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.apple-pay-screen:hover{
  cursor: pointer;
  .apple-pay-screen-form{
    transform: translateY(0);
  }
  .apple-pay-screen-mask{
    opacity: 0.5;
  }
}
