.article-section {
    margin-top: rem-calc(55);
    background: #f5f5f7;
    padding-bottom: rem-calc(20);
    overflow: hidden;
    article {
        flex: 1;
        padding: rem-calc(30);
        background: #fff;
        line-height: 2;
        border-radius: 4px;
        h1 {
            font-size: rem-calc(22);
            font-weight: 500;
            margin-bottom: rem-calc(15);
            text-align: center;
        }
        time {
            color: #999;
            font-size: 14px;
        }
        p {
            text-indent: 2em;
        }
        img {
            width: 100%;
            margin: 20px 0;
        }
    }
    .article-switch {
        margin-top: rem-calc(30);
        color: #4a4a4a;
        line-height: 2;
        div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
.breadcrumb-wrap {
    font-size: rem-calc(14);
    line-height: rem-calc(50);
}
.latest-news-wrap {
    padding: rem-calc(20);
    width: rem-calc(280);
    background: #fff;
    margin-left: rem-calc(30);
    border-radius: 4px;
    align-self: flex-start;
    .ln-title {
        font-size: rem-calc(18);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(20);
    }
    .line {
        height: 1px;
        background: #ddd;
    }
    ul {
        margin: rem-calc(15) 0 0 0;
    }
    li {
        list-style: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 2.5;
        font-size: rem-calc(14);
    }
}

@media screen and (max-width: 768px) {
    .article-section {
        width: 100vw;
        padding-bottom: 0;
        & > div {
            width: 100%;
        }
        article {
            padding: rem-calc(20);
            width: 100vw;
            h1 {
                font-size: rem-calc(18);
            }
            time {
                font-size: rem-calc(12);
            }
        }
    }
    .latest-news-wrap {
        width: 100%;
        margin-left: 0;
        margin-top: rem-calc(20);
    }
    .article-switch {
        font-size: rem-calc(14);
        margin-top: 0 !important;
    }
    .breadcrumb-wrap,
    .article-switch {
        padding: rem-calc(10);
    }
}
