.home-header-container {
    height: rem-calc(600);
    background-image: url(../img/newhome/header_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    h1 {
        font-size: rem-calc(40);
        font-weight: 500;
        color: #252525;
        line-height: rem-calc(66);
        letter-spacing: 6px;
        &.show-for-small-only {
            margin-bottom: 0;
        }
    }
    p {
        font-size: rem-calc(14);
        color: #282f3c80;
        line-height: rem-calc(24);
        margin-bottom: rem-calc(31);
    }
    .free_try {
        display: inline-block;
        height: rem-calc(48);
        padding: 0 rem-calc(20);
        background: #21b2bb;
        border: 1px solid #21b2bb;
        border-radius: rem-calc(48);
        font-size: rem-calc(18);
        font-weight: 500;
        color: #ffffff;
        line-height: rem-calc(46);
        text-align: center;
        box-shadow: 0 1px rem-calc(8) #1b979fa6;
        letter-spacing: rem-calc(4);
        img {
            width: rem-calc(40);
            height: rem-calc(34);
            margin-top: rem-calc(-5);
            margin-left: rem-calc(15);
        }
    }
    .home-img-box {
        justify-content: flex-end;
    }
}

.service-box {
    margin-top: rem-calc(80);
    .service-content {
        margin: 0 auto;
        .cont-box {
            width: 100%;
            margin: rem-calc(60) 0;
            div {
                .left-icon {
                    height: rem-calc(100);
                    width: rem-calc(100);
                }
                h4 {
                    font-size: rem-calc(32);
                    font-weight: 600;
                    color: #202020;
                    line-height: 1.2;
                    margin: rem-calc(40) 0 rem-calc(30) 0;
                    img {
                        width: rem-calc(65);
                        margin-left: rem-calc(20);
                        transform: rotate(180deg);
                    }
                }
                a {
                    display: inline-block;
                    padding: 0 rem-calc(20);
                    height: rem-calc(48);
                    line-height: rem-calc(46);
                    border: 1px solid #21b2bb;
                    border-radius: rem-calc(48);
                    margin-top: rem-calc(30);
                    img {
                        width: rem-calc(36);
                        height: rem-calc(30);
                        margin-top: rem-calc(-5);
                        margin-left: rem-calc(15);
                    }
                }
                &:first-of-type {
                    padding-right: rem-calc(25);
                }
                &:last-of-type {
                    padding-right: rem-calc(25);
                }
            }
        }
    }
    .control {
        position: absolute;
        left: 60%;
        bottom: rem-calc(60);
        div {
            float: left;
            width: rem-calc(50);
            height: rem-calc(50);
            margin-right: rem-calc(15);
            cursor: pointer;
            transition: all 0.2s;
            &.active {
                transform: scale(1.14);
                &:hover {
                    opacity: 0.9;
                }
            }
        }
        .prev {
            background: url(../img/newhome/prev.png) no-repeat center/ 100%;
            &.active {
                background: url(../img/newhome/prev_active.png) no-repeat center/ 100%;
            }
        }
        .next {
            background: url(../img/newhome/next.png) no-repeat center/ 100%;
            &.active {
                background: url(../img/newhome/next_active.png) no-repeat center/ 100%;
            }
        }
    }
}

.customer-message {
    padding: rem-calc(40) 0;
    .case {
        text-align: center;
        margin: rem-calc(10) 0 rem-calc(40);
    }
    h2 {
        font-size: rem-calc(32);
        font-weight: 600;
        color: #000000;
        line-height: rem-calc(45);
        margin-bottom: 0;
        display: flex;
        align-items: center;
        img {
            width: rem-calc(65);
            height: rem-calc(14);
            &:first-of-type {
                margin-right: rem-calc(20);
            }
            &:last-of-type {
                margin-left: rem-calc(20);
                transform: rotate(180deg);
            }
        }
    }
    h4 {
        font-weight: 600;
    }
    .message-banner-outer-box {
        position: relative;
        .message-banner-box {
            width: 66.67vw;
            padding: rem-calc(30) rem-calc(40);
            position: relative;
            &::before {
                content: ' ';
                width: rem-calc(210);
                height: rem-calc(140);
                background: linear-gradient(45deg, #e9fbf7, #ecf6fb, #d3eef5);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                border-radius: 8px;
            }
            &::after {
                content: ' ';
                width: rem-calc(180);
                height: rem-calc(220);
                background: linear-gradient(45deg, #e9fbf6, #ebf5fa, #e4f2fa);
                position: absolute;
                bottom: rem-calc(-10);
                right: 0;
                z-index: -1;
                border-radius: 8px;
            }
            .border-bg {
                border: 1px solid #ebf5f3;
                padding: rem-calc(12);
                border-radius: 8px;
                background: #ffffff67;
            }
            .logo {
                margin-bottom: rem-calc(30);
                display: flex;
                align-items: center;
                width: 100%;
                img {
                    width: rem-calc(120);
                    margin-right: auto;
                }
                a {
                    font-size: rem-calc(16);
                }
            }
            .message-item {
                box-shadow: 0 0 5px #ddd;
                border-radius: 8px;
                padding: rem-calc(30);
                z-index: 999;
                font-size: rem-calc(18);
                background: #fff;
                color: #283450;
                .person {
                    text-align: right;
                    margin-top: rem-calc(20);
                    .name {
                        font-weight: 600;
                    }
                    .job {
                        margin-left: rem-calc(15);
                        color: #606368;
                        font-size: rem-calc(16);
                    }
                }
            }
        }
    }
}

.company-icons {
    margin-bottom: rem-calc(120);
}
.home-bottom-case,
.home-info {
    margin: rem-calc(40) 0;
    h2 {
        font-size: rem-calc(32);
        font-weight: 600;
        color: #000000;
        line-height: rem-calc(45);
        margin-bottom: rem-calc(40);
        display: flex;
        align-items: center;
        img {
            width: rem-calc(65);
            height: rem-calc(14);
            &:first-of-type {
                margin-right: rem-calc(20);
            }
            &:last-of-type {
                margin-left: rem-calc(20);
                transform: rotate(180deg);
            }
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .company-icons {
        margin-bottom: rem-calc(40);
    }
    .home-bottom-case {
        h3 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #000000;
            line-height: rem-calc(30);
            text-align: center;
            margin-bottom: rem-calc(10);
        }
    }
    .home-header-container {
        padding: rem-calc(90 0 25);
        height: auto;
        h2 {
            font-size: rem-calc(20);
            line-height: rem-calc(34);
            margin-bottom: rem-calc(10);
            text-align: center;
        }
        p {
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            margin-bottom: rem-calc(20);
        }
        .free_try {
            display: block;
            width: rem-calc(88);
            height: rem-calc(32);
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(32);
            text-align: center;
            margin: 0 auto;
        }
        .home-img-box {
            justify-content: center;
            margin-top: rem-calc(40);
        }
    }
    .service-box-m {
        background: rgba(200, 244, 249, 0.1);
        padding-bottom: rem-calc(20);
        .service-content-item-m {
            padding-top: rem-calc(40);
            img {
                width: 100%;
                height: auto;
            }
            .icon {
                height: rem-calc(24) !important;
                width: auto !important;
                margin-bottom: rem-calc(10);
            }
            h4 {
                font-size: rem-calc(18);
                font-weight: 600;
                color: #202020;
                line-height: rem-calc(24);
                margin-bottom: rem-calc(20);
            }
            p {
                font-size: rem-calc(14);
                font-weight: 400;
                color: #252525;
                line-height: rem-calc(24);
                margin-bottom: 0;
            }
            a {
                font-size: rem-calc(14);
                font-weight: 600;
                color: #21b2bb;
                line-height: rem-calc(32);
            }
        }
    }
    .customer-message-m {
        padding: rem-calc(40 0 33);
        h3 {
            font-size: rem-calc(20);
            font-weight: 600;
            color: #000000;
            line-height: rem-calc(28);
            margin-bottom: rem-calc(20);
            text-align: center;
        }
        .message-banner-box-m {
            width: 100%;
            overflow-x: scroll;
            ul {
                list-style: none;
                width: rem-calc(720);
                display: flex;
                flex-flow: row nowrap;

                .message-item-m {
                    width: rem-calc(240);
                    background: #ffffff;
                    box-shadow: 0px 4px 13px 0px rgba(179, 195, 208, 0.5);
                    border-radius: 4px;
                    margin-right: rem-calc(20);
                    position: relative;
                    .icon {
                        height: rem-calc(28);
                        width: auto;
                        margin-left: rem-calc(-8);
                    }
                    .content {
                        padding: rem-calc(13 16 53);
                        font-size: rem-calc(12);
                        font-family: LucidaGrande;
                        color: #252525;
                        line-height: rem-calc(20);
                        .person {
                            position: absolute;
                            right: rem-calc(20);
                            bottom: rem-calc(20);
                            width: 80%;
                            display: flex;
                            justify-content: flex-end;
                        }
                        .name {
                            font-size: rem-calc(13);
                            font-weight: 600;
                            color: #252525;
                            line-height: rem-calc(18);
                        }
                        .job {
                            color: #a1a1a1;
                            padding-left: rem-calc(10);
                        }
                    }
                }
            }
        }
    }
}
