.event-banner {
  text-align: center;
  line-height: 30px;
  background-color: #f0f0f0;
  font-size: 12px;
  padding: 0 .5rem;

  @include breakpoint(small only) {
    display: none;
  }
  a {
    display: inline-block;
    color: #212121;
    &:hover {
      text-decoration: underline;
    }
  }
}

.row {
  max-width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.row-d {
  max-width: 75rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media screen and (max-width: 414px) {
  .home-main-con {
    padding: 48px 0;
  }
  .iconwall-item {
    text-align: center;
  }
}

// Home new features block

$new-features-gutter: 0px;

.nav.block-grid.new-features {
  //padding-bottom: 50px;

  ul {
    margin: 0 -$new-features-gutter;
  }

  li {
    position: relative;
    padding: 0 $new-features-gutter;
    transition: transform 0.2s ease;
    width: auto;
    flex: 1;
    @include breakpoint(small only) {
      flex: 0 0 100%;
      margin-bottom: 0.5em;
    }
  ;

    &:not(:last-child) {
      a.block-grid-link {
        @include breakpoint(small only) {
          border-bottom: none
        }
      }
    }

    &:last-child {
      border-right: none;
      a.block-grid-link {
        border-right: none;
      }
    }

    a.block-grid-link {
      z-index: 1;
      border-radius: $new-features-gutter;
      //box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.25);
      border: 1px solid #ebebeb;
      border-left: none;

      //text-align: left;
      h5 {
        z-index: 1;
        //transition: all 0.2s ease;
      }
      .description {
        margin: 0 auto 0.5em;
        z-index: 1;
        max-width: 265px;
        opacity: 0.6;
      }
      .anchor {
        margin-bottom: -0.5em;
        display: none;
        z-index: 1;
      }
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      a {
        color: $black;
      }
    }

    &::after {
      width: calc(100% - #{$new-features-gutter*2});
      height: 100%;
      position: absolute;
      bottom: -5px;
      left: 0px;
      content: ' ';
      filter: blur(10px) brightness(0.9);
      z-index: 0;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &.white-text {
      a h5, a .description {
        text-shadow: none;
        font-weight: normal;
      }
      &:hover {
        a h5, a .description {
          color: white;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
          font-weight: 400;
        }
      }
    }

    a, &::after {
      //background-image: linear-gradient(-180deg, #FFFFFF 0%, #FAFAFA 100%);
    }

    &.payments span.bg, &.payments::after {
      background-image: linear-gradient(-41deg, #E82B4D 0%, #FFACAC 100%);
      background-position: 20% 50%;
    }

    &.account span.bg, &.account::after {
      background-image: linear-gradient(-163deg, #6FCFD3 4%, #48B8D0 100%);
    }

    &.platform span.bg, &.platform::after {
      background-image: linear-gradient(110deg, #64ADEE 10%, #3F6AAF 100%);
    }

    &.bi span.bg, &.bi::after {
      background-image: linear-gradient(-63deg, #83D8DD 0%, #5DD5B1 100%);
    }

    &.sky span.bg, &.sky::after {
      background-image: linear-gradient(36deg, #D3F1F9 0%, #F9FDEE 89%);
    }

    &:hover {
      transform: scale(1.05);
      z-index: 9;
      a h5 {
        //color: $anchor-color-hover;
        opacity: 1;
      }
      a span.bg {
        opacity: 1;
      }
      &::after {
        bottom: -12px;
        filter: blur(12px) brightness(0.9);
        opacity: 0.5;
      }
      a a {
        color: white;
        opacity: 0.7;
        text-decoration: underline;
        &:hover {
          opacity: 1;
        }
      }
    }

    &:active {

      &::after {
        bottom: -5px;
        filter: blur(8px) brightness(0.9);
        opacity: 0.5;
      }
    }

  }
}

// Home feature - main styles
.home-feature {
  padding: 6.25rem 0;
  position: relative;
  @include breakpoint(small only) {
    padding: 3rem 0;
  }
;
  &--text-centered {
    .home-feature__copy {
      margin: 120px auto 0;
      h2 {
        margin-bottom: 24px;
      }
      h2, h5, p {
        text-align: center;
      }
    }
  }
}

.home-content {
  //margin-top: 40px;

  h2 {
    font-weight: 400;
  }

  .difficult {
    background-color: #F5F7F8;
    padding: 40px 0;
    margin-bottom: 80px;

    h3 {
      margin-bottom: 24px;
    }

    &-box {
      > div {
        h4 {
          font-weight: bold;
          font-size: 18px;
          line-height: 160px;
          margin-bottom: 16px;
        }

        overflow: hidden;
        height: 160px;
        padding: 0 71px;
        text-align: center;
        border-right: 1px rgba(0, 0, 0, .08) solid;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 48px;

        &:nth-child(1) {
          background-image: url('../img/newhome/1.png');
        }
        &:nth-child(2) {
          background-image: url('../img/newhome/2.png');
        }
        &:nth-child(3) {
          background-image: url('../img/newhome/3.png');
        }
        &:nth-child(4) {
          background-image: url('../img/newhome/4.png');
        }
      }
    }

    &-desc {
      display: none;
      //margin-top: -70px;
      p {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }

  .product-box {
    margin: 33px 0 0 0;
    padding-top: 67px;
    border-top: 1px rgba(0, 0, 0, .08) solid;
    color: #202020;
    font-size: 14px;

    p {
      opacity: .7;
    }

    &-bottom {
      h2 {
        margin-top: 24px;
        margin-bottom: 0;
      }
    }

    img {
      margin-top: -50px;
      height: 360px;
      display: inline;
    }

    .product-item {
      margin-bottom: 90px;

      &:nth-child(2n) img {
        float: left;
        margin-left: -60px;
      }

      &:nth-child(2n-1) img {
        float: right;
        margin-right: -60px;
      }

      .privatization-img img{
        height: 300px;
        margin-left: 40px;
        margin-top: -20px;
      }
    }
  }

  .business-friend {
    background-color: #F5F7F8;
    padding-top: 72px;

    &.h5 {
      padding-top: 0;
    }

    .home-feature__copy {
      margin-bottom: 48px;
    }

    //.play-btn {
    //  position: absolute;
    //  right: 44px;
    //  bottom: 16px;
    //  height: 48px;
    //  width: 48px;
    //  background-color: rgba(0, 0, 0, .35);
    //  border-radius: 50%;
    //
    //  img {
    //    position: absolute;
    //    top: 50%;
    //    left: 50%;
    //    margin-top: -12.5px;
    //    margin-left: -6px;
    //    height: 25.35px;
    //  }
    //}

    //.video1, .video2, .video3 {
    //  display: inline-block;
    //  position: relative;
    //  outline: 0 none;
    //  cursor: pointer;
    //}

    //.video1 {
    //  padding-right: 20px;
    //
    //  .img{
    //    cursor: pointer;
    //  }
    //}
    //
    //.video2 {
    //  margin-bottom: 20px;
    //}
    //
    //.video2, .video3 {
    //  .play-btn {
    //    right: 24px;
    //  }
    //  .img{
    //    cursor: pointer;
    //  }
    //}

    .h4title {
      margin-top: 40px;
      font-weight: bold;
      font-size: 18px;
      padding-bottom: 40px;
      border-bottom: 1px rgba(0, 0, 0, .08) solid;
    }
  }

  .stickers {
    background-color: #F5F7F8;

    ul {
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      margin: 0;
    }

    li {
      display: inline-block;
      margin: 0 -21px;
    }

    img {
      margin-bottom: 20px;
      height: 300px;
    }
  }

  //.text-desc {
  //  position: absolute;
  //  left: 24px;
  //  bottom: 16px;
  //  color: #fff;
  //
  //  .video-text {
  //    font-size: 14px;
  //    opacity: .75;
  //  }
  //
  //  .video-desc {
  //    font-size: 24px;
  //  }
  //}
}

.bottom-tools {
  padding: 40px 0;

  > div {
    padding: 35px 0 28px 0;
    cursor: pointer;
  }

  > div:not(:last-child) {
    border-right: 1px rgba(0, 0, 0, 0.08) solid;
  }

  img {
    height: 48px;
    margin-bottom: 25px;
  }

  .had-hover {
    display: none;

    img {
      height: 160px;
      margin-bottom: 0;
    }
  }
}

.video {
  video {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .home-main-con {
    padding-bottom: 0 !important;
  }

  .home-content .product-module {
    & .outer {
      &:first-child {
        padding-right: 0;
      }
      &:last-child {
        padding-left: 0;
      }
      .product-module-item {
        margin-bottom: 2px;
        padding: 44px 54px 36px;
      }
    }
  }

  .link-all {
    padding: 32px 0;
    margin-top: -50px;
    background-color: #F5F7F8;
  }

  .product-box {
    border-top: none !important;
    padding-top: 0 !important;
  }

  .product-box img {
    height: auto !important;
    margin-top: 0 !important;
  }

  .product-box .product-item:nth-child(2n-1) img {
    margin-right: 0 !important;
  }

  .product-box .product-item:nth-child(2n) img {
    margin-left: 0 !important;
  }

  .business-friend{
    //@each $index in 1,2,3 {
    //  .video#{$index} {
    //    margin-bottom: 16px;
    //  }
    //}
    //
    //.video1 {
    //  padding-right: 0 !important;
    //}
    //
    //.play-btn {
    //  right: 24px !important;
    //}
  }

  .bottom-tools {
    padding: 0 !important;

    > div {
      &:nth-child(1), &:nth-child(2) {
        border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
      }
    }
  }
}

// Home feature copy
.home-feature__copy {
  h2 {
    font-weight: 300;
    font-size: rem-calc(34);
    @include breakpoint(small only) {
      font-size: rem-calc(28);
    }
  ;
  }

  h5 {
    color: #9E9E9E;
    @include breakpoint(small only) {
      font-size: rem-calc(16);
    }
  ;
  }

  // .punctuation{
  //   letter-spacing: -0.8em;
  // }

  p {
    // font-weight: 300;
    line-height: 1.75;
    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  ;
    a {
      font-weight: 400;
    }
  }
}