.about-header {
    height: rem-calc(329);
    padding-top: rem-calc(80);
    background: url(../img/about/bg.png) center;
    background-size: cover;
    color: #ffffff;
    text-align: center;

    .tip {
        color: rgba(204, 204, 204, 0.7);
        font-size: rem-calc(32);
        line-height: rem-calc(45);
        letter-spacing: rem-calc(10);
        margin-bottom: rem-calc(50);
        span {
            color: #ffffff;
            font-weight: 600;
        }
    }
    .info {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(14);
        color: #ffffff;
        line-height: rem-calc(20);
        text-align: center;
        p {
            margin: 0;
        }

        .big {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #ffffff;
            line-height: rem-calc(33);
            font-family: DINAlternate-Bold, DINAlternate;
            span {
                font-size: rem-calc(54);
                font-weight: bold;
                line-height: rem-calc(63);
            }
        }
    }
}

.content-item {
    padding-bottom: rem-calc(20);
    .title {
        margin-top: rem-calc(80);
        height: rem-calc(46);
        font-size: rem-calc(24);
        font-weight: 600;
        color: #252525;
        line-height: rem-calc(46);
        position: relative;
        padding-left: rem-calc(18);
        &::before {
            content: ' ';
            width: rem-calc(8);
            height: rem-calc(24);
            background: #23b3bc;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: rem-calc(-12);
        }
    }
}

.about-left {
    padding-right: rem-calc(48);

    .content-box {
        box-shadow: 0px 3px 20px 0px rgba(219, 216, 226, 0.5);
        border-radius: 4px;
        font-size: rem-calc(14);
        font-weight: 500;
        color: #252525;
        line-height: rem-calc(20);
        padding: rem-calc(26 64 26);
        position: relative;

        .quotation1 {
            position: absolute;
            top: rem-calc(26);
            left: rem-calc(25);
            width: rem-calc(24);
            height: rem-calc(21);
        }
        .quotation2 {
            position: absolute;
            bottom: rem-calc(26);
            right: rem-calc(25);
            width: rem-calc(24);
            height: rem-calc(21);
            transform: rotate(180deg);
        }
        p {
            margin-bottom: 0;
        }
        // .icon {
        //     position: absolute;
        //     bottom: rem-calc(20);
        //     right: rem-calc(25);
        // }
    }
}

.about-right {
    ul {
        font-size: rem-calc(14);
        font-weight: 400;
        color: #252525;
        line-height: rem-calc(22);

        li {
            position: relative;
            list-style: none;
            padding: rem-calc(5) 0;
            &::before {
                content: '';
                height: 100%;
                width: rem-calc(4);
                background: #21b2bb;
                position: absolute;
                top: 0;
                left: rem-calc(-15);
            }
            &::after {
                content: '';
                width: rem-calc(8);
                height: rem-calc(2);
                background: #21b2bb;
                position: absolute;
                top: rem-calc(15);
                left: rem-calc(-15);
            }
            &:first-child {
                &::before {
                    top: 50%;
                }
            }
            &:last-child {
                &::before {
                    top: auto;
                    bottom: 50%;
                }
            }
        }
        .tag {
            font-weight: 600;
            color: #21b2bb;
            line-height: rem-calc(20);
            padding: 8px 0;
            &::after {
                content: '';
                width: rem-calc(14);
                height: 14px;
                background: #21b2bb;
                border-radius: rem-calc(14);
                position: absolute;
                top: 50%;
                margin-top: rem-calc(-7);
                left: rem-calc(-20);
            }
        }
    }
}

.company {
    text-align: center;
    border-top: 1px solid #d9d9d9;
    padding: rem-calc(40 0 80);
    h2 {
        font-size: rem-calc(32);
        font-weight: 600;
        color: #202020;
        line-height: rem-calc(32);
        margin-bottom: rem-calc(20);
    }
    p {
        font-size: rem-calc(14);
        font-weight: 400;
        color: #595959;
        line-height: rem-calc(22);
        margin-bottom: rem-calc(40);
    }
    .icon-box {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        img {
            width: rem-calc(180);
            height: rem-calc(40);
        }
    }
}

.join-us {
    background: #fafcff;
    padding-bottom: rem-calc(80);
    .img-box {
        padding: 0 5%;
        position: relative;
        overflow: hidden;
        .bg1 {
            width: 100%;
        }
        .main-pos {
            width: 70%;
            height: auto;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -30%;
            z-index: 2;
            transition: all 0.5s ease-in-out;
        }
        .back-pos {
            width: 40%;
            height: auto;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -20%;
            z-index: 1;
            opacity: 0;
            transition: all 0.5s ease-in-out;
        }
        .right-pos {
            width: 40%;
            height: auto;
            position: absolute;
            left: 40%;
            top: 50%;
            margin-top: -15%;
            z-index: 1;
            opacity: 1;
            transition: all 0.5s ease-in-out;
        }
        .about-us-next {
            width: rem-calc(32);
            height: rem-calc(46);
            position: absolute;
            right: 10%;
            top: 50%;
            margin-top: rem-cacl(-23);
        }
    }
    .content-box {
        padding-left: rem-calc(40);
        p {
            font-size: rem-calc(14);
            font-weight: 400;
            color: #595959;
            line-height: rem-calc(22);
            margin-bottom: rem-calc(10);
        }

        h2 {
            font-size: rem-calc(36);
            font-weight: 500;
            color: #252525;
            line-height: rem-calc(36);
            margin-bottom: rem-calc(20);
        }
        h3 {
            font-size: rem-calc(24);
            font-weight: 500;
            color: #595959;
            line-height: rem-calc(24);
            margin-bottom: rem-calc(20);
        }
        h4 {
            font-size: rem-calc(14);
            color: #252525;
            font-weight: 600;
            line-height: rem-calc(22);
            margin-bottom: rem-calc(10);
        }
        .join-us-btn {
            margin-top: rem-calc(20);
            a {
                display: inline-block;
                width: rem-calc(88);
                height: rem-calc(32);
                border-radius: 4px;
                border: 1px solid #252525;
                font-size: rem-calc(14);
                font-weight: 500;
                color: #252525;
                line-height: rem-calc(32);
                text-align: center;
            }
        }
    }
}
@media screen and (max-width: 39.9375em) {
    .about-header {
        height: rem-calc(392);
        padding-top: rem-calc(100);
        background: url('../img/about/bg-m.png') center;
        background-size: 100%;
        align-items: flex-start;
        .tip {
            color: rgba(204, 204, 204, 0.7);
            font-size: rem-calc(24);
            line-height: rem-calc(45);
            letter-spacing: rem-calc(1);
            margin-bottom: rem-calc(30);
        }
        .info {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(12);
            line-height: rem-calc(17);
            text-align: center;
            p {
                margin-bottom: rem-calc(16);
            }

            .big {
                font-size: rem-calc(14);
                font-weight: 600;
                color: #ffffff;
                line-height: rem-calc(20);
                font-family: DINAlternate-Bold, DINAlternate;
                span {
                    font-size: rem-calc(40);
                    font-weight: bold;
                    line-height: rem-calc(47);
                }
            }
        }
    }

    .content-item {
        .title {
            margin-top: rem-calc(40);
            height: rem-calc(18);
            font-size: rem-calc(18);
            font-weight: 600;
            color: #252525;
            line-height: rem-calc(18);
            position: relative;
            padding-left: rem-calc(12);
            &::before {
                content: ' ';
                width: rem-calc(4);
                height: rem-calc(16);
                background: #23b3bc;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: rem-calc(-8);
            }
        }
    }

    .about-left {
        padding-right: 0;

        .content-box {
            box-shadow: none;
            font-size: rem-calc(12);
            font-weight: 400;
            color: #252525;
            line-height: rem-calc(22);
            padding: rem-calc(0 30 20);
            position: relative;

            .quotation1 {
                position: absolute;
                top: rem-calc(0);
                left: rem-calc(5);
                width: rem-calc(14);
                height: rem-calc(12);
            }
            .quotation2 {
                position: absolute;
                bottom: rem-calc(43);
                right: rem-calc(5);
                width: rem-calc(14);
                height: rem-calc(12);
                transform: rotate(180deg);
            }
            .icon {
                position: absolute;
                bottom: rem-calc(0);
                right: rem-calc(5);
                width: rem-calc(78);
                height: rem-cal(10);
            }
            p {
                font-size: rem-calc(12);
                line-height: rem-calc(22);
                margin-bottom: rem-calc(10);
            }
        }
    }

    .company {
        text-align: center;
        border-top: 1px solid #d9d9d9;
        padding: rem-calc(20 0 40);
        h2 {
            font-size: rem-calc(24);
            font-weight: 600;
            color: #202020;
            line-height: rem-calc(34);
            margin-bottom: rem-calc(10);
        }
        p {
            font-size: rem-calc(8);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(15);
        }
        .icon-box {
            padding-top: 0;
            img {
                width: rem-calc(90);
                height: rem-calc(20);
            }
        }
    }

    .join-us {
        background: #fafcff;
        padding-bottom: rem-calc(20);
        .img-box {
            padding: 0;
            position: relative;
            justify-content: center;
            padding-bottom: rem-calc(60);
            .bg1 {
                width: 100%;
            }
            .main-pos {
                width: 70%;
                height: auto;
                position: absolute;
                left: rem-calc(10);
                top: 50%;
                margin-top: -40%;
                z-index: 2;
                transition: all 0.5s ease-in-out;
            }
            .right-pos {
                width: 50%;
                height: auto;
                position: absolute;
                left: 35%;
                top: 50%;
                margin-top: -30%;
                z-index: 1;
                transition: all 0.5s ease-in-out;
            }

            .back-pos {
                width: 50%;
                height: auto;
                position: absolute;
                left: rem-calc(10);
                top: 50%;
                margin-top: -30%;
                z-index: 1;
                opacity: 0;
                transition: all 0.5s ease-in-out;
            }

            .about-us-next {
                width: rem-calc(24);
                height: rem-calc(35);
                position: absolute;
                right: rem-calc(20);
                top: 40%;
            }
        }
        .content-box {
            padding-left: 0;
            p {
                font-size: rem-calc(12);
                line-height: rem-calc(16);
                margin-bottom: rem-calc(10);
            }

            h2 {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                margin-bottom: rem-calc(20);
            }
            h3 {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                margin-bottom: rem-calc(15);
            }
            h4 {
                font-size: rem-calc(14);
                color: #252525;
                font-weight: 600;
                line-height: rem-calc(22);
                margin-bottom: rem-calc(10);
            }
            .join-us-btn {
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
}
